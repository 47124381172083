<div class="list-height projectOkr">
    <div class="row px-3 py-2">
        <div class="col-md-6">
            <h5 class="fw-bold py-2"></h5>
        </div>
        <div class="col-md-6">
            <section class="example-section">
                <mat-checkbox class="example-margin d-flex justify-content-end text-muted"
                    (change)="showOptions($event)" id="termsCheck" [(ngModel)]="termsModel">
                </mat-checkbox>
            </section>
        </div>
    </div>

    <div *ngFor="let data of toeMasterSelectedData" class="row px-3">
        <div class=" my-1 px-0 scope-border col-12">
            <div class="pt-1">
                <p class="fw-bold pt-2 ps-3 mb-0  kleft-border  key-border px-3" style="color: #1A85D6;">
                    {{data?.name}}</p>
            </div>
            <div *ngFor="let i of data?.opportunityterms">
                <div class="ps-3 my-2" style="background-color: rgb(229, 234, 238);margin: 8px 4px;border-radius: 4px;padding:4px;">
                    <p class="fs-14 title-color mb-0">{{i.term.title}}</p>
                    <p class="my-1 fs-16  text-black" *ngIf="i?.comments">ask:[{{i?.comments}}]</p>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="row px-3">
        <div class="shadow my-2 bg-white rounded col-12 okrCenterList py-2" *ngIf="selectedItems?.length">
            <mat-tab-group mat-align-tabs="start" [selectedIndex]="selectedSetupChildIndex">
                <mat-tab *ngFor="let item of selectedItems;let cindex=index;" matTooltip="{{item?.term_category?.category_display_name}}" label="{{item?.term_category?.category_display_name}}" >                                        
                        <table class="fs-14 mt-2 ml-5" >
                            <thead>
                                <th></th>
                                <th class="owner-cell">{{staticText?.delivery.toe.column_heading1}} </th> 
                                <th class="owner-cell">{{staticText?.delivery.toe.column_heading2}} </th> 
                                <th class="owner-cell">{{staticText?.delivery.toe.column_heading3}} </th> 
                                <th class="owner-cell">{{staticText?.delivery.toe.column_heading4}} </th>                                                           
                                <th class="owner-cell">{{staticText?.delivery.toe.column_heading5}} </th> 
                            </thead>
                            <tbody>
                                <ng-container *ngFor="let sub_category of item?.term_category?.term_sub_category;let sindex=index">
                                    <tr>
                                        <td>
                                            <div class="d-flex mt-2 mw-250 fs-14">
                                                <div class="pr-2 text-left">{{sub_category?.display_name}}</div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr *ngFor="let toe of sub_category?.toe_details">
                                        <td>
                                            <div class="d-flex ml-60 pl-3 text-left fs-14">
                                                <div>{{toe?.title}}</div>
                                            </div>
                                        </td>
                                        <td>{{toe?.description}}</td>
                                        <td>{{toe?.function?.display_name}}</td>
                                        <td>{{toe?.is_mandatory?'No':'Yes'}}</td>
                                        <td><span *ngIf="toe?.compliance?.GDPR?.is_active =='true'">GDPR</span><span *ngIf="toe?.compliance?.GDPR?.is_active =='true' && toe?.compliance?.SOX?.is_active =='true'">/</span><span *ngIf="toe?.compliance?.SOX?.is_active =='true'">SOX</span></td>
                                         <td> <mat-icon class="color-info infoicon" (click)="openToEModal(toe,ToEModal)">info</mat-icon></td>
                                    </tr>
                                </ng-container>
                                <tr></tr>
                            </tbody>
                        </table>                                              
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
    <div class="col-md-12 d-flex" *ngIf="selectedItems?.length === 0">
        {{staticText.common.no_records_found}}
    </div>
    <div class="row px-3">
        <div class="shadow my-3 bg-white rounded col-12 py-2">
            <div class="row">
                <div class="col-md-6">
                    <h5 class="fw-bold py-2 mb-0">{{staticText?.rfx.scope.doc}}</h5>
                </div>
                <div class="col-md-6">
                    <section class="example-section">
                        <mat-checkbox class="example-margin py-2 d-flex justify-content-end text-muted"
                            [(ngModel)]="termsModel">
                        </mat-checkbox>
                    </section>
                </div>
            </div>
            <div class="row" *ngFor="let i of totalFiles">
                <div class="col-md-8 d-flex" *ngIf="totalFiles?.length">
                    <div class="me-4 mt-2">
                        <img *ngIf="i?.file.includes('.doc') || i?.file.includes('.docx')"
                            src="./assets/images/word.svg" height="40">
                        <img *ngIf="i?.file.includes('.pdf')" src="./assets/images/pdf.svg"
                            height="40">
                        <img *ngIf="i?.file.includes('.xls') || i?.file.includes('.xlsx')"
                            src="./assets/images/excel.svg" height="40">
                        <img *ngIf="i?.file.includes('.csv')" src="./assets/images/csv.png"
                            height="40">
                        <mat-icon
                            *ngIf="!i?.file.includes('.doc') && !i?.file.includes('.docx') && !i?.file.includes('.pdf')  && !i?.file.includes('.xls') && !i?.file.includes('.xlsx') && !i?.file.includes('.csv')"
                            mat-list-icon class="material-icons my-auto fs-1 text-danger mr-2">
                            description</mat-icon>
                    </div>
                    <div class="m-2 ms-0">
                        <p class="m-0">{{ i.file }}</p>
                        <p class="text-muted fs-6 fs-14">{{ i.size | number : '1.2-2' }} MB
                        </p>
                    </div>
                </div>
                <div class="col-md-4 text-end" *ngIf="totalFiles?.length">
                </div>
            </div>
            <div class="col-md-12 d-flex" *ngIf="totalFiles?.length === 0">
                No Documents Found
            </div>
        </div>
    </div> -->
</div>

<!-- <ng-template #ToEModal>
    <div class="modal-dialog  m-0">
        <div class="modal-content">
            <div class="border-top"></div>
            <div class="modal-header ">
                <h2 mat-dialog-title class="fw-bold mb-0"> ToE Details</h2>
            </div>
            <div class="modal-body"> 
                <div>
                    <table class="table table-responsive fs-14 w-100 d-table" >
                        <tr>
                            <td [attr.colspan]="3" class="risk-title">
                                <b>{{selectedToeData?.title}}</b>
                            </td>
                        </tr>
                        <tr>
                            <td class="theading">Metric Name</td>
                            <td class="theading">MoS Type</td>
                            <td class="theading">Target Value</td>
                        </tr>
                        <tr *ngFor="let metric of selectedToeData?.metrics_details">
                            <td>{{metric?.name}}</td>
                            <td>{{metric?.mos_type_name}}</td>
                            <td *ngIf="metric?.mos_type_name=='Need Improvement'">{{metric?.from_value}}-{{metric?.to_value}}</td>
                            <td *ngIf="metric?.mos_type_name=='LTB'">{{metric?.min_value}}</td>
                            <td *ngIf="metric?.mos_type_name=='HTB'">{{metric?.max_value}}</td>
                            <td *ngIf="metric?.mos_type_name=='Binary(Adherence)'">{{metric?.binary_value?'Yes':'No'}}</td>                        
                        </tr>
                        <tr *ngIf="!selectedToeData?.metrics || selectedToeData?.metrics?.length==0">
                        <td [attr.colspan]="3">{{staticText.common.no_records_found}}</td>
                        </tr>
                    </table>
                </div> 
                <div class="row border-top pt-3">
                    <div class="col-4">
                        <div class="fs-14 title-color fw-normal ms-4 mb-0">Applicability</div>
                        <div class="ms-4 fs-16 text-black mb-0">{{selectedToeData?.applicability_details |joinArrayValues:'display_name' || 'N/A'}}</div>
                    </div>
                    <div class="col-4">
                        <div class="fs-14 title-color fw-normal ms-4 mb-0">Consequences</div>
                        <div class="ms-4 fs-16 text-black mb-0">
                            {{selectedToeData?.consequences_details|slice:0:1|joinArrayValues:'display_name' || 'N/A'}}
                         </div>
                    </div>
                    <div class="col-4">
                        <div class="fs-14 title-color fw-normal ms-4 mb-0">Compliance</div>
                        <div class="ms-4 fs-16 text-black mb-0">{{selectedToeData?.compliance_details|joinArrayValues:'display_name' || 'N/A'}}</div>
                    </div>
                    <div class="col-12 mt-3">
                        <div class="fs-14 title-color fw-normal ms-4 mb-0">Evidence</div>
                        <div class="ms-4 fs-16 text-black mb-0">
                            {{selectedToeData?.evidence_details|joinArrayValues:'display_name' || 'N/A'}}
                        </div>
                    </div>
                </div>      
                    
            </div> 
            <div class="modal-footer">
                <div class="row">
                    <div>
                        <button mat-button class="saveOkr text-white text-capitalize float-right add-release" (click)="closeDialog()">{{staticText?.buttons.close_btn}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div> 
</ng-template>-->