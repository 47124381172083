
<div class="row field-adjust" id="custom-height">
    <div *ngIf="!patchResponse" style="height: calc(100vh - 200px);">
        <app-loading></app-loading>
    </div>
    <div class="col-md-12" *ngIf="patchResponse">
        <div class="row">
            <div *ngIf="isRedirectFrom" class="w-auto p-0">
                <h3 class="screen-name" (click)="clickPrevious()">
                    <mat-icon id="chevron_left" aria-hidden="false" aria-label="material-icons-filled "
                        class="cursor-pointer" style="font-size: 35px;">chevron_left
                    </mat-icon>
                </h3>
            </div>
            <div [ngClass]="isRedirectFrom?'col-11':'col-12'" class="title-holder">
                <p class="heading-color fw-500 mb-0">{{staticText?.opportunity.distribution}}</p>
                <p class="m-0 mb-1" style="font-size: 13px;">{{patchResponse?.opportunity_name}} | {{patchResponse?.opportunity_number}} | {{patchResponse?.opportunity_type_details?.name}} | {{patchResponse?.portfolio_details[0].name}} | {{patchResponse?.portfolio_details[1].name}}
                    | {{patchResponse?.program?.name}} | {{patchResponse?.owners[0].owner_details.full_name}} | {{patchResponse?.single_point_of_contact?.full_name}}
                </p>
                <app-tag [dataObj]="getTagObject(patchResponse?.id)" [tagsIds]="patchResponse?.tag_ids" *ngIf="patchResponse"></app-tag>
            </div>
            <!-- <div class="col-md-3 button-pos" *ngIf="!!stepperTitles.buttonName && !disableFields && stepperTitles.buttonName == 'Key Results'">
                <button id='+parameter' mat-button class="float-end danger fs-16" mat-flat-button
                    (click)="addPopup()">
                    <mat-icon id="add" aria-hidden="false" aria-label="material-icons-filled"
                        class="cursor-pointer fs-5">add
                    </mat-icon>
                    {{stepperTitles.buttonName}}
                </button>
            </div> -->
        </div>
        <hr>
        <mat-stepper [linear]="patchResponse?.status?.slug=='opportunity_draft'?true:false"
            labelPosition="bottom" [disableRipple]="true" #stepper
            (selectionChange)="selectedStepper($event)" id="setupStep" style="height: 67%;">
            <mat-step label="{{staticText?.rfx.setup.team}}" [stepControl]="teamForm?.disabled ?'':teamForm"
                [state]="stepStates[3]">
                <ng-template matStepLabel>
                    <p class="mat-design" matTooltip="{{staticText?.rfx.setup.team}}">
                        {{staticText?.rfx.setup.team}} </p>
                </ng-template>
                <div class="custom-height">
                    <form [formGroup]="teamCreateForm">
                        <div class="custom-table">
                            <table class="table table-bordered">
                                <thead style="position: sticky;top: 0; z-index: 1">
                                    <tr>
                                        <th class="fixed-header" scope="col">#</th>
                                        <th class="fixed-header" scope="col" class="w-25">Function</th>
                                        <th class="fixed-header" scope="col">Deal Team</th>
                                        <th class="fixed-header text-center col-2" scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let option of teamCreateForm.controls; let i = index">
                                        <td class="align-middle">{{i+1}}</td>
                                        <td class="align-middle w-25">
                                            <mat-form-field class="example-full-width" style="width: 100%" appearance="outline">
                                                <mat-label></mat-label>
                                                <input type="text"
                                                    placeholder="Select or Create Function" matInput
                                                    [formControl]="option.value.function"
                                                    [matAutocomplete]="autoFunction"
                                                    (ngModelChange)="searchFun(i)"
                                                    (input)="onInputChanged($event.target.value)"
                                                    name="function_name">
                                                <mat-autocomplete #autoFunction="matAutocomplete" (optionSelected)="onOptionSelectionChanged($event)"
                                                [displayWith]="getFunctionName.bind(this)">
                                                    <mat-option *ngFor="let item of getFunctionList(i)" [value]="item.id" style="font-size: 0.8rem; height: 30px;">
                                                        {{item.display_name}} 
                                                        <span *ngIf="!item.is_custom" class="thumbs"> 
                                                            <mat-icon style="transform: scale(0.6);">
                                                                thumb_up
                                                            </mat-icon> 
                                                        </span>
                                                    </mat-option>
                                                    <mat-option *ngIf="getFunctionList(i).length == 0" [value]="inputValue" style="font-size: 0.8rem; height: 30px;">
                                                        Create New: <span (click)="addNewFunction(i, option.value.function.value)">"{{option.value.function.value}}"</span>
                                                    </mat-option>
                                                </mat-autocomplete>
                                            </mat-form-field>
                                        </td>
                                        <td class="align-middle w-75">
                                            <mat-form-field class="example-chip-list" appearance="outline">
                                                <mat-chip-list #chipList aria-label="Members selection">
                                                <mat-chip
                                                    *ngFor="let members of option?.value.team_members.value"
                                                    [selectable]="selectable"
                                                    [removable]="removable && !disableFields"
                                                    (removed)="removeTeam(members, i)">
                                                    {{members.name || members.full_name}}
                                                    <mat-icon matChipRemove *ngIf="removable && !disableFields">cancel</mat-icon>
                                                </mat-chip>
                                                <input
                                                    placeholder="Team Members.."
                                                    *ngIf="!disableFields"
                                                    [formControl]="option.value.input"
                                                    [matAutocomplete]="auto"
                                                    [matChipInputFor]="chipList"
                                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                                    [matChipInputAddOnBlur]="addOnBlur"
                                                    (ngModelChange)="searchTeam(i)"
                                                    #operationTrigger="matAutocompleteTrigger"
                                                    name="team_name"
                                                    (click)="operationTrigger.openPanel()">
                                                </mat-chip-list>
                                                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedTeam($event, i)">
                                                    <mat-option *ngFor="let team of filteredTeamListFilter(i)" [value]="team" style="font-size: 0.8rem; height: 30px;">
                                                        {{team.name}}
                                                    </mat-option>
                                                </mat-autocomplete>
                                            </mat-form-field>
                                        </td>
                                        <td class="align-middle text-center">
                                            <div style="display: flex; align-items: center; justify-content: space-around;">
                                                <span class="cursor-pointer" (click)="removeFunction(i)"
                                                *ngIf="!disableFields">
                                                    <span class="cursor-pointer"
                                                        (click)="removeFunctionColumn(option)"
                                                        *ngIf="!disableFields">
                                                        <img src="./assets/images/comment-delete.svg"
                                                            style="width:16px;">
                                                    </span>
                                                </span>

                                                <span class="cursor-pointer" (click)="addFunctionItems(i)"
                                                *ngIf="!disableFields && teamCreateForm.controls.length - 1 === i">
                                                <mat-icon aria-hidden="false" aria-label="material-icons-filled"
                                                        class="cursor-pointer fs-5" style="margin-top: 10px;">add
                                                    </mat-icon>
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="teamCreateForm?.controls?.length <= 0">
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td class="w-25">
                                            <a class="add-btton"
                                                (click)="addFunctionItems()" *ngIf="!disableFields">
                                                <mat-icon aria-hidden="false" aria-label="material-icons-filled"
                                                    class="cursor-pointer fs-5">add
                                                </mat-icon>
                                                Add Function
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div>
                               
                            </div>
                        </div>
                    </form>
                </div>
                <mat-card-actions class="mb-0 foot-position  footer-align">
                    <div class="row" *ngIf="showNext">
                        <div class="col-md-6">
                            <div class="col-md-6 d-flex justify-content-start">
                                <button id="previous" mat-button mat-stroked-button color="primary"
                                    (click)="previous()">
                                    <mat-icon class="">chevron_left</mat-icon>
                                    <span id="prev_section" class="m-0 pe-2">
                                        {{staticText?.rfx?.prev_section}}
                                    </span>
                                </button>
                            </div>
                        </div>
                        <div class="col-md-6 text-right">
                            <button id='teamNext' mat-button mat-flat-button color="primary" class="bg-primary text-white"
                                matStepperNext (click)="next('Team')">
                                <span class="m-0 ps-2">
                                    {{staticText?.buttons?.next_btn}}
                                </span>
                                <mat-icon class="">chevron_right</mat-icon>
                            </button>
                        </div>
                    </div>
                </mat-card-actions>

            </mat-step>

            <mat-step label="OU/GDP" [state]="stepStates[4]">
                <ng-template matStepLabel>
                    <p class="mat-design" matTooltip="OU/GDP" matTooltipClass="example-tooltip">
                        {{staticText?.rfx.setup.gdps}}</p>
                </ng-template>                
                <div class="customs-height">
                    <form [formGroup]="gdpCreateForm">
                        <div class="custom-table">
                            <table class="table table-bordered">
                                <thead style="position: sticky;top: 0; z-index: 1">
                                    <tr>
                                        <th class="fixed-header" scope="col">#</th>
                                        <th class="fixed-header" scope="col" class="w-75">OU/GDP</th>
                                        <th class="fixed-header text-center col-2" scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let option of gdpCreateForm.controls; let i = index">
                                        <td class="align-middle">{{i+1}}</td>
                                        <td class="align-middle w-75">
                                            <mat-form-field class="example-full-width" style="width: 100%" appearance="outline">
                                                <mat-label></mat-label>
                                                <input type="text"
                                                    placeholder="Select OU/GDP" matInput
                                                    [formControl]="option.value.vendor"
                                                    [matAutocomplete]="autoVendorCom"
                                                    (ngModelChange)="searchVendor(i)"
                                                    (input)="onInputChanged($event.target.value)"
                                                    name="gdp_name">
                                                <mat-autocomplete #autoVendorCom="matAutocomplete" (optionSelected)="onVendorOptionSelectionChanged($event)"
                                                [displayWith]="getVendorName.bind(this)">
                                                    <mat-option *ngFor="let item of getVendors(i)" [value]="item.id" style="font-size: 0.8rem; height: 30px;">
                                                        {{item.name}} 
                                                    </mat-option>
                                                </mat-autocomplete>
                                            </mat-form-field>
                                        </td>
                                        <td class="align-middle">
                                            <div style="display: flex; align-items: center; justify-content: space-around;">
                                                <span class="cursor-pointer" (click)="removeVendor(i)"
                                                *ngIf="!disableFields">
                                                    <span class="cursor-pointer"
                                                        (click)="removeFunctionColumn(option)"
                                                        *ngIf="!disableFields">
                                                        <img src="./assets/images/comment-delete.svg"
                                                            style="width:16px;">
                                                    </span>
                                                </span>

                                                <span class="cursor-pointer" (click)="addDeliveryPartner(i)"
                                                *ngIf="!disableFields && gdpCreateForm.controls.length - 1 === i">
                                                <mat-icon aria-hidden="false" aria-label="material-icons-filled"
                                                        class="cursor-pointer fs-5" style="margin-top: 10px;">add
                                                    </mat-icon>
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="gdpCreateForm?.controls?.length <= 0">
                                        <td></td>
                                        <td></td>
                                        <td class="w-50">
                                            <a class="add-btton"
                                                (click)="addDeliveryPartner()" *ngIf="!disableFields">
                                                <mat-icon aria-hidden="false" aria-label="material-icons-filled"
                                                    class="cursor-pointer fs-5">add
                                                </mat-icon>
                                                Add Delivery Partner
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div>
                               
                            </div>
                        </div>
                    </form>
                </div>
                <mat-card-actions class="mb-0 foot-position  footer-align">
                    <div class="row" *ngIf="showNext">
                        <div class="col-md-6">
                            <button id='GDPsBack' mat-button mat-stroked-button color="primary"
                                matStepperPrevious>
                                <mat-icon class="">chevron_left</mat-icon>
                                <span class="m-0 pe-2">
                                    {{staticText?.buttons.back}}
                                </span>
                            </button>
                        </div>
                        <div class="col-md-6 text-right">
                            <button id='GDPsNext' mat-button mat-flat-button color="primary" class="bg-primary text-white"
                                matStepperNext (click)="next('OU/GDP')">
                                <span class="m-0 ps-2">
                                    {{staticText?.buttons?.next_btn}}
                                </span>
                                <mat-icon class="">chevron_right</mat-icon>
                            </button>
                        </div>
                    </div>
                </mat-card-actions>
            </mat-step>

            <mat-step label="Timeline" [state]="stepStates[5]">
                <ng-template matStepLabel>
                    <p class="mat-design" matTooltip="Timeline">
                        {{staticText?.rfx.setup.timeline}}</p>
                </ng-template>
                <div class="custom-height">
                    <form [formGroup]="timeLineCreateForm">
                        <div class="custom-table">
                            <table class="table table-bordered">
                                <thead style="position: sticky;top: 0; z-index: 1">
                                    <tr>
                                        <th class="fixed-header" scope="col">#</th>
                                        <th class="fixed-header" scope="col" class="w-50">Timeline For</th>
                                        <th class="fixed-header" scope="col" class="w-25">Date</th>
                                        <th class="fixed-header text-center col-2" scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let option of timeLineCreateForm.controls; let i = index">
                                        <td class="align-middle">{{i+1}}</td>
                                        <td class="align-middle w-50">
                                            <mat-form-field class="example-full-width" style="width: 100%" appearance="outline">
                                                <mat-label></mat-label>
                                                <input type="text"
                                                    placeholder="Select or Create Timeline" matInput
                                                    [formControl]="option.value.timeline"
                                                    [matAutocomplete]="autoTimeline"
                                                    (ngModelChange)="searchTimeline(i)"
                                                    name="timeline_name">
                                                <mat-autocomplete #autoTimeline="matAutocomplete"
                                                [displayWith]="getTimelineName.bind(this)">
                                                    <mat-option *ngFor="let item of getTimelineList(i)" [value]="item.id" style="font-size: 0.8rem; height: 30px;">
                                                        {{item.display_name}} 
                                                        <span *ngIf="!item.is_custom" class="thumbs"> 
                                                            <mat-icon style="transform: scale(0.6);">
                                                                thumb_up
                                                            </mat-icon> 
                                                        </span>
                                                    </mat-option>
                                                    <mat-option *ngIf="getTimelineList(i).length == 0" [value]="inputValue" style="font-size: 0.8rem; height: 30px;">
                                                        Create New: <span (click)="addNewTimeline(i, option.value.timeline.value)">"{{option.value.timeline.value}}"</span>
                                                    </mat-option>
                                                </mat-autocomplete>
                                            </mat-form-field>
                                        </td>
                                        <td class="align-middle w-25">
                                            <mat-form-field appearance="outline" class="w-100 py-1">
                                                <mat-label class="label-design">{{option.display_name}}
                                                </mat-label>
                                                <input matInput
                                                    [matDatepicker]="picker" readonly class="fw-500"
                                                    [formControl]="option.value.timeline_date" fullWidth
                                                    status="basic" (click)="picker.open()" name="timelinedate_name">
                                                <mat-datepicker-toggle matSuffix [for]="picker">
                                                </mat-datepicker-toggle>
                                                <mat-datepicker #picker>
                                                </mat-datepicker>
                                            </mat-form-field>

                                        </td>
                                        <td class="align-middle text-center">
                                            <div style="display: flex; align-items: center; justify-content: space-around;">
                                                <span class="cursor-pointer" (click)="removeTimeline(i)"
                                                *ngIf="!disableFields">
                                                    <span class="cursor-pointer"
                                                        *ngIf="!disableFields">
                                                        <img src="./assets/images/comment-delete.svg"
                                                            style="width:16px;">
                                                    </span>
                                                </span>

                                                <span class="cursor-pointer" (click)="addTimelineItems(i)"
                                                *ngIf="!disableFields && timeLineCreateForm.controls.length - 1 === i">
                                                <mat-icon aria-hidden="false" aria-label="material-icons-filled"
                                                        class="cursor-pointer fs-5" style="margin-top: 10px;">add
                                                    </mat-icon>
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="timeLineCreateForm?.controls?.length <= 0">
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td class="w-25">
                                            <a class="add-btton"
                                                (click)="addTimelineItems()" *ngIf="!disableFields">
                                                <mat-icon aria-hidden="false" aria-label="material-icons-filled"
                                                    class="cursor-pointer fs-5">add
                                                </mat-icon>
                                                Add Timeline
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div>
                               
                            </div>
                        </div>
                    </form>

                     <!--<div class="row" *ngIf="selectedTimelineItems?.length == 0">
                        <div class="col-md-10 mx-auto align-center">
                            <img src="./assets/images/no-record-found.png" class="no-record">
                        </div>
                        <div class="col-md-6 mx-auto">
                            <p class=" text-center text-muted">{{staticText?.rfx.setup.timeline_note}}</p>
                        </div>
                    </div>
                    <form [formGroup]="timeLineForm">
                        <div class="row my-1">
                            <div *ngFor="let option of selectedTimelineItems; index as i" class="col-md-6">
                                <div class="row">
                                    <div class="col-md-10">
                                        <div class="pe-0">
                                            <mat-form-field appearance="outline" class="w-100 py-1">
                                                <mat-label class="label-design">{{option.display_name}}
                                                </mat-label>
                                                <input id='option_display_name' matInput
                                                    [matDatepicker]="picker" readonly class="fw-500"
                                                    [formControlName]="option.display_name" fullWidth
                                                    status="basic" (click)="picker.open()">
                                                <mat-datepicker-toggle matSuffix [for]="picker">
                                                </mat-datepicker-toggle>
                                                <mat-datepicker #picker>
                                                </mat-datepicker>
                                            </mat-form-field>
                                        </div>
                                        <div *ngIf="timeLineSubmitted && time[option.display_name]?.errors">
                                            <small class="text-danger"
                                                *ngIf="time[option.display_name]?.errors.required">{{option.display_name}}
                                                is required</small>
                                        </div>
                                    </div>
                                    <div class="col my-auto p-0">
                                        <span class="cursor-pointer" (click)="removeTimelineColumn(option)"
                                            *ngIf="!disableFields">
                                            <img src="./assets/images/Group 8814.svg" style="width:16px;">
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form> -->
                </div>
                <mat-card-actions class="mb-0 foot-position  footer-align">
                    <div class="row" *ngIf="showNext">
                        <div class="col-md-6">
                            <button id='timelineBack' mat-button mat-stroked-button color="primary"
                                matStepperPrevious>
                                <mat-icon class="">chevron_left</mat-icon>
                                <span class="m-0 pe-2">
                                    {{staticText?.buttons.back}}
                                </span>
                            </button>
                        </div>
                        <div class="col-md-6 text-right">
                            <button id='timelineNext' mat-button mat-flat-button color="primary" class="bg-primary text-white"
                                matStepperNext (click)="next('Timeline')">
                                <span>
                                    {{staticText?.rfx.next_section}}
                                </span>
                                <mat-icon class="">chevron_right</mat-icon>
                            </button>
                        </div>
                    </div>
                </mat-card-actions>
            </mat-step>
        </mat-stepper>
    </div>
</div>

  