<div class="custom-height">
    <div class="row px-3">
        <div class="col-md-6">
        </div>
        <div class="col-md-6">
            <section class="example-section">
                <mat-checkbox class="example-margin py-2 d-flex justify-content-end text-muted"
                    (change)="showOptions($event)" id="scopeCheck" [(ngModel)]="scopeModel">
                </mat-checkbox>
            </section>
        </div>
    </div>
    <div *ngFor="let data of selectedCategoryItems" class="row px-3">
        <div class=" my-1 px-0 scope-border col-12">
            <div class="pt-1">
                <p class="fw-bold pt-2 ps-3 mb-0  kleft-border  key-border px-3" style="color: #1A85D6;">
                    {{data.category.display_name}}</p>
            </div>
            <div *ngFor="let i of data.category.scope">
                <div class="ps-3 my-2">
                    <p class="fs-14 title-color mb-0">{{i.title}}</p>
                    <p class="my-1 fs-16  text-black">{{i.description}}</p>
                </div>

                <div class="row">
                    <div class="col-md-4 col-12" *ngFor="let s of i.parameter">
                        <p class="fs-14 title-color ps-3 d-flex my-1">{{s.display_name}}</p>
                        <p class="ps-3 pb-2 my-1 text-black">{{s.scope_parameter_value || '-'}}</p>
                    </div>
                    <hr class="m-0 mx-2" *ngIf="data?.category?.scope?.length > 1">
                </div>
            </div>
        </div>
    </div>
    <div class="row px-3">
        <div class=" my-1 px-0 scope-border col-12">
            <div class="row">
                <div class="col-md-6 pt-3">
                    <p class="fw-bold empall-border eleft-border pt-2 " style="color: #F86443;"><span
                            class="px-3">{{staticText?.rfx.scope.doc}}</span></p>
                </div>
                <div class="col-md-6">
                    <section class="example-section">
                        <mat-checkbox class="example-margin py-2 d-flex justify-content-end text-muted" id="scopeCheck" [(ngModel)]="scopeDocumentModal">
                        </mat-checkbox>
                    </section>
                </div>
            </div>
            <div class="row" *ngFor="let s of totalFiles">
                <div class="col-md-8 d-flex" *ngIf="totalFiles?.length">
                    <div class="me-4 mt-2 px-2">
                        <img *ngIf="s.doc == 'doc' || s.doc == 'docx'" src="./assets/images/docs.png"
                            height="40">
                        <img *ngIf="s.doc == 'pdf'" src="./assets/images/pdf.png" height="40">
                        <mat-icon *ngIf="s.doc !== 'pdf' && s.doc !== 'doc' && s.doc !== 'docx'" mat-list-icon
                            class="material-icons my-auto fs-1 text-danger">description</mat-icon>
                    </div>
                    <div class="m-2 ms-0">
                        <p class="m-0 fs-16">{{ s.file }}</p>
                        <p class="text-muted fs-16">{{ s.size | number : '1.2-2' }} MB
                        </p>
                    </div>
                </div>
                <div class="col-md-4 text-end">
                </div>
            </div>
            <div class="col-md-12 d-flex" *ngIf="totalFiles.length ==0">
                {{staticText.common.no_records_found}}
            </div>
        </div>
    </div>
</div>