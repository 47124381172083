import { ViewEncapsulation } from '@angular/core';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { GetProposalService } from 'src/app/shared/services/get-proposal.service';
import { ProgramService } from 'src/app/shared/services/program.service';
import { TermsService } from 'src/app/shared/services/terms.service';

import * as textConfiguration from 'src/assets/static-text-configuration.json';
@Component({
  selector: 'app-preview-terms',
  templateUrl: './preview-terms.component.html',
  styleUrls: ['./preview-terms.component.scss'],
  encapsulation:ViewEncapsulation.Emulated
})
export class PreviewTermsComponent implements OnInit {
  staticText: any = (textConfiguration as any).default;
  opportunityId: any;
  selectedItems: any;
  subCategory: any;
  totalFiles: any;
  @Output() sendSelected = new EventEmitter<{}>();
  exportPayload: any;
  termsModel = true;
  selectedSetupChildIndex:number = 0;
  selectedToeData: any={};
  toeMasterSelectedData:any = [];

  constructor(
    private ps: ProgramService,
    private proposalService : GetProposalService,
    private route: ActivatedRoute,
    private termsService: TermsService,
    private spinner: NgxSpinnerService,
    private toastrService: ToastrService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.route.parent?.parent?.params.subscribe((res: any) => {
      this.opportunityId = atob(res.id);
      // this.getOpportunityById();
      // this.getFile();
      this.getOpportunityTermsMatrix();
    });
  }

 async getOpportunityTermsMatrix(){
   await this.proposalService.getOpportunityTermsDetails(this.opportunityId).subscribe((resp: any) => {
    this.toeMasterSelectedData = resp;
});

    // this.addCategories('')
  }



  getOpportunityById() {
    this.ps.getOpportunityById(this.opportunityId).subscribe((res: any) => {
    
      this.selectedItems = res.contract_terms;
      (this.selectedItems || []).forEach((i: any) => {
        i.term_category.term_subcategory
      });
    }, error => {
      throw error;
    });
  }
  openToEModal(mos: any,  modal: any) {
    this.selectedToeData = mos
    this.selectedToeData['consequences_details'] = this.selectedToeData['consequences_details'].map((o:any)=>{
      return {...o,...o.consequences_details}
    });
    this.dialog.open(modal, {panelClass:"mw-650"});
  }

  showOptions(e: any) {
    if(e.source.id == 'termsCheck') {
      this.toastrService.warning('Please save the changes');
      this.ps.getOpportunityFormData('terms_of_engagement').subscribe((res: any) => {
        const sectionId = res.records[0].form_details.id;
        this.exportPayload = {
          section: sectionId,
          export: e.checked
        };
        this.sendSelected.emit(this.exportPayload);
      }, error => {
        throw error;
      });
    }
  }

  getFile() {
    this.termsService.getFile(this.opportunityId).subscribe((res: any) => {  
      this.totalFiles = res;
      this.totalFiles.map((i: any) => {
        i.size = i.size / 1000000
      });
    }, error => {
      throw error;
    });
  }

  downloadFile(e: any) {
    var payload = {
      file_name: e.file
    }
    return;
    this.spinner.show();
    this.termsService.downloadFile(payload, this.opportunityId).subscribe((res: any) => {
      let downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(res);
      downloadLink.setAttribute('download', e.file);
      document.body.appendChild(downloadLink);
      downloadLink.click();
      this.toastrService.success('File downloaded successfully');
      this.spinner.hide();
    }, error => {
      throw error;
    });
  }
  closeDialog() {
    this.dialog.closeAll();
  }

}
