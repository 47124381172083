import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EvaluationMatrixComponent } from './evaluation-matrix/evaluation-matrix.component';
import { MoreInformationComponent } from './more-information/more-information.component';
import { PreviewComponent } from './preview/preview.component';
import { ProfileComponent } from './profile/profile.component';
import { ScopeComponent } from './scope/scope.component';
import { SetupComponent } from './setup/setup.component';
import { TermsComponent } from './terms/terms.component';
import { ToeComponent } from './toe/toe.component';
import { DistributionComponent } from './distribution/distribution.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'preview',
    pathMatch: 'full'
  },
  {
    path: 'setup',
    component: SetupComponent,//data: { breadcrumb: 'Opportunity' } 
  },
  {
    path: 'profile',
    component: ProfileComponent,//data: { breadcrumb: 'Opportunity' } 
  },
  {
    path: 'scope',
    component: ScopeComponent,//data: { breadcrumb: 'Scope' } 
  },
  {
    path: 'evaluation',
    component: EvaluationMatrixComponent,//data: { breadcrumb: 'Evaluation Matrix' } 
  },
  {
    path: 'more-info',
    component: MoreInformationComponent,//data: { breadcrumb: 'More Information' } 
  },
  {
    path: 'terms',
    component:ToeComponent
    //component: TermsComponent,//data: { breadcrumb: 'Terms of Engagement' } 
  },
  {
    path: 'preview',
    component: PreviewComponent,//data: { breadcrumb: 'Preview' } 
  },
  {
    path: 'distribution',
    component: DistributionComponent,//data: { breadcrumb: 'Preview' } 
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OpportunityRoutingModule { }
