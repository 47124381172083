<div class="custom-height">
    <div class="row  px-4">
        <div class="col-md-6">
        </div>
        <div class="col-md-6">
            <section class="example-section">
                <mat-checkbox class="example-margin d-flex justify-content-end text-muted"
                    (change)="showOptions($event)" id="moreInfoCheck" [(ngModel)]="moreInfoModel">
                </mat-checkbox>
            </section>
        </div>
    </div>
    <div *ngFor="let s of newFileArray" class="row px-3">
        <div class="info-border my-2 ps-0  col-12">
            <p class="fw-bold mb-2 fs-12 doc-border dleft-border"><span class="px-3" style="position: relative;
            top: 10px; color: #ffffff">{{s.name}}</span></p>
            <div class="row">
                <div class="col-md-12 d-flex">
                    <div class="me-4 mt-2 px-2">
                        <img *ngIf="s.doc == 'doc' || s.doc == 'docx'"
                            src="./assets/images/word.svg" height="40">
                        <img *ngIf="s.doc == 'pdf'" src="./assets/images/pdf.svg"
                            height="40">
                        <img *ngIf="s.doc == 'xls' || s.doc == 'xlsx'"
                            src="./assets/images/excel.svg" height="40">
                        <img *ngIf="s.doc == 'csv'" src="./assets/images/csv.png"
                            height="40">
                        <mat-icon
                            *ngIf="s.doc !== 'pdf' && s.doc !== 'doc' && s.doc !== 'docx' && s.doc !== 'xls' && s.doc !== 'xlsx' && s.doc !== 'csv'"
                            mat-list-icon class="material-icons my-auto fs-1 text-danger mr-2">
                            description</mat-icon>
                    </div>
                    <div class="m-2 ms-0 mb-0">
                        <p class="m-0">{{s.file}}</p>
                        <p class="text-muted fs-6" style="font-size: 14px;">{{staticText?.common.last_updated}}: {{s.date}}
                        </p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>