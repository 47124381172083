import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { saveAs } from "file-saver";
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { pdfExporter } from 'quill-to-pdf';
import { MoreInformationService } from 'src/app/shared/services/more-information.service';
import { ProgramService } from 'src/app/shared/services/program.service';
var _ = require('lodash');
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
import { ViewEncapsulation } from '@angular/core';
import { forEach } from 'lodash';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BreadcrumbService } from 'src/app/shared/services/breadcrumb.service';
import { MessageService } from 'src/app/shared/services/message.service';


declare let Quill: any; // declare Quill to make TypeScript happy :)
@Component({
  selector: 'app-more-information',
  templateUrl: './more-information.component.html',
  styleUrls: ['./more-information.component.scss'],
  encapsulation:ViewEncapsulation.Emulated
})
export class MoreInformationComponent implements OnInit, OnDestroy {
  @ViewChild('fileInput1') file: ElementRef;

  staticText: any = (textConfiguration as any).default;
  disableFields: boolean=false;
  initalLoad = false;
  side = false;
  searchText = '';
  tips = true;
  sectionTitle: any;
  section = false;
  search = false;
  opportunityId: any;
  textContents = {
    searchText: 'Document searched is not found in the list'
  }
  sectionList: any[] = [];
  customSectionList: any[] = [];
  updateItems: any;
  selectedSection: any[] = [];
  selectedSectionInitial:any[]=[];
  addDocument = false;
  showFile = false;
  quillInstance: any;
  showSave = false;
  newsectionList: any;
  newCustomSectionList: any;
  fileName: any;
  fileArray: any[] = [];
  fileResData: any[] = [];
  newFileArray: any[] = [];
  showContent: boolean | any;
  displayName: any;
  exportPayload: any[] = [];
  maxChars = 100;
  data:any
  showTab='help';
  isRedirectFrom = false;
  projectID:any='';
  sub:any;
  newAddedSection: any[]=[];
  opportunityInfo: any;

  filteredDocumentList: any;
  moreInfoCreateForm: FormGroup | any;
  breadcrumb: any;

  constructor(private router: Router,
    private moreInfoService: MoreInformationService,
    private spinner: NgxSpinnerService,
    private toastrService: ToastrService,
    private ps: ProgramService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private breadCrumService: BreadcrumbService,
    private messageService: MessageService
    ) {
      this.createForm();
     }

  ngOnInit(): void {  
    this.messageService.setGnews('Opportunity More Information')
    this.sub = this.route
      .queryParams
      .subscribe((params:any) => {
        this.isRedirectFrom = params['redirectFrom'] || false;
        this.projectID = params['id']; 
      });
      let oppInfo = localStorage.getItem('opportunity-info');
      this.opportunityInfo = typeof(oppInfo) === 'string' ? JSON.parse(oppInfo) : {};
      this.breadCrumService.breadcrumbs$.subscribe((bc: any) => {
        this.breadcrumb = bc;
      })
    this.addDocument = true;
    this.route.parent?.parent?.params.subscribe((res: any) => {
      this.opportunityId = atob(res.id);
      this.getSection(1);
    });
  }

  createForm(){
    this.moreInfoCreateForm = this.formBuilder.array([]);
  }

  openScope() {
    this.showTab='add_document'
  }

  addDocumentRo(){
    this.moreInfoCreateForm.push(
      this.formBuilder.control({
        section: new FormControl('', [Validators.required]),
        file: new FormControl('', [Validators.required]),
        extension: new FormControl(''),
        date: new FormControl('')
      })
    )
  }

  addNewDocument(index: any, documentName: any) {
    if (documentName) {
      this.spinner.show();
      var payload = {
        name: documentName,
        display_name: documentName,
      }
      this.moreInfoService.createSection(payload).subscribe((res: any) => {
        if (res.status == 208 || res.status == 200) {
          this.spinner.hide();
          this.toastrService.error('Section name already available, please verify');
          return;
        }
        this.sectionList.push(res.body)
        this.spinner.hide();
      }, error => {
        this.spinner.hide();
        this.toastrService.error('Could not Section Name');
        throw error;
      });      
    }
    else {
      this.toastrService.error('Please enter a document name');
    }
  }

  getSection(type?: any) {
    this.ps.getOpportunityFormData('more_info').subscribe((res: any) => {
      let breadCrumbFirst: any = [this.breadcrumb[0]];
      let breadCrumbLast: any = [this.breadcrumb[this.breadcrumb.length - 1]];
      let breadCrumb = [
        { label: this.opportunityInfo.portfolio, url: '/program', queryParams: {portfolio: this.opportunityInfo.portfolio_id} },
        { label: this.opportunityInfo.subportfolio, url: '/program', queryParams: {subportfolio: this.opportunityInfo.subportfolio_id} },
        { label: this.opportunityInfo.program, url: '/program', queryParams: {program: this.opportunityInfo.program_id} }
      ];
      breadCrumbFirst.push(...breadCrumb, ...breadCrumbLast)
  
      this.breadCrumService.setBreadcrumb(breadCrumbFirst);

      const list = res.records[0].field_values;
      this.sectionList = list;
      this.filteredDocumentList = this.sectionList;
      
      if(type == 1){
        this.getOpportunityById();
        this.getAllDynamicFormData();
      }
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.toastrService.error('Could not get documents');
      throw error;
    });
  }

  searchDocument(index: any){
    return this.getDocument(index)
  }

  getDocumentName(documentId: any) {
    if(documentId){
      return this.filteredDocumentList.find((item: any) => item.id === documentId).name;
    }
  }

  getDocument(index: any){
    if(this.sectionList){
      let filteringArray = this.moreInfoCreateForm.value ? this.moreInfoCreateForm.value : [];
      var filteredArray  = filteringArray.length > 0 ? this.sectionList.filter(function(array_el: any){
        return filteringArray.filter(function(chl: any){
            return chl.section.value == array_el.id;
        }).length == 0
      }) : this.sectionList;

      filteredArray = typeof this.moreInfoCreateForm.controls[index].value.section.value === 'string' ? filteredArray.filter((f: any) => f.name.toLowerCase().indexOf(this.moreInfoCreateForm.controls[index].value.section.value.toLowerCase()) === 0) : filteredArray;
      return filteredArray;
    }else{
      return [];
    }
  }

  removeDocument(index: any){
    if(this.moreInfoCreateForm.controls[index].value.file.value){
      this.toastrService.error('Please delete file before removing the section');
      return;
    }
    this.moreInfoCreateForm.removeAt(index);
  }


  removeColumn(item: any) {
    const tempFiles = this.fileArray.filter((s: any) => s.section == item.id);
    if(tempFiles.length > 0) {
      if(tempFiles[0].triggerDelete == false) {
        this.toastrService.error('Please delete file before removing the section');
        return;
      }
    }
    this.fileArray = this.fileArray.filter((s: any) => s.section != item.id);
    this.selectedSection = this.selectedSection.filter((e: any) => e.id !== item.id)
    if(this.fileArray.length > 0) {
      const deletePayload = this.fileArray.filter((s: any) => s.section == item.id);
      if(deletePayload.length > 0) {
        const payload = {
          file_name: deletePayload[0].file,
          section_id: deletePayload[0].section
        }
        this.moreInfoService.deleteFile(payload, this.opportunityId).subscribe((res: any) => {
        }, error => {
          this.spinner.hide();
          throw error;
        });
      }
    }
    if (item.is_custom == true) {
      this.customSectionList.push(item);
    } else {
      this.sectionList.push(item);
    }
    this.newsectionList = JSON.stringify(this.sectionList);
    this.newCustomSectionList = JSON.stringify(this.customSectionList);
  }

  deleteCustom(e: any) {
    this.spinner.show();
    this.moreInfoService.deleteSection(e).subscribe((data: any) => {
      this.spinner.hide();
      this.dialog.closeAll();
      const index = this.sectionList.findIndex(k => k.id == e);
      this.sectionList.splice(index, 1);
       
     });
  }

  async exportPdf() {
    this.showSave = true;
    const delta = this.quillInstance.getContents();
    const blob = await pdfExporter.generatePdf(delta);
    saveAs(blob as Blob, "pdf-export.pdf");
    this.showSave = false;
    this.showFile = false;
    this.addDocument = true;
  }

  setUpQuill() {
    this.quillInstance = new Quill("#editor", {
      theme: "snow",
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
          ['code-block'],
          [{ 'header': 1 }, { 'header': 2 }],               // custom button values
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
          [{ 'font': [] }],
          [{ 'align': [] }],
          ['clean'],                                         // remove formatting button
          ['link'],
        ]
      }
    });
  }

  clickPrevious() { 
    this.ps.getOppData(this.opportunityId).subscribe((res)=>{this.ps.setStatusValue(res?.status);this.router.navigate([`rfx/`+btoa(this.opportunityId)+`/opportunity/evaluation`])});
  }

  clickNext() { 
    // const noChange = JSON.stringify(this.selectedSection) === JSON.stringify(this.selectedSectionInitial);
    // if(noChange){
      this.save();
      this.ps.setValue(5);
      this.ps.getOppData(this.opportunityId).subscribe((res)=>{this.ps.setStatusValue(res?.status);this.router.navigate([`rfx/`+btoa(this.opportunityId)+`/opportunity/preview`])});
      return;
    // }   
    // this.save();
  }

  onFileSelected(e: any, option: any, i: any) {
    if(!option.value.section.value){
      this.file.nativeElement.value = '';
      this.toastrService.error('Please select document type before uploading');
      return;
    }
    const file = e;
    const fd: any = new FormData();
    fd.append('file_name', file.target.files[0].name);
    fd.append('file', file.target.files[0]);
    fd.append('section_id', option.value.section.value);
    if (file.target.files[0].size > 5000000) {
      this.toastrService.error('Please upload less than 5MB');
      return;
    }
    this.spinner.show();
    this.moreInfoService.uploadFile(fd, this.opportunityId).subscribe((res: any) => {
      this.toastrService.success('File uploaded successfully');
      this.moreInfoCreateForm.controls[i].value.file.setValue(file.target.files[0].name);
      let filename = file.target.files[0].name;
      this.moreInfoCreateForm.controls[i].value.extension.setValue(filename.substring(filename.lastIndexOf('.') + 1).toLowerCase());
      this.moreInfoCreateForm.controls[i].value.date.setValue(moment().format('ll'));
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      throw error;
    });
  }

  getFileExtension(file: any){
    if(file){
      return file.substring(file.lastIndexOf('.') + 1).toLowerCase(); 
    }else{
      return "";
    }
  }

  downloadFile(option: any) {
    var payload = {
      file_name: option.value.file.value,
      section_id: option.value.section.value
    }
    this.spinner.show();
    this.moreInfoService.downloadFile(payload, this.opportunityId).subscribe((res: any) => {
      let downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(res);
      downloadLink.setAttribute('download', option.value.file.value);
      document.body.appendChild(downloadLink);
      downloadLink.click();
      this.toastrService.success('File downloaded successfully');
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      throw error;
    });
  }


  getAllDynamicFormData() {
    this.exportPayload = [];
    this.ps.getDynamicForm().subscribe((res: any) => {
      this.exportPayload = res.records.map((x: any) => {
        return { section: x.form_details.id, export: true };
      });
    }, error => {
      throw error;
    });
  }

  
  getOpportunityById() {
    this.spinner.show();
    this.moreInfoCreateForm.clear();
    this.ps.getOpportunityMoreInfo(this.opportunityId).subscribe((resp: any) => {
      let res = resp?.records[0]
      this.data = res;
      this.ps.setStatusValue(res?.status);
      if(res.status.slug == 'opportunity_evaluating' || res.status.slug == 'opportunity_sow_signed' || res.status.slug == 'opportunity_cancelled'){
        this.disableFields =true;
      }
      this.spinner.hide();
      this.fileResData = res.more_info;
      if (this.fileResData) {
        this.fileResData.forEach((f: any) => {
          let filename = f.file.substring(f.file.lastIndexOf('.') + 1).toLowerCase();
          
          this.moreInfoCreateForm.push(
            this.formBuilder.control({
              section: new FormControl(f.section_details.id, [Validators.required]),
              file: new FormControl(f.file, [Validators.required]),
              extension: new FormControl(filename),
              date: new FormControl(moment(f.section_details.last_updated_on).format('ll'))
            })
          )
        });
      }
      
    }, error => {
      this.spinner.hide();
      throw error;
    });
  }

  deleteFile(option: any, index: any) {
    var payload = {
      file_name: option.value.file.value,
      section_id: option.value.section.value
    }

    this.spinner.show();
    this.moreInfoService.deleteFile(payload, this.opportunityId).subscribe((res: any) => {
      this.toastrService.success('File deleted successfully');
      this.moreInfoCreateForm.controls[index].value.file.setValue("");
      this.moreInfoCreateForm.controls[index].value.extension.setValue("");
      this.moreInfoCreateForm.controls[index].value.date.setValue("");
      this.spinner.hide();
      this.dialog.closeAll();
    }, error => {
      this.spinner.hide();
      throw error;
    });
  }

  save() {
    var export_section: any[] = [];
    this.exportPayload.forEach((x) => {
      if (export_section.findIndex(y => y.section == x.section) == -1)
        export_section.push(x);
    });
    let payload:any = {
      export_section: export_section,
      more_info: []
    }
    this.spinner.show();
    this.moreInfoCreateForm.value.forEach((element: any) => {
      payload.more_info.push({
        file: element.file.value,
        section: element.section.value,
        triggerDelete: false
      })
    });
    this.ps.exportOpportunities(this.opportunityId, payload).subscribe((res: any) => {
      this.saveData();
    }, error => {
      this.spinner.hide();
      throw error;
    });
  }

  saveData() {
    var payload = {
      action: "Save"
    }
    this.spinner.show();
    this.ps.opportunityActions(this.opportunityId, payload).subscribe((res: any) => {
      if (res.status == 208 || res.status == 200) {
        this.spinner.hide();
        this.toastrService.success('Document saved successfully');
        return;
      }
    }, error => {
      this.spinner.hide();
      throw error;
    });
       
    this.ps.setValue(5)
    this.ps.getOppData(this.opportunityId).subscribe((res)=>{this.ps.setStatusValue(res?.status);this.router.navigate([`rfx/`+btoa(this.opportunityId)+`/opportunity/preview`])});
  }
  clickPrevious1(){
    this.router.navigate([`view/${this.projectID}/overview`])
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  getTagObject(id: any){
    return {
      entity_id: id,
      entity_type: "opportunity"
    }
  }
}
