import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ProgramService } from 'src/app/shared/services/program.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { NgxSpinner, NgxSpinnerService } from 'ngx-spinner';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { map, startWith, tap } from 'rxjs/operators';
import { DataService } from 'src/app/shared/services/data.service';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ColumnMode } from '@swimlane/ngx-datatable';
import * as moment from 'moment';
import { LodashService } from 'src/app/shared/services/lodash.service';
import { MatDialog } from '@angular/material/dialog';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
import { FormValidatorService } from 'src/app/shared/services/form-validator/form-validator.service';
import { MatStepper } from '@angular/material/stepper';
import { Observable, combineLatest } from 'rxjs';
import { CustomPropertiesComponent } from '../custom-properties/custom-properties.component';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { BreadcrumbService } from 'src/app/shared/services/breadcrumb.service';
import { MessageService } from 'src/app/shared/services/message.service';
var _ = require('lodash');
export interface nameList {
  name: string;
}
@Component({
  selector: 'app-distribution',
  templateUrl: './distribution.component.html',
  styleUrls: ['./distribution.component.scss']
})
export class DistributionComponent implements OnInit {
  staticText: any = (textConfiguration as any).default;

  timeLineForm: FormBuilder | any;
  profileForm: FormBuilder | any;
  setupForm: FormGroup | any;
  goalForm: FormGroup | any;
  keyForm: FormGroup | any;
  teamForm: FormGroup | any;
  showAddField: boolean | any;
  @ViewChild('stepper') stepper: MatStepper | any;

  id: any[] = [];
  items: any[] = [];
  nameList: any[] = [];
  userList: any[] = [];
  chipsList: any[] = [];
  keyRecords: any[] = [];
  goalTitles: any[] = [];
  keyResult: any[] = [];
  customItems: any[] = [];
  customRecords: any[] = [];
  selectedItems: any[] = [];
  parameterList: any[] = [];
  // Slave key result for right pane which is list component
  slaveKeyResult: any[] = [];
  slaveKeyResultClone: any[] = [];
  // Master key result for centerpane
  masterKeyResult: any[] = [];
  masterKeyResultInitial: any[] = [];
  selectedKeyItems: any[] = [];
  customNewKeyItems: any[] = [];
  customNewItems: any[] = [];
  selectedVendorItems: any[] = [];
  selectedVendorItemsInitial: any[] = [];
  selectedTimelineItems: any[] = [];
  selectedFunctionItems: any[] = [];
  constantKeyResults: any[] = [];
  customTimeline: any[] = [];
  teamArrayToFilter: any[] = [];

  disableFields = false;

  newCustomItems: any;
  keyDescriptions: any;
  disDescription: any;
  updateItems: any;
  newItems: any;
  cutomupdateItems: any;
  opportunityId: any;
  parameterName: any;

  setup_data: any = {};

  textContents = {
    searchText: 'Parameter searched is not found in the list'
  }
  stepperTitles = {
    title: 'Opportunity Setup',
    buttonName: 'Parameter'
  }


  tips = true
  sideHelp = true;
  showPara = true;
  removable = true;
  addOnBlur = true;
  selectable = true;
  side = false;
  side1 = false;
  keyExist = false;
  keySubmit = false;
  initalLoad = false;
  goalExist = false;
  isLinear = false;
  showNext = false;
  errorMsg = false;
  search = false;
  newTimeline = false;
  submitted = false;
  teamSubmit = false;
  teamFunction = false;
  profSubmitted = false;
  customKeypanel = false;
  isMemberSelected = false;
  timeLineSubmitted = false;
  showSecondaryKey = false;
  customParameter = false;
  editShow = false;

  keyData: any;
  data: any;
  teamList: any;
  username: any;
  goalPayload: any;
  vendorList: any;
  permission: any;
  functionList: any;
  functionName: any;
  newGoalItems: any;
  updateKeyItems: any;
  filterOptionTitle: any;
  newFunctionItems: any;
  newCustomFunctionItems: any;
  customFunctionList: any;
  unFilteredTeamList: any;
  selectedMember: any;
  updateTeamListItems: any;
  newTeamListItems: any;
  newVendorItems: any;
  vendorId: any;
  profileList: any;
  categoryList: any;
  clasificationList: any;
  triggerList: any;
  workList: any;
  statusList: any;
  teamPayload: any;
  patchResponse: any;
  timelineName: any;
  timelineList: any;
  newTimelineItems: any;
  newCustomTimeItems: any;
  filterSearchItems: any;
  keyOptionTitle: any;
  keyResultResponse: any;
  stepLabel: any;

  customStyle = {
    backgroundColor: "#f1f1fc",
    borderRadius: "50%",
    color: "#232459",
    cursor: "pointer",
    fontWeight: 'bolder'
  };
  stepStates: any = [];

  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  searchText = '';
  searchKeyText = '';
  maxChars = 100;
  ColumnMode = ColumnMode;
  readonly headerHeight = 50;
  readonly rowHeight = 50;
  hasChange: boolean = false;

  options2: any;
  filteredOptions2: any;
  options1: any;
  filteredOptions1: any;
  subPortOptions: any;
  ownersOptions: any;
  spocOptions: any;
  programOptions: any;

  subPortfilteredOptions: any;
  spocFilteredOptions: any;
  programFilteredOptions: any;


  filterOptionType: Observable<any[]> | any;
  filterOptionPortfolio: Observable<any[]> | any;
  filterOptionSubPortfolio: Observable<any[]> | any;
  filterOptionOwners: Observable<any[]> | any;
  filterOptionSpoc: Observable<any[]> | any;
  filterOptionProgram: Observable<any[]> | any;

  OpportunityFieldList: any = [];

  selectedOwnerDetails: any = {};
  subPortFolioChild: any[] = [];
  isRedirectFrom = false;
  projectID: any = '';
  sub: any;

  context: any;

  filteredItems: any = [];

  filteredTeamList: any;
  teamCreateForm: FormGroup | any;

  filteredVendorList: any;
  gdpCreateForm: FormGroup | any;

  filteredTimeLineList: any;
  timeLineCreateForm: FormGroup | any;

  okrArray: any;
  selectedOkr: any;
  breadcrumb: any;

  constructor(
    private formBuilder: FormBuilder,
    private ps: ProgramService,
    private toastrService: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private permissionsService: NgxPermissionsService,
    private dataService: DataService,
    private lodashService: LodashService,
    public dialog: MatDialog,
    private _formValidator: FormValidatorService,
    private breadCrumService: BreadcrumbService,
    private messageService: MessageService
  ) {
    this.context = {
      componentParent: this,
    };
    this.createForm();
  }

  ngOnInit(): void {
    this.messageService.setGnews('Opportunity recipient and timeline')
    this.sub = this.route
      .queryParams
      .subscribe((params: any) => {
        this.isRedirectFrom = params['redirectFrom'] || false;
        this.projectID = params['id'];
      });
    setTimeout(() => {
      this.showNext = true;
    }, 1100)
    const perm: any = JSON.parse(localStorage.getItem('permission') || '{}');

    this.permissionsService.loadPermissions(perm.permissions);
    this.route.parent?.parent?.params.subscribe((res: any) => {
      this.opportunityId = atob(res.id)
      // this.getOpportunityById();
    })
    this.data = {
      category: false,
      classification: false,
      triggers: false,
      work: false,
      fundingStatus: false,
    }
    this.customFunction();
    // this.getOkr();
    //this.getProfileData();
    //this.getCustomkeyResults();
    this.filterOptionTitle = this.keyForm.get('goalTitle')!.valueChanges
      .pipe(
        startWith(''),
        map((value: any) => typeof value === 'string' ? value : value?.goalTitle),
        map((goalTitle: any) => goalTitle ? this.typeFilter(goalTitle) : this.goalTitles.slice())
      );
    //  this.getTeamList(1);
    this.getTeamList(0);
    // this.getVendorList();
    // this.getTimeline();
    this.keyForm.get("goalTitle").valueChanges.subscribe((item: any) => {
      if (!item?.goalTitle) {
        this.keyForm.patchValue({
          goalDescription: ''
        })
      }
    })
    this.username = JSON.parse(localStorage.getItem('permission') || '{}');
    // this.keyPatch();

    this.breadCrumService.breadcrumbs$.subscribe((bc: any) => {
      this.breadcrumb = bc;
    })
  }
  async customFunction() {

    await this.getList();
    this.openParameter();
  }

  private typeFilter(value: string): any {
    const filterValue = value.toLowerCase();
    return this.filteredOptions2?.filter((i: any) => i.name.toLowerCase().includes(filterValue));
  }
  private portfolioFilter(value: string): any {
    const filterValue = value.toLowerCase();
    return this.filteredOptions1?.filter((i: any) => i.name.toLowerCase().includes(filterValue));
  }
  private subPortfolioFilter(value: string): any {
    const filterValue = value.toLowerCase();
    return this.subPortfilteredOptions?.filter((i: any) => i.name.toLowerCase().includes(filterValue));
  }
  // private spocFilter(value: string): any {
  //   const filterValue = value.toLowerCase();
  //   return this.spocFilteredOptions?.filter((i: any) => i.name.toLowerCase().includes(filterValue))
  // }
  private programFilter(value: string): any {
    const filterValue = value.toLowerCase();
    return this.programFilteredOptions?.filter((i: any) => i.name.toLowerCase().includes(filterValue))
  }
  initAutoComplete(): any {
    this.filterOptionType = this.setupForm.get('opportunity')!.valueChanges
      .pipe(
        startWith(''),
        map((value: any) => typeof value === 'string' ? value : value?.name),
        map((name: any) => name ? this.typeFilter(name) : this.filteredOptions2.slice())
      );
    this.filterOptionPortfolio = this.setupForm.get('portfolio')!.valueChanges
      .pipe(
        startWith(''),
        map((value: any) => typeof value === 'string' ? value : value?.name),
        map((name: any) => name ? this.portfolioFilter(name) : this.filteredOptions1.slice())
      );
    // this.filterOptionSpoc = this.setupForm.get('spoc')!.valueChanges
    //   .pipe(
    //     startWith(''),
    //     map((value: any) => typeof value === 'string' ? value : value?.name),
    //     map((name: any) => name ? this.spocFilter(name) : this.spocFilteredOptions?.slice())
    //   );     
    this.filterOptionProgram = this.setupForm.get('program')!.valueChanges
      .pipe(
        startWith(''),
        map((value: any) => typeof value === 'string' ? value : value?.name),
        map((name: any) => name ? this.programFilter(name) : this.programFilteredOptions?.slice())
      );
    this.valuePatch(this.patchResponse);
  }

  createForm() {
    this.setupForm = this.formBuilder.group({
      opportunity_brief: [''],
      name: ['', [Validators.required]],
      opportunity: ['', [Validators.required, this._formValidator.autocompleteObjectValidator()]],
      portfolio: ['', [Validators.required, this._formValidator.autocompleteObjectValidator()]],
      subPortfolio: ['', [Validators.required, this._formValidator.autocompleteObjectValidator()]],
      program: [''],
      owners: ['', [Validators.required]],
      // spoc: ['',[Validators.required,this._formValidator.autocompleteObjectValidator()]]
    });
    this.keyForm = this.formBuilder.group({
      goalTitle: ['', [Validators.required, this._formValidator.autocompleteObjectValidator]],
      goalDescription: ['', Validators.required],
      keyTitle: ['', Validators.required],
      keyDescription: ['', Validators.required],
      childKeyTitle: ['', Validators.required],
      // childKeyDescription: ['', Validators.required],
      primaryOwner: ['', Validators.required],
      secondaryOwner: [''],
      primaryChildOwner: ['', Validators.required],
      secondaryChildOwner: ['']
    });
    this.teamForm = this.formBuilder.group({
    });

    this.teamCreateForm = this.formBuilder.array([]);
    this.gdpCreateForm = this.formBuilder.array([]);
    this.timeLineCreateForm = this.formBuilder.array([]);

    this.profileForm = this.formBuilder.group({
      category: ['', Validators.required],
      classification: ['', Validators.required],
      triggers: ['', Validators.required],
      work: ['', Validators.required],
      fundingStatus: [false, Validators.required],
    });
    this.timeLineForm = this.formBuilder.group({
    });

  }

  getOpportunityById() {
    //this.spinner.show();

    this.ps.getSingleOpportunityById(this.opportunityId).subscribe((res: any) => {
      let breadCrumbFirst: any = [this.breadcrumb[0]];
      let breadCrumbLast: any = [this.breadcrumb[this.breadcrumb.length - 1]];
      let breadCrumb = [
        { label: res.portfolio_details[0].name, url: '/program', queryParams: { portfolio: res?.portfolio_details[0]?.id } },
        { label: res.portfolio_details[1].name, url: '/program', queryParams: { subportfolio: res?.portfolio_details[1]?.id } },
        { label: res.program?.name, url: '/program', queryParams: { program: res?.program?.id } }
      ];
      breadCrumbFirst.push(...breadCrumb, ...breadCrumbLast)

      this.breadCrumService.setBreadcrumb(breadCrumbFirst);

      this.patchResponse = res;
      this.getTeamList(0);

      localStorage.setItem('opportunity-info', JSON.stringify({
        opportunity_name: this.patchResponse.opportunity_name,
        opportunity_number: this.patchResponse.opportunity_number,
        opportunity_type: this.patchResponse.opportunity_type_details?.name,
        portfolio: this.patchResponse.portfolio_details[0].name,
        portfolio_id: this.patchResponse.portfolio_details[0].id,
        subportfolio: this.patchResponse.portfolio_details[1].name,
        subportfolio_id: this.patchResponse.portfolio_details[1].id,
        program: this.patchResponse.program?.name,
        program_id: this.patchResponse.program?.id,
        owner_name: this.patchResponse.owners[0].owner_details.full_name,
        single_point_of_contact: this.patchResponse.single_point_of_contact?.full_name,

      }));
      this.ps.setStatusValue(res?.status);
      this.valuePatch(this.patchResponse);

      if (this.patchResponse?.status?.slug != 'opportunity_draft') {
        this.stepper?.steps.forEach((step: any, index: number) => {
          this.stepStates[index] = 'done';
          step.completed = true;
        });
      }
      //   this.spinner.hide();
    }, error => {
      //   this.spinner.hide();
      throw error;
    })
  }
  getUser() {
    this.ps.getUser().subscribe((res: any) => {

      this.userList = JSON.parse(JSON.stringify(res.records));
      this.userList.map((i: any) => {
        i.full_name = `${i.first_name} ${i.last_name}`;
        i.full_name = i.full_name != ' ' ? i.full_name : i.username;
      });

    }, error => {
      this.spinner.hide();

      throw error;
    })
  }
  enableField(field_name: string) {
    this.setupForm.get(field_name).enable();
  }
  onSelectionChangePortfolio(event: any) {
    const portFolioId = event.source.value.id;
    this.subPortfilteredOptions = [];
    if (!portFolioId) {
      return;
    }
    this.setupForm.patchValue({
      subPortfolio: '',
      owners: ''
    })
    this.subPortFolioChild = [];
    Object.keys(this.setupForm.controls).forEach((control: any) => {
      if (control.includes('sub_portfolio_child')) {
        this.setupForm.removeControl(control);
      }
    })

    this.subPortOptions = event.source.value.sub_portfolio;
    this.subPortfilteredOptions = this.subPortOptions;
    this.filterOptionSubPortfolio = this.setupForm.get('subPortfolio')!.valueChanges
      .pipe(
        startWith(''),
        map((value: any) => typeof value === 'string' ? value : value?.name),
        map((name: any) => name ? this.subPortfolioFilter(name) : this.subPortfilteredOptions.slice())
      );
  }
  valuePatch(data: any) {
    // this.selectedItems=[];

    if (data?.custom_parameter?.length > 0) {
      data.custom_parameter.forEach((element: any) => {
        // if (this.customItems.length != 0) {
        //   this.customItems = this.customItems?.filter((e: any) => e?.id !== element?.custom_parameter);
        // }
        this.selectedItems.push(element);
        let display_name = element.custom_parameter_value;
        this.setupForm.addControl(display_name, new FormControl('', Validators.required));
        this.setupForm.controls[display_name].patchValue(element.custom_parameter_value);
      })
    }
    // if (data.opportunity_brief) {
    this.setupForm?.patchValue({
      name: data?.opportunity_name,
      opportunity: data?.opportunity_type_details,
      portfolio: data?.portfolio_details?.[0],
      subPortfolio: data?.portfolio_details?.[1],
      program: data?.program,

      owners: data?.owners?.[0]?.owner_details?.full_name,
      // spoc: data?.single_point_of_contact,
      opportunity_brief: data?.opportunity_brief,
      opportunity_line_of_business: data?.parameter_details?.line_of_business_details?.id,
      opportunity_department: data?.parameter_details?.department_details?.id,
      opportunity_cost_centre: data?.parameter_details?.cost_centre_details?.id,
      opportunity_business_unit: data?.parameter_details?.business_unit_details?.id
    })

    if (data?.parameter_details && data?.parameter_details?.line_of_business_details == null) {
      let itemVal = this.selectedItems.find((e: any) => e.field_name == 'opportunity_line_of_business')
      this.removeColumn(itemVal);
    }
    if (data?.parameter_details && data?.parameter_details?.department_details == null) {
      let itemVal = this.selectedItems.find((e: any) => e.field_name == 'opportunity_department')
      this.removeColumn(itemVal);
    }
    if (data?.parameter_details && data?.parameter_details?.business_unit_details == null) {
      let itemVal = this.selectedItems.find((e: any) => e.field_name == 'opportunity_business_unit')
      this.removeColumn(itemVal);
    }
    if (data?.parameter_details && data?.parameter_details?.cost_centre_details == null) {
      let itemVal = this.selectedItems.find((e: any) => e.field_name == 'opportunity_cost_centre')
      this.removeColumn(itemVal);
    }
    //}
    this.setupForm.updateValueAndValidity();
    let portdata = this.options1?.find((e: any) => e.id === data?.portfolio_details?.[0]?.id);
    this.subPortOptions = portdata?.sub_portfolio;
    this.subPortfilteredOptions = this.subPortOptions;
    this.filterOptionSubPortfolio = this.setupForm.get('subPortfolio')!.valueChanges
      .pipe(
        startWith(''),
        map((value: any) => typeof value === 'string' ? value : value?.name),
        map((name: any) => name ? this.subPortfolioFilter(name) : this.subPortfilteredOptions?.slice())
      );
    // this.ps.getSubPortfolio(data?.portfolio_details?.id).subscribe((res: any) => {
    //   this.subPortfilteredOptions = res;
    //   this.filterOptionSubPortfolio = this.setupForm.get('subPortfolio')!.valueChanges
    //     .pipe(
    //       startWith(''),
    //       map((value: any) => typeof value === 'string' ? value : value?.name),
    //       map((name: any) => name ? this.subPortfolioFilter(name) : this.subPortfilteredOptions.slice())
    //     );
    // }, error => {
    //   throw error;
    // })
    this.setupForm.get("name").disable();
    // this.setupForm.get("opportunity").disable();
    // this.setupForm.get("portfolio").disable();
    // this.setupForm.get("subPortfolio").disable();
    this.setupForm.get("owners").disable();
    //this.setupForm.get("spoc").disable();
    // Object.keys(this.setupForm.controls).forEach((control)=>{
    //   if(control.includes("sub_portfolio_child")){ 
    //     this.setupForm.get(control).disable();
    //   }
    // })

    const initialValue = this.setupForm.getRawValue();
    this.setupForm.valueChanges.subscribe((value: any) => {
      this.hasChange = Object.keys(initialValue).some(key => this.setupForm?.value[key] != initialValue[key])
    });

    if (data?.opportunity_timeline?.length > 0) {
      data?.opportunity_timeline.forEach((element: any) => {
        this.timelineList = this.timelineList?.filter((e: any) => e?.id !== element?.timeline_details?.id);
        this.customTimeline = this.customTimeline?.filter((e: any) => e?.id !== element?.timeline_details?.id);
        this.selectedTimelineItems = this.selectedTimelineItems.filter((e: any) => e?.id !== element?.timeline_details?.id);
        this.selectedTimelineItems.push(element?.timeline_details);
        let display_name = element.timeline_details.display_name;
        this.timeLineForm.addControl(display_name, new FormControl('', Validators.required));
        this.timeLineForm.controls[display_name].patchValue(new Date(element.timeline_date));
      })
    }
    if (data?.opportunity_key_results?.length > 0) {
      let keyArray: any[] = [];
      data.opportunity_key_results.forEach((element: any) => {
        keyArray.push(element.goal);
      });
      this.masterKeyResult = keyArray;
      this.masterKeyResultInitial = JSON.parse(JSON.stringify(keyArray));
      //this.getCustomkeyResults();
    }


    if (data) {
      this.profileForm.patchValue({
        category: data.category_details?.id,
        classification: data.classification_details?.id,
        triggers: data.trigger_details?.id,
        work: data.work_type_details?.id,
        fundingStatus: data.funding_status == true ? 0 : 1
      })
    }
    const profileFormValue = this.profileForm.value
    this.profileForm.valueChanges.subscribe((value: any) => {
      this.hasChange = Object.keys(profileFormValue).some(key => this.profileForm.value[key] != profileFormValue[key])
    });

    const timeLineFormValue = this.timeLineForm.value
    this.timeLineForm.valueChanges.subscribe((value: any) => {
      this.hasChange = true;//Object.keys(timeLineFormValue).some(key => this.timeLineForm.value[key] != timeLineFormValue[key])
    });
    if (data?.status?.slug == 'opportunity_evaluating' || data?.status?.slug == 'opportunity_sow_signed' || data?.status?.slug == 'opportunity_sow_signed' || data?.status?.slug == 'opportunity_cancelled') {
      this.disableFields = true;
      this.side = false;
      this.setupForm.disable();
      this.profileForm.disable();
      this.teamCreateForm.disable();
      this.gdpCreateForm.disable();
      this.timeLineCreateForm.disable();
      // this.teamForm.disable();
      this.keyForm.disable();
      // this.timeLineForm.disable();
    } else { this.disableFields = false; }
  }

  // keyPatch() {
  //   this.keyForm.patchValue({
  //     primaryOwner: this.username.username,
  //     secondaryOwner: this.username.username,
  //     primaryChildOwner: this.username.username,
  //     secondaryChildOwner: this.username.username
  //   })
  // }

  goalDesSelect(e: any) {
    if (e) {
      this.keyForm.patchValue({
        goalDescription: e.goalDescription
      })
    } else {
    }
  }

  keyDesSelect(e: any) {
    if (e) {
      this.keyForm.patchValue({
        keyDescription: e.keyDescription
      })
    } else {
    }
  }

  displayFn(data: any) {
    return data && data.goalTitle ? data.goalTitle : '';
  }
  displayFnName(data: any) {
    return data && data.name ? data?.name : data?.full_name;
  }
  onSelectionChangeSubportPortfolio(e: any) {
    const subportFolioId = e.source.value.id;
    //this.subPortfilteredOptions = [];
    if (!subportFolioId) {
      return;
    }
    let value = e.source.value;

    this.subPortFolioChild = [];
    if (JSON.stringify(value).includes("sub_portfolio_child")) {
      Object.keys(value).forEach((key: any) => {
        if (key.includes("sub_portfolio_child") && value[key] && value[key]?.length > 0) {
          this.setupForm.addControl(key, new FormControl('', [Validators.required, this._formValidator.autocompleteObjectValidator()]));
          let level = key?.split("_")?.pop();;
          this.subPortFolioChild.push({ "key": key, "name": "Sub-Portfolio Child " + level, arrList: value[key] })
        } else {
          this.setupForm.patchValue({
            owners: value.owner_details?.[0]?.full_name || value.owner_details?.[0]?.username
          })
          this.selectedOwnerDetails = value.owner_details?.[0];
          this.selectedOwnerDetails['mail'] = this.selectedOwnerDetails['email'];
        }
      });
    } else {
      this.setupForm.patchValue({
        owners: value.owner_details?.[0]?.full_name || value.owner_details?.[0]?.username
      })
      this.selectedOwnerDetails = value.owner_details?.[0];
      this.selectedOwnerDetails['mail'] = this.selectedOwnerDetails['email'];
    }
  }
  onSelectionChangeSubportPortfolioChild(e: any) {
    let value = e.source.value;
    if (JSON.stringify(value).includes("sub_portfolio_child")) {
      Object.keys(value).forEach((key: any) => {
        if (key.includes("sub_portfolio_child") && value[key] && value[key]?.length > 0) {
          let index = this.subPortFolioChild.findIndex((e: any) => e.key === key);
          if (index > -1)
            this.subPortFolioChild.splice(index, this.subPortFolioChild.length - index);
          this.setupForm.addControl(key, new FormControl('', [Validators.required, this._formValidator.autocompleteObjectValidator()]));
          let level = key?.split("_")?.pop();;
          this.subPortFolioChild.push({ "key": key, "name": "Sub-Portfolio Child " + level, arrList: value[key] })
        } else {
          this.setupForm.patchValue({
            owners: value.owner_details?.[0]?.full_name || value.owner_details?.[0]?.username
          })
          this.selectedOwnerDetails = value.owner_details?.[0];
          this.selectedOwnerDetails['mail'] = this.selectedOwnerDetails['email'];
        }
      });
    } else {
      this.setupForm.patchValue({
        owners: value.owner_details?.[0]?.full_name || value.owner_details?.[0]?.username
      })
      this.selectedOwnerDetails = value.owner_details?.[0];
      this.selectedOwnerDetails['mail'] = this.selectedOwnerDetails['email'];
    }
  }
  selectedStepper(e: StepperSelectionEvent) {
    this.stepper.steps.forEach((step: any, index: number) => {
      if (index == e.selectedIndex) {
        this.stepStates[index] = 'edit';
      } else if (!step.completed && index != e.selectedIndex) {
        this.stepStates[index] = 'number';
      } else if (step.completed && index != e.selectedIndex) {
        this.stepStates[index] = 'done';
      }
    });
    this.showNext = false;
    setTimeout(() => {
      this.showNext = true;
    }, 550)
    this.stepLabel = e.selectedStep.label;
    localStorage.setItem('stepLabel', this.stepLabel);
    if (this.patchResponse?.status?.slug != 'opportunity_draft') {
      switch (e.previouslySelectedStep?.label) {
        case 'Setup':
          // && this.hasChange
          if (!this.disableFields)
            this.updateSetup();
          this.hasChange = false;
          break;
        case 'Profile':
          // && this.hasChange
          if (!this.disableFields)
            this.updateProfile();
          this.hasChange = false;
          break;
        case 'Key Results':
          // const noChange = JSON.stringify(this.masterKeyResult) === JSON.stringify(this.masterKeyResultInitial);
          // && !noChange
          if (!this.disableFields)
            // this.saveSelection();         
            this.hasChange = false;
          break;

        case 'Team':
          // && this.hasChange
          if (!this.disableFields)
            this.updateTeam();
          this.hasChange = false;
          break;

        case 'OU/GDP':
          const noChange1 = JSON.stringify(this.selectedVendorItemsInitial) === JSON.stringify(this.selectedVendorItems);
          // && !noChange1
          if (!this.disableFields)
            this.updateVendors();
          this.hasChange = false;
          break;

        case 'Timeline':
          // && this.hasChange
          if (!this.disableFields)
            this.updateTimeline();
          this.hasChange = false;
          break;
      }
    }
    this.stepperValues(this.stepLabel);
  }

  stepperValues(stepLabel: any) {
    switch (stepLabel) {
      case 'Team':
        this.stepperTitles = { title: 'Opportunity Team', buttonName: 'Function' };
        this.isMemberSelected = false;
        this.getTeamList(0);
        const noChange = JSON.stringify(this.masterKeyResult) === JSON.stringify(this.masterKeyResultInitial);
        // && !noChange
        if (!this.disableFields && this.patchResponse?.status?.slug == 'opportunity_draft')
          // this.saveSelection();         
          this.hasChange = false;
        break;

      case 'OU/GDP':
        this.stepperTitles = { title: 'Opportunity OU/GDP', buttonName: 'Delivery Partner' };
        this.getVendorList(0)
        // && this.hasChange
        if (!this.disableFields && this.patchResponse?.status?.slug == 'opportunity_draft')
          this.updateTeam();
        this.hasChange = false;
        break;

      case 'Timeline':
        this.stepperTitles = { title: 'Opportunity Timeline', buttonName: 'Timeline' };
        this.getTimeline();
        const noChange1 = JSON.stringify(this.selectedVendorItemsInitial) === JSON.stringify(this.selectedVendorItems);
        // && !noChange1
        if (!this.disableFields && this.patchResponse?.status?.slug == 'opportunity_draft')
          this.updateVendors();
        this.hasChange = false;
        break;

      default:
        this.stepperTitles = { title: 'Opportunity Setup', buttonName: 'Parameter' };
    }
  }

  get f() {
    return this.setupForm.controls;
  }
  get t() {
    return this.f.setup_data as FormArray;
  }
  get g() {
    return this.keyForm.controls;
  }

  get h() {
    return this.teamForm.controls;
  }

  get p() {
    return this.profileForm.controls;
  }

  get time() {
    return this.timeLineForm.controls;
  }

  addColumn(item: any) {
    this.items = this.items.filter(e => e.id !== item?.id);
    this.updateItems = this.items
    this.selectedItems.push(item)
    if (this.setupForm.get(item?.field_name)) {
      this.setupForm.removeControl(item?.field_name);
      this.setupForm.updateValueAndValidity();
    }
    this.setupForm.addControl(item?.field_name, new FormControl('', [Validators.required]));
    this.setupForm.get(item?.field_name).setValidators([Validators.required]);
    this.setupForm.get(item?.field_name).updateValueAndValidity();
    const tempItems = JSON.parse(this.newItems);
    const newItems = tempItems.filter((e: any) => e.id !== item?.id);
    this.newItems = JSON.stringify(newItems);
    return this.items;
  }
  removeColumn(item: any) {
    console.log(this.setupForm);

    this.setupForm.get(item?.field_name)?.setValidators([]);
    this.setupForm.get(item?.field_name)?.setValue('');
    this.setupForm.get(item?.field_name)?.updateValueAndValidity();
    this.selectedItems = this.selectedItems.filter((e: any) => e.id !== item?.id)
    if (this.customNewItems.length > 0) {
      this.customNewItems = this.customNewItems.filter(e => e.id !== item?.id);
    }
    if (item?.custom || item?.custom_parameter) {
      this.customItems.push(item);
    } else {
      this.items.push(item);
    }
    this.newItems = JSON.stringify(this.items);
    this.newCustomItems = JSON.stringify(this.customItems);
  }
  addCustomColumn(item: any) {
    const newItems = this.customItems.find(e => e.id == item.id);
    this.customNewItems.push(newItems);
    this.customItems = this.customItems.filter(e => e.id !== item.id)
    this.cutomupdateItems = this.customItems
    this.selectedItems.push(item)
    this.setupForm.addControl(item.field_name, new FormControl('', Validators.required));
    const tempCustomItems = JSON.parse(this.newCustomItems);
    const newCustomItems = tempCustomItems.filter((e: any) => e.id !== item.id);
    this.newCustomItems = JSON.stringify(newCustomItems);
    return this.customItems;
  }
  removeCustom(item: any) {
    this.spinner.show();
    this.customItems = this.customItems.filter(e => e !== item);
    this.ps.deleteCustomParameter(item.id).subscribe((res: any) => {
      if (res) {
        this.spinner.hide();
        this.toastrService.success("Deleted Successfully");
      }
      return this.customItems;
    }, error => {
      this.toastrService.error('Could not able to delete');
      this.spinner.hide();
      throw error;
    })
  }

  addKeyColumn(item: any) {


    let slaveKeyResultdata = _.cloneDeep(this.slaveKeyResult);
    // below will find goal parent using goal id which will return respective goal parent
    let keyresultsdata = { ...slaveKeyResultdata.find((i: any) => i.goal_uuid == item.strategic_goal_uuid) };
    // below will find goals child data using key result unique id which will return respective goal parents child data
    let keyresultchilddata = keyresultsdata.key_results.filter((i: any) => {
      if (i.key_result_uuid == item.parent_key_result_uuid) {
        return i;
      }
    });
    // below will check whether goal unique id is equal to key result goal unique id if equal it will return true else false
    const isExist = this.masterKeyResult.some(e => e.goal_uuid == keyresultsdata.goal_uuid);
    // below will pass child data to parent
    keyresultsdata.key_results = keyresultchilddata;
    // below will find subchild data of parent using key result id which will return respective goal parent subchild
    let subchild = keyresultsdata.key_results[0].child_key_results.filter((el: any) => {
      if (el.key_result_uuid == item.key_result_uuid) {
        return el;
      }
    });


    // below will push to keyarr based on condition

    let subchildata = subchild[0];
    if (!isExist) {
      let keyclonedata = _.cloneDeep(keyresultsdata);
      this.masterKeyResult.push(keyclonedata);

      if (keyresultsdata.key_results != null) {
        this.masterKeyResult.filter((filterres: any) => {
          if (filterres.goal_uuid == subchildata.strategic_goal_uuid) {
            if (filterres.key_results.some((e: any) => e.key_result_uuid == subchildata.parent_key_result_uuid)) {
              let parentindex = filterres.key_results.findIndex((i: any) => i.key_result_uuid == subchildata.parent_key_result_uuid);
              filterres.key_results[parentindex].child_key_results = subchild;
              let sortby = this.lodashService.orderBy(filterres.key_results[parentindex].child_key_results, "key_result_title", "asc");
              filterres.key_results[parentindex].child_key_results = sortby
            }
          }
        })
      }
    } else {

      this.masterKeyResult.filter((filterres: any) => {
        if (filterres.goal_uuid == subchildata?.strategic_goal_uuid) {
          if (filterres.key_results.some((e: any) => e.key_result_uuid == subchildata?.parent_key_result_uuid)) {
            let parentindex = filterres.key_results.findIndex((i: any) => i.key_result_uuid == subchildata?.parent_key_result_uuid);
            filterres.key_results[parentindex].child_key_results.push(subchild[0]);
            let sortby = this.lodashService.orderBy(filterres.key_results[parentindex].child_key_results, "key_result_title", "asc");
            filterres.key_results[parentindex].child_key_results = sortby
          } else {
            filterres.key_results.push(keyresultchilddata[0]);
            let parentindex = filterres.key_results.findIndex((i: any) => i.key_result_uuid == subchildata?.parent_key_result_uuid);
            filterres.key_results[parentindex].child_key_results = subchild;
            let sortby = this.lodashService.orderBy(filterres.key_results[parentindex].child_key_results, "key_result_title", "asc");
            filterres.key_results[parentindex].child_key_results = sortby
          }
        }
      })
    }
    for (let item in this.masterKeyResult) {
      this.masterKeyResult[item]['key_results'] = this.sortKeyResults(this.masterKeyResult[item]['key_results'], 'key_title')
      for (let child in this.masterKeyResult[item]['key_results']) {
        this.masterKeyResult[item]['key_results'][child]['child_key_results'] = this.sortKeyResults(this.masterKeyResult[item]['key_results'][child]['child_key_results'], 'key_title')
      }
    }
  }

  sortKeyResults(arr: any = [], key: any) {
    arr.sort((a: any, b: any) => {
      if (a[key] === b[key]) {
        return 0;
      } else if (a[key] === null) {
        return 1;
      } else if (b[key] === null) {
        return -1;
      } else if (a[key] < b[key]) {
        return -1;
      } else {
        return 1;
      }
    });
    return arr;
  }

  // below will remove child key / parent key / goal form Master key Result which is center pane  

  removeKeyResult(item: any) {
    this.masterKeyResult.filter((goal: any, goalindex: number) => {
      if (goal.goal_uuid == item.strategic_goal_uuid) {
        if (goal.key_results.length != 0) {
          goal.key_results.forEach((keyResultParent: any, keyResultParentindex: number) => {
            let childKeyResultIndex = keyResultParent.child_key_results.findIndex((child: any) => child.key_result_uuid == item.key_result_uuid)
            if (childKeyResultIndex !== -1) {
              keyResultParent.child_key_results.splice(childKeyResultIndex, 1);
            }
            if (keyResultParent.child_key_results.length == 0) {
              goal.key_results.splice(keyResultParentindex, 1);
            }
            if (goal.key_results.length == 0) {
              this.masterKeyResult.splice(goalindex, 1);
            }
          })
        } else {
          this.masterKeyResult.splice(goalindex, 1);
        }
      }
    })
    if (!item.goal_is_custom) {
      this.dataService.removeKeyMeasurmentMethod(item);
    } else {
      this.dataService.removeCustomKeyMeasurmentMethod(item);
    }
  }

  // addFunctionColumn(item: any) {
  //   this.functionList = this.functionList?.filter((e: any) => e.id !== item.id);
  //   this.updateItems = this.functionList
  //   this.selectedFunctionItems.push(item)
  //   this.teamForm.addControl(item.display_name, new FormControl([], Validators.required));
  //   const tempItems = JSON.parse(this.newFunctionItems);
  //   const newItems = tempItems.filter((e: any) => e.id !== item.id);
  //   this.newFunctionItems = JSON.stringify(newItems);
  //   return this.functionList;
  // }
  // removeFunction(item: any) {
  //   this.spinner.show();
  //   this.ps.deleteFunction(item.id).subscribe((res: any) => {
  //     this.spinner.hide();
  //     this.dialog.closeAll();
  //     this.getTeamList(1);
  //   }, error => {
  //     this.spinner.hide();
  //     this.toastrService.error('Could not able to delete');
  //     throw error;
  //   })
  // }

  // removeFunctionColumn(item: any) {
  //   this.spinner.show();
  //   this.isMemberSelected = false;
  //   this.selectedFunctionItems = this.selectedFunctionItems.filter((e: any) => e.id != item.id)
  //   this.customFunctionList = this.customFunctionList?.filter((e: any) => e.id !== item.id);
  //   if (item.is_custom) {
  //     this.customFunctionList.push(item);
  //   } else {
  //     this.functionList?.push(item)
  //   }
  //   let seletedChips = this.teamForm.controls[item.display_name].value;
  //   if (seletedChips.length > 0) {
  //     seletedChips.forEach((c: any) => {
  //       this.spinner.hide();
  //       this.removeChips(c, item.display_name);
  //     });
  //   }

  //   this.teamForm.removeControl(item.display_name);
  //   this.newFunctionItems = JSON.stringify(this.selectedFunctionItems);
  //   this.newCustomFunctionItems = JSON.stringify(this.customFunctionList);
  //   this.spinner.hide();
  //   return this.customFunctionList;
  // }

  // addCustomFunctionColumn(item: any) {
  //   const newItems = this.customFunctionList.find((e: any) => e.id == item.id);
  //   this.selectedFunctionItems.push(newItems);
  //   this.customFunctionList = this.customFunctionList?.filter((e: any) => e.id !== item.id)
  //   this.cutomupdateItems = this.customFunctionList;
  //   this.teamForm.addControl(item.display_name, new FormControl([], Validators.required));
  //   const tempCustomItems = JSON.parse(this.newCustomFunctionItems);
  //   const newCustomItems = tempCustomItems.filter((e: any) => e.id !== item.id);
  //   this.newCustomFunctionItems = JSON.stringify(newCustomItems);
  //   return this.customFunctionList;
  // }
  // removeCustomFunction(item: any) {
  //   this.customFunctionList = this.customFunctionList?.filter((e: any) => e !== item);
  //   this.ps.deleteCustomParameter(item.id).subscribe((res: any) => {
  //   }, error => {
  //     this.toastrService.error('Could not able to delete');
  //     throw error;
  //   })
  // }

  addVendorColumn(item: any) {
    this.vendorList = this.vendorList.filter((e: any) => e.id !== item.id);
    this.updateItems = this.vendorList;
    this.selectedVendorItems.push(item);

    this.selectedVendorItems = [...this.selectedVendorItems]
    const tempItems = JSON.parse(this.newVendorItems);
    const newItems = tempItems.filter((e: any) => e.id !== item.id);
    this.newVendorItems = JSON.stringify(newItems);
    return this.vendorList;
  }

  removeVendorColumn(item: any) {
    this.selectedVendorItems = this.selectedVendorItems.filter((e: any) => e.id !== item.id)
    this.vendorList.push(item);
    this.vendorList.reverse();
    this.vendorList = _.sortBy(this.vendorList, 'name');
    this.newVendorItems = JSON.stringify(this.vendorList);
  }

  addTimelineColumn(item: any) {
    this.timelineList = this.timelineList.filter((e: any) => e.id !== item.id);
    this.updateItems = this.timelineList;
    this.selectedTimelineItems.push(item);
    this.timeLineForm.addControl(item.display_name, new FormControl('', Validators.required));
    const tempItems = JSON.parse(this.newTimelineItems);
    const newItems = tempItems.filter((e: any) => e.id !== item.id);
    this.newTimelineItems = JSON.stringify(newItems);
    return this.timelineList;
  }

  removeTimelineColumn(item: any) {
    this.timeLineForm.removeControl(item.display_name);
    this.selectedTimelineItems = this.selectedTimelineItems.filter((e: any) => e.id !== item.id)
    if (item.is_custom) {
      this.customTimeline.push(item);
    } else {
      this.timelineList.push(item);
    }
    this.newTimelineItems = JSON.stringify(this.items);
    this.newCustomTimeItems = JSON.stringify(this.customTimeline);
  }

  addCustomTimelineColumn(item: any) {
    const newItems = this.customTimeline?.find((e: any) => e.id == item.id);
    this.selectedTimelineItems.push(newItems);
    this.customTimeline = this.customTimeline?.filter((e: any) => e.id !== item.id)
    this.cutomupdateItems = this.customTimeline;
    this.timeLineForm.addControl(item.display_name, new FormControl('', Validators.required));
    const tempCustomItems = JSON.parse(this.newCustomTimeItems);
    const newCustomItems = tempCustomItems.filter((e: any) => e.id !== item.id);
    this.newCustomTimeItems = JSON.stringify(newCustomItems);
    return this.customTimeline;
  }

  deleteCustomTimeline(item: any) {
    this.spinner.show();
    this.ps.deleteCustomTimeline(item.id).subscribe((res: any) => {
      this.dialog.closeAll();
      this.spinner.hide();
      this.toastrService.success('Deleted Successfully');
      this.getTimeline(1);
    }, error => {
      this.spinner.hide();
      throw error;
    })
  }

  getProfileData() {
    this.ps.getOpportunityFormData('opportunity_profile').subscribe((res: any) => {
      this.profileList = JSON.parse(JSON.stringify(res.records));
      this.profileList.map((i: any) => {
        if (i.field_name == "opportunity_category") {
          this.data.category = i.field_status;
          this.categoryList = i.field_values;
          this.data.disCategory = i.display_name;
          if (!i.field_status) {
            this.profileForm.removeControl('name');
          }
        }
        if (i.field_name == "opportunity_classification") {
          this.data.classification = i.field_status;
          this.clasificationList = i.field_values;
          this.data.disClassification = i.display_name
          if (!i.field_status) {
            this.profileForm.removeControl('classification');
          }
        }
        if (i.field_name == "opportunity_trigger") {
          this.data.triggers = i.field_status;
          this.triggerList = i.field_values;
          this.data.disTriggers = i.display_name
          if (!i.field_status) {
            this.profileForm.removeControl('triggers');
          }
        }
        if (i.field_name == "opportunity_work_type") {
          this.data.work = i.field_status;
          this.workList = i.field_values;
          this.data.disWork = i.display_name
          if (!i.field_status) {
            this.profileForm.removeControl('work');
          }
        }
        if (i.field_name == "funding_status") {
          this.data.fundingStatus = i.field_status;
          this.statusList = i.field_type;
          this.data.disfundingStatus = i.display_name
          if (!i.field_status) {
            this.profileForm.removeControl('fundingStatus');
          }
        }
      })
    }, error => {
      this.spinner.hide();
      throw error;
    })
  }

  // onInputKeyChange(e: any) {
  //   this.searchKeyText = e.target.value;
  //   if (this.newGoalItems) {
  //     var goalItems = JSON.parse(this.newGoalItems);
  //   }
  //   if (!!this.searchKeyText) {
  //     this.search = true;
  //     this.searchKeyText = this.searchKeyText.toLowerCase();
  //     setTimeout(() => {
  //       // if (goalItems) {
  //       // this.slaveKeyResult = goalItems.filter((e: any) => e.goal_title.toLowerCase().includes(this.searchKeyText));
  //       // }
  //       if (goalItems) {
  //         this.slaveKeyResult = goalItems.filter((e: any) => e.goal_title.toLowerCase().includes(this.searchKeyText) | e.goal_description.toLowerCase().includes(this.searchKeyText));
  //         goalItems.forEach((goal: any) => {
  //           let key_results = goal.key_results
  //           if (key_results) {
  //             let new_key_result = key_results.filter((element: any) => element.key_result_title.toLowerCase().includes(this.searchKeyText) | element.key_result_description.toLowerCase().includes(this.searchKeyText));
  //             if (new_key_result.length > 0) {
  //               goal.key_results = new_key_result

  //               this.slaveKeyResult.push(goal)
  //               this.slaveKeyResult = _.uniqWith([...this.slaveKeyResult], _.isEqual);
  //             }
  //           }
  //         });
  //       }
  //     }, 200);
  //   } else {
  //     this.search = false;
  //     this.slaveKeyResult = goalItems;
  //   }
  // }

  updateSetup() {
    let prefix = 'opportunity_'
    let form_data = this.setupForm.getRawValue();
    let payload: any = {
      setup_data: this.setup_data,
    }
    payload.setup_data.opportunity_name = this.setupForm.value.name;
    if (this.setupForm.value.opportunity) {
      payload.setup_data.opportunity_type = this.setupForm.value.opportunity.id
    }

    if (this.setupForm.value.portfolio) {
      let childID = this.subPortFolioChild[this.subPortFolioChild?.length - 1]?.id;
      payload.portfolio = childID >= 0 ? childID : this.setupForm.value.subPortfolio.id;
    }
    // if (this.setupForm.value.portfolio) {
    //   payload.setup_data.portfolio = this.setupForm.value.portfolio.id
    // }
    // if (this.setupForm.value.subPortfolio) {
    //   payload.setup_data.sub_portfolio = this.setupForm.value.subPortfolio.id
    // }
    // if (this.setupForm.value.owners) {
    //   payload.setup_data.owners = [this.setupForm.value.owners]
    // }
    // if (this.setupForm.value.spoc) {
    //   payload.setup_data.spoc = {'mail':this.setupForm.value.spoc?.mail}
    // } 
    for (let item in form_data) {
      if (item != "opportunity_brief") {
        if (item.startsWith(prefix)) {
          let key = item.slice(prefix.length);
          this.setup_data[key] = form_data[item]
        }
      }
      else {
        this.setup_data[item] = form_data[item]
      }

      let updateCustom = this.selectedItems.filter((i: any) => i.custom_parameter || i.custom == true);
      const custom_parameters = updateCustom.map(item => {
        let customValues: any;
        customValues = {
          custom_parameter: item.id,
          custom_parameter_value: this.setupForm.value[item.field_name] || item.custom_parameter_value
        };
        if (item?.custom_parameter_details) {
          customValues.custom_parameter = item?.custom_parameter_details.id;
        }
        return customValues;
      })
      payload.setup_data.custom_parameter = custom_parameters;
      if (!payload.setup_data.hasOwnProperty('business_unit')) {
        payload.setup_data.business_unit = '';
      }
      if (!payload.setup_data.hasOwnProperty('department')) {
        payload.setup_data.department = '';
      }
      if (!payload.setup_data.hasOwnProperty('line_of_business')) {
        payload.setup_data.line_of_business = '';
      }
      if (!payload.setup_data.hasOwnProperty('cost_centre')) {
        payload.setup_data.cost_centre = '';
      }
      if (!payload.setup_data.hasOwnProperty('cost_centre')) {
        payload.setup_data.cost_centre = '';
      }
    }
    this.ps.updateOpportunity(this.opportunityId, payload).subscribe((res: any) => {
      this.spinner.hide();
      this.getSetupParameters();
    }, error => {
      this.spinner.hide();
      throw error;
    })
    this.searchText = '';
  }

  getSetupParameters() {
    // //  this.spinner.show();
    //   this.ps.getOpportunityFormData('opportunity_setup').subscribe((res: any) => {
    //   //  this.spinner.hide();
    //   }, error => {
    //   //  this.spinner.hide();
    //     throw error;
    //   })
  }

  updateProfile() {
    var profilePayload = {
      profile_data: {
        category: this.profileForm.value.category,
        classification: this.profileForm.value.classification,
        trigger: this.profileForm.value.triggers,
        work_type: this.profileForm.value.work,
        funding_status: this.profileForm.value.fundingStatus == '0' ? true : false
      }
    };
    this.ps.updateOpportunity(this.opportunityId, profilePayload).subscribe((res: any) => {
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      throw error;
    })
    this.searchText = '';
  }

  goToAdd() {
    this.customParameter = true;
    this.side = false;
    this.sideHelp = false
    this.side1 = true;
  }

  goToFunction() {
    this.teamFunction = true;
    this.customKeypanel = false;
    this.customParameter = false;
    this.side = false;
    this.sideHelp = false
    this.side1 = true;
    this.newTimeline = false;
  }

  goAddKey() {
    // this.keyPatch();
    this.getUser();
    this.customKeypanel = true;
    this.customParameter = false;
    this.side = false;
    this.sideHelp = false
    this.side1 = true;
    this.teamFunction = false;
  }

  goToTimeline() {
    this.newTimeline = true;
    this.side = false;
    this.sideHelp = false
    this.side1 = true;
    this.teamFunction = false;
    this.customKeypanel = false;
    this.customParameter = false;
  }

  customPar(parameterName: any) {
    if (!!parameterName) {
      var payload1 = {
        name: parameterName,
        display_name: parameterName
      }
      this.ps.createCustomParameter(payload1).subscribe((res: any) => {
        if (res == false) {
          this.toastrService.error('UH OH! This parameter already exists, Please create a parameter that didn’t exist in the system');
          return;
        } else {
          this.getList(1);
          this.toastrService.success('Custom parameter added successfully');
          // this.side = true;
        }
        return this.parameterName = '';
      }, error => {
        this.toastrService.error('Could not create a custom field');
        throw error;
      });
    }
    else {
      this.toastrService.error('Please enter a custom parameter');
      return this.parameterName;
    }
  }


  async getList(type?: any) {
    //this.spinner.show();

    combineLatest([

      // this.ps.getOpportunityType().pipe(tap((res:any) => {
      //   this.options2 = res.opportunity_type;
      //   this.filteredOptions2 = this.options2;      
      // })),

      // this.ps.getOpportunityHierarchy().pipe(tap((res:any) => {
      //   this.options1 = res.records;
      //   this.filteredOptions1 = this.options1;

      // })),
      // this.ps.getOpportunitySPOC().pipe(tap((res:any) => {
      //   this.spocOptions = res.risk_owners;
      //     this.spocFilteredOptions = this.spocOptions;


      // })),
      // this.ps.getProgramData().pipe(tap((res:any) => {
      //   this.programOptions = res;
      //     this.programFilteredOptions = this.programOptions;


      // })
      // )

    ]).subscribe((resultArray) => {
      console.log(resultArray)
      this.initAutoComplete()

    })



    return new Promise((resolve, reject) => {
      this.ps.getOpportunityFormDataWithoutChache('opportunity_setup').subscribe((res: any) => {
        //  this.spinner.hide();
        let records = JSON.parse(JSON.stringify(res.records));
        var customRecords = records.filter((x: any) => x.field_name == 'custom_parameter' && x.field_status == true);
        var checkCustom = records.filter((x: any) => x.field_name == 'custom_parameter' && x.field_status == false);
        this.showAddField = checkCustom.length ? false : true;
        this.customItems = [];
        this.customRecords = customRecords[0].field_values;
        this.customRecords.forEach(item => {
          var payload = {
            display_name: item.display_name,
            field_name: item.name,
            id: item.id,
            delete: item.delete,
            field_type: 'input',
            custom: true
          }
          this.customItems.push(payload);
          this.customItems = _.sortBy(this.customItems, 'id');
          if (type) {
            this.customItems.forEach(i => {
              this.selectedItems.forEach((e: any) => {
                if (i.id == e.id || i.id == e.custom_parameter) {
                  const index = this.customItems.findIndex(k => k.id == e.id || k.id == e.custom_parameter);
                  this.customItems.splice(index, 1);
                }
              })
              this.customItems = _.sortBy(this.customItems, 'id');
            })
          }
          this.customItems = this.customItems.reverse();
        });
        this.newCustomItems = JSON.stringify(this.customItems);

        if (!type && !this.initalLoad) {
          this.side = false;
          this.submitted = false;
          //this.spinner.show();
          this.initalLoad = true;
          this.parameterList = JSON.parse(JSON.stringify(res.records));
          this.filterSearchItems = this.parameterList.filter((i: any) => i.field_status == false);

          this.items = this.parameterList.filter(e => e.field_status == true);

          this.items.map(i => {
            if (i.field_name == 'opportunity_description') {
              this.items = this.items.filter(e => e !== i)
              this.disDescription = i.display_name;
              this.setupForm.setControl('opportunity_brief', new FormControl('', Validators.required));
            }
            if (i.field_name == 'opportunity_department') {
              this.selectedItems.push(i)
              this.setupForm.addControl(i.field_name, new FormControl('', Validators.required));
              this.items = this.items.filter(e => e !== i)
              this.updateItems = this.items
            }

            if (i.field_name == 'opportunity_business_unit') {
              this.selectedItems.push(i)
              this.setupForm.addControl(i.field_name, new FormControl('', Validators.required));
              this.items = this.items.filter(e => e !== i);
              this.updateItems = this.items
            }
            if (i.field_name == 'opportunity_line_of_business') {
              this.selectedItems.push(i)
              this.setupForm.addControl(i.field_name, new FormControl('', Validators.required));
              this.items = this.items.filter(e => e !== i)
              this.updateItems = this.items
            }
            if (i.field_name == 'opportunity_cost_centre') {
              this.selectedItems.push(i)
              this.setupForm.addControl(i.field_name, new FormControl('', Validators.required));
              this.items = this.items.filter(e => e !== i)
              this.updateItems = this.items
            }
            if (i.field_name == 'opportunity_category') {
              this.selectedItems.push(i)
              this.setupForm.addControl(i.field_name, new FormControl('', Validators.required));
              this.items = this.items.filter(e => e !== i)
              this.updateItems = this.items
            }
            if (i.field_name == 'opportunity_classification') {
              this.selectedItems.push(i)
              this.setupForm.addControl(i.field_name, new FormControl('', Validators.required));
              this.items = this.items.filter(e => e !== i)
              this.updateItems = this.items
            }
            if (i.field_name == 'opportunity_trigger') {
              this.selectedItems.push(i)
              this.setupForm.addControl(i.field_name, new FormControl('', Validators.required));
              this.items = this.items.filter(e => e !== i)
              this.updateItems = this.items
            }
            if (i.field_name == 'opportunity_work_type') {
              this.selectedItems.push(i)
              this.setupForm.addControl(i.field_name, new FormControl('', Validators.required));
              this.items = this.items.filter(e => e !== i)
              this.updateItems = this.items
            }
            if (i.field_name == 'funding_status') {
              this.selectedItems.push(i)
              this.setupForm.addControl(i.field_name, new FormControl('', Validators.required));
              this.items = this.items.filter(e => e !== i)
              this.updateItems = this.items
            }
            if (i.field_name == 'custom_parameter') {
              this.items = this.items.filter(e => e !== i);
              this.updateItems = this.items
            }
            this.newItems = JSON.stringify(this.items);
          });
          this.getOpportunityById();
        }
        resolve(true);
      }, error => {
        this.spinner.hide();
        reject();
        this.toastrService.error('Could not get custom field');
      });
    });


  }

  openParameter() {
    this.side = false;
    this.isMemberSelected = false;
    if (this.initalLoad) {
      this.submitted = false;
    }
    if (!this.disableFields)
      this.side = true;
  }

  addPopup() {
    this.dialog.open(CustomPropertiesComponent, {
      data: {
        customItems: this.customItems,
        items: this.items,
        functionList: this.functionList,
        customFunctionList: this.customFunctionList,
        showAddField: this.showAddField,
        disableFields: this.disableFields,
        context: this.context,
        buttonName: this.stepperTitles.buttonName,
        isMemberSelected: false,
        vendorList: this.vendorList,
        timelineList: this.timelineList,
        customTimeline: this.customTimeline
      },
      height: '400px',
      width: '600px',
      position: { top: '10px' }
    })
  }

  // getOkr(){
  //   this.spinner.show();
  //   this.ps.getOpportunityKrData(this.opportunityId).subscribe((getOkr:any) => {
  //     getOkr.okr_hierarchy.forEach((objective: any) => {
  //       objective.level = "objective";
  //       objective.name = objective.title;
  //       if(objective.key_results.length > 0){
  //         objective.children = objective.key_results;
  //         objective.children.forEach((kr: any) => {
  //           kr.level = "kr";
  //           kr.name = kr.title;
  //           kr.objective_id = objective.id;
  //           if(kr.goals.length > 0){
  //             kr.children = kr.goals;
  //             kr.children.forEach((goals: any) => {
  //               goals.level = "goal";
  //               goals.name = goals.goal_name;
  //               goals.kr_id = kr.id;
  //               goals.objective_id = objective.id;

  //               if(getOkr.selected_goal_ids.findIndex((e: any) => e.goal_id == goals.id) > -1){
  //                 goals.selected = true;
  //               }else{
  //                 goals.selected = false;
  //               }
  //             })

  //             if(kr.children.filter((e: any) => e.selected === true).length == kr.children.length){
  //               kr.selected = true;
  //               kr.selection_type = 'all';
  //             }else if(kr.children.filter((e: any) => e.selected === true).length > 0){
  //               kr.selected = true;
  //               kr.selection_type = 'intermediate';
  //             }else{
  //               kr.selected = false;
  //               kr.selection_type = 'none';
  //             }
  //           }
  //         });

  //         if(objective.children.filter((e: any) => e.selection_type === 'all').length == objective.children.length){
  //           objective.selected = true;
  //           objective.selection_type = 'all';
  //         }else if(objective.children.filter((e: any) => e.selection_type === 'intermediate').length > 0 || objective.children.filter((e: any) => e.selection_type === 'all').length > 0){
  //           objective.selected = true;
  //           objective.selection_type = 'intermediate';
  //         }else{
  //           objective.selected = false;
  //           objective.selection_type = 'none';
  //         }
  //       }
  //     });
  //     this.okrArray = getOkr.okr_hierarchy;
  //     this.selectedOkr = getOkr.selected_goal_ids;
  //     this.spinner.hide();      
  //   })
  // }

  collapse(item: any) {
    if (item.level == 'objective') {
      this.okrArray.forEach((element: any) => {
        if (element.id != item.id) element.expanded = false;
      });
    } else if (item.level == 'kr') {
      let krIndex = this.okrArray.findIndex((e: any) => e.id == item.objective_id);

      if (this.okrArray[krIndex].children.length > 0) {
        this.okrArray[krIndex].children.forEach((element: any) => {
          if (element.id != item.id) element.expanded = false;
        });
      }
    }
  }

  selectGoals(item: any) {
    if (item.level == 'objective') {
      this.okrArray.forEach((obj: any) => {
        if (obj.id == item.id) {
          obj.selected = item.selected;
          obj.selection_type = item.selected ? 'all' : 'none';

          obj.children.forEach((kr: any) => {
            kr.selected = item.selected;
            kr.selection_type = item.selected ? 'all' : 'none';

            kr.children.forEach((goal: any) => {
              goal.selected = item.selected;
              goal.selection_type = item.selected ? 'all' : 'none';
            });
          });
        }
      });
    } else if (item.level == 'kr') {
      let krIndex = this.okrArray.findIndex((e: any) => e.id == item.objective_id);

      if (this.okrArray[krIndex].children.length > 0) {
        this.okrArray[krIndex].children.forEach((kr: any) => {
          if (kr.id == item.id) {
            kr.selected = item.selected;
            kr.selection_type = item.selected ? 'all' : 'none';

            kr.children.forEach((goal: any) => {
              goal.selected = item.selected;
              goal.selection_type = item.selected ? 'all' : 'none';
            });
          }
        });

        if (this.okrArray[krIndex].children.filter((e: any) => e.selection_type === 'all').length == this.okrArray[krIndex].children.length) {
          this.okrArray[krIndex].selected = true;
          this.okrArray[krIndex].selection_type = 'all';
        } else if (this.okrArray[krIndex].children.filter((e: any) => e.selection_type === 'intermediate').length > 0 || this.okrArray[krIndex].children.filter((e: any) => e.selection_type === 'all').length > 0) {
          this.okrArray[krIndex].selected = true;
          this.okrArray[krIndex].selection_type = 'intermediate';
        } else {
          this.okrArray[krIndex].selected = false;
          this.okrArray[krIndex].selection_type = 'none';
        }
      }
    } else if (item.level == 'goal') {
      let krIndex = this.okrArray.findIndex((e: any) => e.id == item.objective_id);
      let goalIndex = this.okrArray[krIndex].children.findIndex((e: any) => e.id == item.kr_id);

      if (this.okrArray[krIndex].children[goalIndex].children.length > 0) {
        this.okrArray[krIndex].children[goalIndex].children.forEach((goal: any) => {
          if (goal.id == item.id) {
            goal.selected = item.selected;
            goal.selection_type = item.selected ? 'all' : 'none';
          }
        });


        if (this.okrArray[krIndex].children[goalIndex].children.filter((e: any) => e.selected === true).length == this.okrArray[krIndex].children[goalIndex].children.length) {
          this.okrArray[krIndex].children[goalIndex].selected = true;
          this.okrArray[krIndex].children[goalIndex].selection_type = 'all';
        } else if (this.okrArray[krIndex].children[goalIndex].children.filter((e: any) => e.selected === true).length > 0) {
          this.okrArray[krIndex].children[goalIndex].selected = true;
          this.okrArray[krIndex].children[goalIndex].selection_type = 'intermediate';
        } else {
          this.okrArray[krIndex].children[goalIndex].selected = false;
          this.okrArray[krIndex].children[goalIndex].selection_type = 'none';
        }

        if (this.okrArray[krIndex].children.filter((e: any) => e.selection_type === 'all').length == this.okrArray[krIndex].children.length) {
          this.okrArray[krIndex].selected = true;
          this.okrArray[krIndex].selection_type = 'all';
        } else if (this.okrArray[krIndex].children.filter((e: any) => e.selection_type === 'intermediate').length > 0 || this.okrArray[krIndex].children.filter((e: any) => e.selection_type === 'all').length > 0) {
          this.okrArray[krIndex].selected = true;
          this.okrArray[krIndex].selection_type = 'intermediate';
        } else {
          this.okrArray[krIndex].selected = false;
          this.okrArray[krIndex].selection_type = 'none';
        }
      }
    }
  }

  // saveSelection(){
  //   let goalArray: any = [];

  //   this.okrArray.forEach((objective: any) => {
  //     objective?.children?.forEach((kr: any) => {
  //       kr?.children?.forEach((goal: any) => {
  //         if(goal.selected){
  //           goalArray.push(goal.id)
  //         }
  //       });

  //     });
  //   });

  //   let postdata = {
  //     opportunity_id: this.opportunityId,
  //     goal_ids: goalArray
  //   }

  //   this.ps.selectOpportunityGoals(postdata).subscribe((getOkr:any) => {

  //   })
  // }

  pushLastValue(arr: any, obj: any) {
    const index = arr.findIndex((e: any) => e.goal_uuid === obj.goal_uuid);
    if (index === -1) {
      arr.push(obj);
    } else {
      arr[index] = obj;
    }
    return arr
  }

  clearFunctionField() {
    this.functionName = '';
  }

  add(event: MatChipInputEvent, i: any): void {
    const value = (event.value || '').trim();
    if (value) {
      this.chipsList.push(value);
    }
    event.chipInput!.clear();
  }

  remove(nameList: string): void {
    const index = this.chipsList.indexOf(nameList);

    if (index >= 0) {
      this.chipsList.splice(index, 1);
    }
  }

  getTeamList(type?: any) {
    this.ps.getOpportunityFormData('opportunity_teams').subscribe((res: any) => {
      var wholeTeamList = JSON.parse(JSON.stringify(res.records));
      if (wholeTeamList) {
        const functionList = wholeTeamList.find((e: any) => e.field_name == "opportunity_function");
        this.functionList = functionList.field_values;

        const teamList = wholeTeamList.find((i: any) => i.field_name == "opportunity_team");
        teamList.field_values = teamList.field_values.filter((item: any) => !this.teamArrayToFilter.includes(item.email));
        this.teamList = teamList.field_values;

        this.filteredTeamList = this.teamList;
      }
      if (type == 0) {
        this.teamCreateForm.clear();
        console.log(this.patchResponse.opportunity_teams);
        if (this.patchResponse.opportunity_teams?.length >= 0) {
          this.patchResponse.opportunity_teams.forEach((element: any) => {
            this.teamCreateForm.push(
              this.formBuilder.control({
                function: new FormControl(element.function.id, [Validators.required]),
                team_members: this.formBuilder.array(element.function.team_members),
                input: new FormControl('')
              })
            )
          })
        }
        this.teamCreateForm.valueChanges.subscribe((value: any) => {
          this.hasChange = true;
        });
      }
    })
  }

  searchTeam(index: any) {
    return this.filteredTeamListFilter(index)
  }

  filteredTeamListFilter(index: any) {
    if (this.filteredTeamList) {
      let filteringArray = this.teamCreateForm.controls[index].value.team_members.value ? this.teamCreateForm.controls[index].value.team_members.value : [];
      var filteredArray = filteringArray.length > 0 ? this.filteredTeamList.filter(function (array_el: any) {
        return filteringArray.filter(function (innerel: any) {
          return innerel.mail == array_el.mail || innerel.email == array_el.mail;
        }).length == 0
      }) : this.filteredTeamList;

      filteredArray = typeof this.teamCreateForm.controls[index].value.input.value === 'string' ? filteredArray.filter((f: any) => f.name.toLowerCase().indexOf(this.teamCreateForm.controls[index].value.input.value.toLowerCase()) === 0) : filteredArray;
      return filteredArray;
    } else {
      return [];
    }
  }

  removeFunction(index: any) {
    this.teamCreateForm.removeAt(index);
  }

  removeTeam(team: string, indx: any): void {
    const index = this.teamCreateForm.controls[indx].value.team_members.value.indexOf(team);

    if (index >= 0) {
      this.teamCreateForm.controls[indx].value.team_members.value.splice(index, 1);
    }
  }

  selectedTeam(event: MatAutocompleteSelectedEvent, index: any): void {
    this.teamCreateForm.controls[index].value.team_members.value.push(event.option.value);
    this.teamCreateForm.controls[index].value.input.setValue(null);
  }

  getFunctionName(functionId: any) {
    if (functionId) {
      return this.functionList.find((item: any) => item.id === functionId).display_name;
    }
  }

  searchFun(index: any) {
    return this.getFunctionList(index)
  }

  addNewFunction(index: any, functionName: any) {
    if (!!functionName) {
      this.spinner.show();
      var payload = {
        name: functionName,
        display_name: functionName,
        type: "opportunity_function"
      }
      this.ps.createFunction(payload).subscribe((res: any) => {
        this.spinner.hide();
        if (res == false) {
          this.spinner.hide();
          this.toastrService.error('UH OH! This function already exists, Please create a function that didn’t exist in the system');
          return;
        } else {
          this.functionList.push(res);
          this.toastrService.success('Function added successfully');
          this.spinner.hide();
        }
      }, error => {
        this.spinner.hide();
        this.toastrService.error('Could not create a custom function');
        throw error;
      });
    }
    else {
      this.toastrService.error('Please enter a function');
    }
  }

  getFunctionList(index: any) {
    if (this.functionList) {
      let filteringArray = this.teamCreateForm.value ? this.teamCreateForm.value : [];
      var filteredArray = filteringArray.length > 0 ? this.functionList.filter(function (array_el: any) {
        return filteringArray.filter(function (chl: any) {
          return chl.function.value == array_el.id;
        }).length == 0
      }) : this.functionList;

      filteredArray = typeof this.teamCreateForm.controls[index].value.function.value === 'string' ? filteredArray.filter((f: any) => f.name.toLowerCase().indexOf(this.teamCreateForm.controls[index].value.function.value.toLowerCase()) === 0) : filteredArray;
      return filteredArray;
    } else {
      return [];
    }
  }

  updateTeam() {
    const teamData: any = [];
    this.teamCreateForm.value.forEach((element: any) => {
      const payloadObj = {
        function: element.function.value,
        team_members: element.team_members.value
      };
      teamData.push(payloadObj);
    });

    const payload = { team_data: teamData };
    this.ps.updateOpportunity(this.opportunityId, payload).subscribe((res: any) => {
      this.spinner.hide();
      this.ps.getOppData(this.opportunityId).subscribe((res) => this.patchResponse = res);
    }, error => {
      this.spinner.hide();
      throw error;
    })
  }

  closeFinancial() {
    this.isMemberSelected = false;
  }

  getVendorList(index: any) {
    this.ps.getOpportunityFormData('opportunity_empanelled_vendors').subscribe((res: any) => {
      this.vendorList = JSON.parse(JSON.stringify(res.records[0].field_values));
      this.filteredVendorList = this.vendorList;

      this.gdpCreateForm.clear();
      if (this.patchResponse.opportunity_vendors?.length >= 0) {
        this.patchResponse.opportunity_vendors.forEach((element: any) => {
          this.gdpCreateForm.push(
            this.formBuilder.control({
              vendor: new FormControl(element.vendor_details.id),
            })
          )
        });
      }
    }, error => {
      this.spinner.hide();
      throw error;
    })
  }

  searchVendor(index: any) {
    return this.getVendors(index)
  }

  getVendorName(vendorId: any) {
    if (vendorId) {
      return this.filteredVendorList.find((item: any) => item.id === vendorId).name;
    }
  }


  getVendors(index: any) {
    if (this.vendorList) {
      let filteringArray = this.gdpCreateForm.value ? this.gdpCreateForm.value : [];
      var filteredArray = filteringArray.length > 0 ? this.vendorList.filter(function (array_el: any) {
        return filteringArray.filter(function (chl: any) {
          return chl.vendor.value == array_el.id;
        }).length == 0
      }) : this.vendorList;

      filteredArray = typeof this.gdpCreateForm.controls[index].value.vendor.value === 'string' ? filteredArray.filter((f: any) => f.name.toLowerCase().indexOf(this.gdpCreateForm.controls[index].value.vendor.value.toLowerCase()) === 0) : filteredArray;
      return filteredArray;
    } else {
      return [];
    }
  }

  removeVendor(index: any) {
    this.gdpCreateForm.removeAt(index);
  }

  updateVendors() {
    let payload: any = {};
    const vendors_values = this.gdpCreateForm.value.map((item: any) => {
      return { vendor: item.vendor.value };
    })
    payload.empanelled_vendors = vendors_values;

    this.ps.updateOpportunity(this.opportunityId, payload).subscribe((res: any) => {
      this.spinner.hide();
      this.ps.getOppData(this.opportunityId).subscribe((res) => this.patchResponse = res);
    }, error => {
      this.spinner.hide();
      throw error;
    })
    this.searchText = '';
  }

  getTimeline(type?: any) {
    this.ps.getOpportunityFormData('opportunity_timelines').subscribe((res: any) => {
      var fullTimelist = JSON.parse(JSON.stringify(res.records[0].field_values));
      this.timelineList = fullTimelist;
      this.filteredTimeLineList = this.timelineList;
      this.timeLineCreateForm.clear();
      if (this.patchResponse.opportunity_timeline?.length >= 0) {
        this.patchResponse.opportunity_timeline.forEach((element: any) => {
          this.timeLineCreateForm.push(
            this.formBuilder.control({
              timeline: new FormControl(element.timeline_details.id),
              timeline_date: new FormControl(element.timeline_date),
            })
          )
        });
      }
    }, error => {
      this.spinner.hide();
      throw error;
    })
  }

  getTimelineList(index: any) {
    if (this.timelineList) {
      let filteringArray = this.timeLineCreateForm.value ? this.timeLineCreateForm.value : [];
      var filteredArray = filteringArray.length > 0 ? this.timelineList.filter(function (array_el: any) {
        return filteringArray.filter(function (chl: any) {
          return chl.timeline.value == array_el.id;
        }).length == 0
      }) : this.timelineList;

      filteredArray = typeof this.timeLineCreateForm.controls[index].value.timeline.value === 'string' ? filteredArray.filter((f: any) => f.name.toLowerCase().indexOf(this.timeLineCreateForm.controls[index].value.timeline.value.toLowerCase()) === 0) : filteredArray;
      return filteredArray;
    } else {
      return [];
    }
  }

  getTimelineName(timelineId: any) {
    if (timelineId) {
      return this.timelineList.find((item: any) => item.id === timelineId).display_name;
    }
  }

  searchTimeline(index: any) {
    return this.getTimelineList(index)
  }


  addNewTimeline(index: any, timelineName: any) {
    if (!!timelineName) {
      this.spinner.show();
      var payload = {
        name: timelineName,
        display_name: timelineName,
        type: "opportunity_timeline"
      }
      this.ps.createTimeline(payload).subscribe((res: any) => {
        if (res == false) {
          this.spinner.hide();
          this.toastrService.error('UH OH! This parameter already exists, Please create a parameter that didn’t exist in the system');
        } else {
          this.timelineList.push(res)
          this.toastrService.success('Custom parameter added successfully');
          this.spinner.hide();
        }
      }, error => {
        this.spinner.hide();
        this.toastrService.error('Could not create a custom field');
        throw error;
      });
    }
    else {
      this.toastrService.error('Please enter a custom parameter');
    }
  }

  removeTimeline(index: any) {
    this.timeLineCreateForm.removeAt(index);
  }

  updateTimeline() {
    const timeData: any = [];
    this.timeLineCreateForm.value.forEach((item: any) => {
      const payloadObj = {
        timeline: item.timeline.value,
        timeline_date: moment(item.timeline_date.value).format("YYYY-MM-DD")
      };
      timeData.push(payloadObj);
    })
    const payload = { timeline: timeData };
    this.ps.updateOpportunity(this.opportunityId, payload).subscribe((res: any) => {
      this.spinner.hide();
      this.ps.setValue(1)
      this.ps.getOppData(this.opportunityId).subscribe((res) => { 
        this.ps.setStatusValue(res?.status); 
        setTimeout(() => {
          this.router.navigate([`rfx/` + btoa(this.opportunityId) + `/opportunity/evaluation`]) 
        })
        
      });
    }, error => {
      this.spinner.hide();
      throw error;
    })
  }

  next(type: any) {
    if (type === 'Team') {
      this.teamSubmit = true
      if (this.teamCreateForm.invalid) {
        return;
      }
    }
    else if (type === 'OU/GDP') {
    }
    else if (type === 'Timeline') {
      this.timeLineSubmitted = true;
      if (this.timeLineForm.invalid) {
        return;
      }
      if (!this.disableFields)
        this.updateTimeline();
      else {
        this.ps.setValue(1)
        this.ps.getOppData(this.opportunityId).subscribe((res) => {
          this.ps.setStatusValue(res?.status);
          this.router.navigate([`rfx/` + btoa(this.opportunityId) + `/opportunity/evaluation`])
        });
      }
    }
  }


  clickPrevious() {
    this.router.navigate([`view/${this.projectID}/terms`])
  }

  previous() {
    this.router.navigate([`rfx/` + btoa(this.opportunityId) + `/opportunity/terms`]);
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  getTagObject(id: any) {
    return {
      entity_id: id,
      entity_type: "opportunity"
    }
  }




  onInputChanged(value: string): void {
    console.log(value)
  }

  onOptionSelectionChanged(event: any) {
    const selectedOption = event.option.value;
  }

  onVendorOptionSelectionChanged(event: any) {

  }



  addFunctionItems() {
    this.teamCreateForm.push(
      this.formBuilder.control({
        function: new FormControl('', [Validators.required]),
        team_members: this.formBuilder.array([]),
        input: new FormControl('')
      })
    )
  }

  addDeliveryPartner() {
    this.gdpCreateForm.push(
      this.formBuilder.control({
        vendor: new FormControl('', [Validators.required]),
      })
    )
  }

  addTimelineItems() {
    this.timeLineCreateForm.push(
      this.formBuilder.control({
        timeline: new FormControl('', [Validators.required]),
        timeline_date: new FormControl('', [Validators.required])
      })
    )
  }
}
