import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProgramService } from 'src/app/shared/services/program.service';
import { BreadcrumbService } from 'src/app/shared/services/breadcrumb.service';
import * as moment from 'moment';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
import { ViewEncapsulation } from '@angular/core';
import { MessageService } from 'src/app/shared/services/message.service';
var _ = require('lodash');

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss'],
  encapsulation:ViewEncapsulation.Emulated
})
export class PreviewComponent implements OnInit, OnDestroy {
  staticText: any = (textConfiguration as any).default;
  @ViewChild('pdfContent', { static: false }) pdfContent: ElementRef | any;
  opportunityId: any;
  data: any;
  createdOn: any;
  owners: any;
  vendorList: any;
  ColumnMode = ColumnMode;
  readonly headerHeight = 50;
  readonly rowHeight = 50;
  customStyle = {
    backgroundColor: "#f1f1fc",
    borderRadius: "50%",
    color: "#232459",
    cursor: "pointer",
    fontWeight: 'bolder'
  };
  customParameters: any;
  teamList: any;
  vendorsName: any[] = [];
  mailText = '';
  opportunitNumber: any;
  exportPayload: any[] = [];
  setupModel = true;
  profileModel = true;
  keyModel = true;
  timelineModel = true;
  vendorModel = true;
  teamModel = true;
  opportunityModel = true;
  listOfScope = [{
    Id: "opportunityCheck", name: 'new_opportunity'
  }]
  exportSectionList: any;
  field_names: any[] = [];
  checked = false;
  disableFields: boolean=false;
  isRedirectFrom = false;
  projectID:any='';
  sub:any;
  opportunityInfo: any;
  breadcrumb:any;
  okrArray:any;
  selectedOkr:any;
  context: any;
  opportunity_data: any;

  constructor(
    private ps: ProgramService,
    private spinner: NgxSpinnerService,
    private toastrService: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public breadCrumService:BreadcrumbService,
    private messageService: MessageService
  ) {
    this.context = {
      componentParent: this,
      componentName: PreviewComponent
    }

    this.field_names = [{ name: 'new_opportunity', selected: true },
    { name: 'opportunity_setup', selected: true },
    { name: 'opportunity_profile', selected: true },
    { name: 'opportunity_key_results', selected: true },
    { name: 'opportunity_teams', selected: true },
    { name: 'opportunity_empanelled_vendors', selected: true },
    { name: 'opportunity_timelines', selected: true }];
  }

  ngOnInit(): void {
    this.messageService.setGnews('Opportunity Preview & Send')
    this.sub = this.route
      .queryParams
      .subscribe((params:any) => {
        this.isRedirectFrom = params['redirectFrom'] || false;
        this.projectID = params['id']; 
      });
      let oppInfo = localStorage.getItem('opportunity-info');
      this.opportunityInfo = typeof(oppInfo) === 'string' ? JSON.parse(oppInfo) : {};
  
      this.breadCrumService.breadcrumbs$.subscribe((bc: any) => {
        this.breadcrumb = bc;
      })
    this.route.parent?.parent?.params.subscribe((res: any) => {
      this.opportunityId = atob(res.id);
      this.getOpportunityById();
      this.getOkr();
      this.getTeamList();
    })
  }

  getOpportunityById() {
    this.ps.getSingleOpportunityById(this.opportunityId).subscribe((res: any) => {
      let breadCrumbFirst: any = [this.breadcrumb[0]];
      let breadCrumbLast: any = [this.breadcrumb[this.breadcrumb.length - 1]];
      let breadCrumb = [
        { label: res.portfolio_details[0].name, url: '/program', queryParams: {portfolio: res?.portfolio_details[0]?.id} },
        { label: res.portfolio_details[1].name, url: '/program', queryParams: {subportfolio: res?.portfolio_details[1]?.id} },
        { label: res.program?.name, url: '/program', queryParams: {program: res?.program?.id} }
      ];
      breadCrumbFirst.push(...breadCrumb, ...breadCrumbLast)
  
      this.breadCrumService.setBreadcrumb(breadCrumbFirst);

      this.data = res;
      this.opportunity_data = res;

      this.ps.setStatusValue(res?.status);
      if(res.status.slug == 'opportunity_evaluating' || res.status.slug == 'opportunity_sow_signed' || res.status.slug == 'opportunity_cancelled'){
        this.disableFields =true;
      }
      this.opportunitNumber = this.data.opportunity_number;
      //this.data.funding_status = false ? 'Funded' : 'Not Funded';
      this.createdOn = moment(this.data.created_on).format('LL');
      this.exportSectionList = res.export_section_list;
     
      this.data?.owners?.map((i: any) => {
        this.owners = i.owner_details.full_name;
      });
      (this.data.opportunity_timeline || []).forEach((item: any) => {
        item.timeline_date = moment(item.timeline_date).format('LL');
      });

      (this.data.opportunity_teams || []).forEach((elm: any) => {
        elm.function.team_members.forEach((i: any) => {
          this.vendorsName.push({ name: i.full_name, department: elm.function.display_name });
        });
      });
      this.vendorList = this.data.opportunity_vendors;
      this.customParameters = this.data.custom_parameter;
      if (!this.exportSectionList) {
        this.getAllDynamicFormData();
      } else {
        this.exportPayload = res.export_section_list;
      
        this.field_names?.forEach((i: any) => {
          var formDetails = this.exportPayload.find(x => {
            return x.section_details.form_name == i.name;
          });
          if (formDetails) {
            i.selected = formDetails.export;
            this.selectCheckBox(i.name, formDetails.export);
          }
        });
      }
    }, error => {
      throw error;
    })
  }

  getSelecetedData(formName: string) {
    return this.field_names.find((x: any) => {
      x.name == formName;
    })
  }

  previousSection() {     
    this.ps.setValue(4)
    this.router.navigate([`rfx/`+btoa(this.opportunityId)+`/opportunity/more-info`]).then(() => {
    this.ps.setValue(4)
    });
  }

  openModal(content: any) {
    if(this.data.opportunity_vendors&& this.data.opportunity_vendors.length>0){
      this.dialog.open(content);
    }else{
      this.toastrService.error("Please add vendor details to publish");
    }
  }

  onCheck(e: any){
    this.checked = e.checked;
  }

  sendEmail() {
    var payload = {
      action: "Send"
    }
    this.dialog.closeAll();
    this.spinner.show();
    this.ps.opportunityActions(this.opportunityId, payload).subscribe((res: any) => {
      this.spinner.hide();      
      if (res.status == 208 || res.status == 200) {
        // this.ps.getOppData(this.opportunityId).subscribe((response: any) => {          
        // this.data['status'] =response?.status;
        //   this.ps.setStatusValue(response?.status);
        //   if(res.status.slug == 'opportunity_evaluating' || res.status.slug == 'opportunity_sow_signed' || res.status.slug == 'opportunity_cancelled'){
        //     this.disableFields =true;
        //   }
        // });
        this.toastrService.success('Mail sent successfully');
        this.router.navigate(["/getproposal/"+btoa(this.opportunityId)+"/proposal-evaluation"]);
        return;
      }
    }, error => {
      this.spinner.hide();
      throw error;
    });
  }

  emailSupport() {
    location.href = "mailto:email-address@gmail.com?subject='I need help'&body='write some message'";
  }

  cancel() {
    this.dialog.closeAll();
  }
  getAllDynamicFormData() {
    this.field_names = ['new_opportunity', 'opportunity_setup', 'opportunity_profile', 'opportunity_key_results',
      'opportunity_teams', 'opportunity_empanelled_vendors', 'opportunity_timelines'];
    this.exportPayload = [];
    this.spinner.hide()
    this.field_names.forEach((i: string) => {
      let addlQueryParam: string = '';
      if(i == 'opportunity_key_results'){
        addlQueryParam = `&opportunity_id=${this.opportunityId}`;
      }
      this.ps.getOpportunityFormData(i, addlQueryParam).subscribe((res: any) => {
        const data = res.records[0];
        if (data != undefined) {
          data.export = true;
          data.section = data.form_details.id;
          data.section

          this.exportPayload.push(data);
         
        }
      }, error => {
        throw error;
      });
    })

  }

  selectCheckBox(name: string, check: boolean) {
    var field_name = '';
    switch (name) {
      case 'opportunityCheck':
      case 'new_opportunity':
        this.opportunityModel = check;
        field_name = 'new_opportunity';
        break;
      case 'setupCheck':
      case 'opportunity_setup':
        this.setupModel = check;
        field_name = 'opportunity_setup';
        break;
      case 'profileCheck':
      case 'opportunity_profile':
        this.profileModel = check;
        field_name = 'opportunity_profile';
        break;
      case 'keyCheck':
      case 'opportunity_key_results':
        this.keyModel = check;
        field_name = 'opportunity_key_results'
        break;
      case 'teamCheck':
      case 'opportunity_teams':
        this.teamModel = check;
        field_name = 'opportunity_teams';
        break;
      case 'vendorCheck':
      case 'opportunity_empanelled_vendors':
        this.vendorModel = check;
        field_name = 'opportunity_empanelled_vendors';
        break;
      case 'timelineCheck':
      case 'opportunity_timelines':
        this.timelineModel = check;
        field_name = 'opportunity_timelines';
        break;
    }
    return field_name;
  }

  showOptions(e: any) {
    this.toastrService.warning('Please save the changes');
    var field_name: any;
    field_name = this.selectCheckBox(e.source.id, e.checked);
    this.exportPayload.filter(x => x.section_details.form_name == field_name).forEach(x => {
      x.export = e.checked
    });
  }

  showSend(e: any) {
    this.exportPayload.forEach((i: any) => {
      if (i.section == e.section) {
        this.exportPayload = this.exportPayload.filter((elm: any) => elm.section != i.section);
        this.exportPayload.push({
          section: e.section,
          export: e.export
        });
      }
    })
  }

  save() {
    const payload = {
      export_section: this.exportPayload.map((i: any) => {
        return { export: i.export, section: i.section }
      })
    }
    this.spinner.show()
    this.ps.exportOpportunities(this.opportunityId, payload).subscribe((res: any) => {
      this.spinner.hide()
      this.saveData();
    }, error => {
      this.spinner.hide();
      throw error;
    });
  }

  saveData() {
    var payload = {
      action: "Save"
    }
    this.spinner.show()
    this.ps.opportunityActions(this.opportunityId, payload).subscribe((res: any) => {
      this.spinner.hide()
      if (res.status == 208 || res.status == 200) {
        this.toastrService.success('Document saved successfully');
        return;
      }
    }, error => {
      this.spinner.hide();
      throw error;
    });
  }

  backToList() {
    this.router.navigate(['program']);
  }

  download() {
    var payload = {
      action: "Download"
    }
    this.spinner.show();
    this.ps.opportunityFileDownload(this.opportunityId, payload).subscribe((res: any) => {
      let downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(res);
      downloadLink.setAttribute('download', this.opportunitNumber);
      document.body.appendChild(downloadLink);
      downloadLink.click();
      this.toastrService.success('File downloaded successfully');
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      throw error;
    });
  }

  edit() {   
    this.ps.setValue(0)
    this.router.navigate([`rfx/`+btoa(this.opportunityId)+`/opportunity/setup`]).then(() => {
     // window.location.reload();
    });
  }

  getTeamList() {
    this.spinner.show()
    this.ps.getOpportunityFormData('opportunity_teams').subscribe((res: any) => {
      this.spinner.hide()
      var wholeTeamList = res.records;
      const teamList = wholeTeamList.find((i: any) => i.field_name == "opportunity_team");
      this.teamList = teamList.field_values;
    },err=>this.spinner.hide());
  }

  getShortName(fullName: any) {
    return fullName.split(' ').slice(0, 2).map((n: any) => n[0]).join('');
  }  
  ngOnDestroy() {
    this.sub.unsubscribe();
  }  
  clickPrevious(){
    this.router.navigate([`view/${this.projectID}/overview`])
  }

  getTagObject(id: any){
    return {
      entity_id: id,
      entity_type: "opportunity"
    }
  }

  getOkr(){
    this.spinner.show();
    this.ps.getOpportunityKrData(this.opportunityId).subscribe((getOkr:any) => {
      getOkr.okr_hierarchy.forEach((objective: any) => {
        objective.level = "objective";
        objective.name = objective.title;
        if(objective.key_results.length > 0){
          objective.children = objective.key_results;
          objective.children.forEach((kr: any) => {
            kr.level = "kr";
            kr.name = kr.title;
            kr.objective_id = objective.id;
            if(kr.goals.length > 0){
              kr.children = kr.goals;
              kr.children.forEach((goals: any) => {
                goals.level = "goal";
                goals.name = goals.goal_name;
                goals.kr_id = kr.id;
                goals.objective_id = objective.id;

                if(getOkr.selected_goal_ids.findIndex((e: any) => e.goal_id == goals.id) > -1){
                  goals.selected = true;
                }else{
                  goals.selected = false;
                }
              })

              if(kr.children.filter((e: any) => e.selected === true).length == kr.children.length){
                kr.selected = true;
                kr.selection_type = 'all';
              }else if(kr.children.filter((e: any) => e.selected === true).length > 0){
                kr.selected = true;
                kr.selection_type = 'intermediate';
              }else{
                kr.selected = false;
                kr.selection_type = 'none';
              }
            }
          });

          if(objective.children.filter((e: any) => e.selection_type === 'all').length == objective.children.length){
            objective.selected = true;
            objective.selection_type = 'all';
          }else if(objective.children.filter((e: any) => e.selection_type === 'intermediate').length > 0 || objective.children.filter((e: any) => e.selection_type === 'all').length > 0){
            objective.selected = true;
            objective.selection_type = 'intermediate';
          }else{
            objective.selected = false;
            objective.selection_type = 'none';
          }
        }
      });
      this.okrArray = getOkr.okr_hierarchy;
      this.selectedOkr = getOkr.selected_goal_ids;
      this.spinner.hide();      
    })
  }
}


