import { Inject, Input, ViewEncapsulation } from '@angular/core';
import { Component, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as textConfiguration from 'src/assets/static-text-configuration.json';

@Component({
  selector: 'app-custom-properties',
  templateUrl: './custom-properties.component.html',
  styleUrls: ['./custom-properties.component.scss'],
  encapsulation:ViewEncapsulation.Emulated
})

export class CustomPropertiesComponent implements OnInit {
  
  staticText: any = (textConfiguration as any).default;
  disableFields = false;
  showAddField = false;
  searchText = '';
  search = false;
  parameterName: any;
  functionName: any;
  timelineName: any;
  maxChars = 100;
  customItems: any;
  items: any;
  customFunctionList: any;
  functionList: any;
  context: any;
  buttonName: any;
  isMemberSelected: any;
  teamList: any;
  vendorList: any;
  timelineList: any;
  customTimeline: any;

  textContents = {
    searchText: 'Parameter searched is not found in the list'
  }

  showAdd: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<CustomPropertiesComponent>) {
    
  }

  ngOnInit() {
    this.context = this.data.context;
    this.customItems = this.data.customItems;
    this.items = this.data.items;
    this.functionList = this.data.functionList;
    this.customFunctionList = this.data.customFunctionList;
    this.disableFields = this.data.disableFields;
    this.showAddField = this.data.showAddField;
    this.buttonName = this.data.buttonName;
    this.isMemberSelected = this.data.isMemberSelected
    this.teamList = this.data.teamList;
    this.vendorList = this.data.vendorList;
    this.timelineList = this.data.timelineList;
    this.customTimeline = this.data.customTimeline;
  }

  addColumn(item: any) {
    this.items = this.context.componentParent.addColumn(item);
  }

  addCustomColumn(item: any) {
    this.customItems = this.context.componentParent.addCustomColumn(item)
  }

  removeCustom(item: any) {
    this.customItems = this.context.componentParent.removeCustom(item)
  }

  clearSearchField() {
    this.parameterName = '';
  }

  clearFunctionField() {
    this.functionName = '';
  }

  async customPar() {
    this.parameterName = await this.context.componentParent.customPar(this.parameterName);
  }

  goToAdd(){
    this.showAdd = true
  }

  addFunctionColumn(item: any){
    this.functionList = this.context.componentParent.addFunctionColumn(item)
  }

  async addFunction(){
    this.functionName = await this.context.componentParent.addColumn(this.functionName);
  }

  addCustomFunctionColumn(item: any){
    this.customFunctionList = this.context.componentParent.addCustomFunctionColumn(item)
  }

  removeFunctionColumn(item: any) {
    this.customFunctionList = this.context.componentParent.removeFunctionColumn(item)
  }

  removeFunction(item: any) {
    this.context.componentParent.removeFunction(item)
  }

  goToFunction(){
    this.showAdd = true
  }

  addChips(item: any){
    this.teamList = this.context.componentParent.addChips(item)
  }

  addVendorColumn(item: any){
    this.vendorList = this.context.componentParent.addVendorColumn(item)
  }

  addTimelineColumn(item: any){
    this.timelineList = this.context.componentParent.addTimelineColumn(item)
  }

  async addNewTimeline(){
    this.timelineName = await this.context.componentParent.addNewTimeline(this.timelineName);
  }

  addCustomTimelineColumn(item: any){
    this.customTimeline = this.context.componentParent.addCustomTimelineColumn(item)
  }

  deleteCustomTimeline(item: any){
    this.context.componentParent.deleteCustomTimeline(item)
  }
}
