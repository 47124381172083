<div class="row" id="pdfContent" #pdfContent>
    <div class="col-md-12">
        <div *ngIf="data">
            <div class="row row-cols-2 mb-3">
                <div *ngIf="isRedirectFrom" class="w-auto p-0">
                    <h3 class="screen-name" (click)="clickPrevious()">
                        <mat-icon aria-hidden="false" aria-label="material-icons-filled " class="cursor-pointer"
                            style="font-size: 35px;">chevron_left
                        </mat-icon>
                    </h3>
                </div>
                <div class="col-9">
                    <p id="opportunity_name" class="fs-24 fw-500 mb-1"> {{staticText?.opportunity?.preview || ''}}
                    </p>
                    <p class="m-0 mb-1" style="font-size: 13px;">{{opportunity_data?.opportunity_name}} | {{opportunity_data?.opportunity_number}} | {{opportunity_data?.opportunity_type_details?.name}} | {{opportunity_data?.portfolio_details[0].name}} | {{opportunity_data?.portfolio_details[1].name}}
                        | {{opportunity_data?.program?.name}} | {{opportunity_data?.owners[0].owner_details.full_name}} | {{opportunity_data?.single_point_of_contact?.full_name}}
                    </p>
                    <app-tag [dataObj]="getTagObject(data?.id)" [tagsIds]="data?.tag_ids"></app-tag>
                </div>

                <div class="text-right" [ngClass]="isRedirectFrom?'col-5':'col-3'">
                    <!-- <button id="download" mat-button
                        style="border-color: #f01a9a !important;border-radius: 8px !important; background-color: #d40f78 !important; color: white;"
                        class="fs-16" (click)="download()">
                        <p id="download_pdf" class="m-0 ps-2">
                            <i class="fas fa-arrow-to-bottom mr-1"></i>
                            <span id="preview_section">{{staticText?.rfx.preview_section.download_pdf}}</span>
                        </p>
                    </button> -->
                    <button mat-icon-button color="primary"
                        data-toggle="tooltip" data-placement="top" 
                        (click)="download()" matTooltip="Export as PDF"  matTooltipClass="example-tooltip"><mat-icon>cloud_download</mat-icon></button>
                </div>
            </div>
            <mat-tab-group mat-align-tabs="start" id="previewTab" disableRipple mat-stretch-tabs>
                <mat-tab label="Opportunity" *ngIf="data" id="previewTab">
                    <div class="list-height">
                        <div class="row px-3 py-2 pt-3">
                            <div class="shadow bg-white rounded col-12">
                                <div class="row">
                                    <div class="col-md-10 ps-0">
                                        <p class="pt-2 fw-500 fs-12 sleft-border setup-border" style="color: #ffffff">
                                            <span class="px-3">{{staticText?.rfx.setup.title}}</span>
                                        </p>
                                    </div>
                                    <div class="col-md-2 pt-2">
                                        <section class="example-section">
                                            <mat-checkbox class="example-margin d-flex justify-content-end text-muted"
                                                (change)="showOptions($event)" id="setupCheck"
                                                [(ngModel)]="setupModel"></mat-checkbox>
                                        </section>
                                    </div>
                                </div>
                                <ng-container *ngIf="data?.opportunity_brief != null">
                                    <p class="fs-14 title-color fw-normal mb-0">
                                        {{staticText?.opportunity.opportunity_brief}}</p>
                                    <p class="fs-16 text-black">{{data?.opportunity_brief || ' '}}
                                    </p>
                                </ng-container>
                                <div class="row my-2">
                                    <!-- <div class="col-md-4"
                                                    *ngIf="data?.program_details?.display_name != null">
                                                    <p class="fs-14 title-color fw-normal m-0">Program</p>
                                                    <p class="fs-16 text-black">
                                                        {{data?.program_details?.display_name
                                                        || ' '}}</p>
                                                </div> -->
                                    <div class="col-md-4"
                                        *ngIf="data.parameter_details?.line_of_business_details?.display_name  != null">
                                        <p class="fs-14 title-color fw-normal m-0">
                                            {{staticText?.delivery.overview.lob}}</p>
                                        <p class="fs-16 text-black">
                                            {{data.parameter_details?.line_of_business_details?.display_name
                                            || ' '}}</p>
                                    </div>
                                    <div class="col-md-4" *ngIf="data.parameter_details?.cost_centre_details != null">
                                        <p class="fs-14 title-color fw-normal m-0">
                                            {{staticText?.delivery.overview.cost_center}}</p>
                                        <p class="fs-16 text-black">
                                            {{data.parameter_details?.cost_centre_details?.display_name
                                            || ' '}}
                                        </p>
                                    </div>
                                    <div class="col-md-4"
                                        *ngIf="data.parameter_details?.department_details?.display_name != null">
                                        <p class="fs-14 title-color fw-normal m-0">
                                            {{staticText?.delivery.overview.dept}}</p>
                                        <p class="fs-16 text-black">
                                            {{data.parameter_details?.department_details?.display_name
                                            || ' '}}</p>
                                    </div>
                                    <div class="col-md-4"
                                        *ngIf="data.parameter_details?.business_unit_details?.display_name != null">
                                        <p class="fs-14 title-color fw-normal m-0">
                                            {{staticText?.delivery.overview.business_unit}}</p>
                                        <p class="fs-16 text-black">
                                            {{data.parameter_details?.business_unit_details?.display_name
                                            || ' '}}</p>
                                    </div>
                                    
                                </div>
                                <div *ngFor="let i of data.custom_parameter">
                                </div>
                            </div>
                        </div>
                        <div class="row px-3 py-2">
                            <div class="shadow bg-white rounded col-12">
                                <div class="row">
                                    <div class="col-md-10 ps-0">
                                        <p class="fw-500 pt-2 pleft-border  profile-border fs-12" style="color:#ffffff">
                                            <span class="px-3">{{staticText?.rfx.setup.profile}}</span>
                                        </p>
                                    </div>
                                    <div class="col-md-2 pt-2">
                                        <section class="example-section">
                                            <mat-checkbox class="example-margin  d-flex justify-content-end text-muted"
                                                (change)="showOptions($event)" id="profileCheck"
                                                [(ngModel)]="profileModel"></mat-checkbox>
                                        </section>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4" *ngIf="data.category_details?.display_name != null">
                                        <p class="fs-14 title-color fw-normal m-0">
                                            {{staticText?.opportunity.opportunity_category}}</p>
                                        <p class="text-black">{{data.category_details?.display_name
                                            || ' '}}</p>
                                    </div>
                                    <div class="col-md-4" *ngIf="data.classification_details?.display_name != null">
                                        <p class="fs-14 title-color fw-normal m-0">
                                            {{staticText?.opportunity.opp_classficication}}
                                        </p>
                                        <p class="text-black">
                                            {{data.classification_details?.display_name || ' '}}</p>
                                    </div>
                                    <div class="col-md-4" *ngIf="data.trigger_details?.display_name != null">
                                        <p class="fs-14 title-color fw-normal m-0">
                                            {{staticText?.opportunity.opp_triggers}}</p>
                                        <p class="text-black">{{data.trigger_details?.display_name
                                            || ' '}}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4" *ngIf="data.work_type_details?.display_name != null">
                                        <p class="fs-14 title-color fw-normal m-0">
                                            {{staticText?.opportunity.type_work}}</p>
                                        <p class="text-black">{{data.work_type_details?.display_name
                                            || ' '}}</p>
                                    </div>
                                    <div class="col-md-4" *ngIf="data.funding_status != null">
                                        <p class="fs-14 title-color fw-normal m-0">
                                            {{staticText?.opportunity.fund_status}}</p>
                                        <div *ngIf="data.funding_status == true">
                                            <p class="text-black">{{staticText?.opportunity.funded}}</p>
                                        </div>
                                        <div *ngIf="data.funding_status != true">
                                            <p class="text-black">{{staticText?.opportunity.not_funded}}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4" *ngFor="let i of customParameters">
                                        <p class="fs-14 title-color fw-normal m-0">{{i
                                            .custom_parameter_details?.display_name || ' '}}</p>
                                        <p class="m-0 fs-16 text-black">{{i.custom_parameter_value
                                            || ' '}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row px-3 py-2">
                            <div class="shadow bg-white rounded col-12">
                                <div class="row">
                                    <div class="col-md-10 ps-0">
                                        <p class="fw-500 pt-2 fs-12 kleft-border  key-border" style="color: #ffffff;">
                                            <span
                                                class="px-3">{{staticText?.rfx.preview_section.strategic_goal_krs}}</span>
                                        </p>
                                    </div>
                                    <div class="col-md-2 pt-2">
                                        <section class="example-section">
                                            <mat-checkbox class="example-margin d-flex justify-content-end text-muted"
                                                (change)="showOptions($event)" id="keyCheck"
                                                [(ngModel)]="keyModel"></mat-checkbox>
                                        </section>
                                    </div>
                                </div>

                                <app-selection-tree-listing [(items)]="okrArray" selectedOkr="selectedOkr" [disabled]="disableFields" [tree]="'program'" [context]="context"></app-selection-tree-listing>

                                <!-- <div *ngFor="let data of okrArray" class="row px-3">
                                    <div class=" my-1 px-0 scope-border col-12" *ngIf="data.selected">
                                        <div class="pt-1">
                                            <p class="fw-bold pt-2 ps-3 mb-0 fs-12 time-border tleft-border" style="color: #D40F78;">
                                                {{data.title}}</p>
                                        </div>
                                        <div *ngFor="let i of data.key_results">
                                            <div *ngIf="i.selected">
                                                <div class="ps-3 my-2">
                                                    <p class="fw-bold pt-2 ps-3 mb-0 pleft-border profile-border fs-12 " style="color:#07B797;width:100%">{{i.title}}</p>
                                                    <div class="p-2">
                                                        <div *ngFor="let s of i.goals">
                                                            <p *ngIf="s.selected" class="fs-14 title-color ps-3 d-flex my-1 fs-12" style="display: block;background-color: rgb(227, 230, 233);padding:4px;border-radius:4px">{{s.goal_name}}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <app-tree *ngFor="let tree of data.opportunity_key_results; index as treeindex;"
                                    [treeclass]="'pt-2 pb-2'" [treearray]="tree.goal" [treecardtype]="'simpletree'"
                                    [treeindex]="treeindex" [parentTagName]="'previewGoal'"
                                    [subParentTagname]="'previewParent'">
                                </app-tree> -->
                            </div>
                        </div>
                        <div class="row px-3 py-2">
                            <div class="shadow bg-white rounded col-12 ">
                                <div class="row">
                                    <div class="col-md-10 ps-0">
                                        <p class="fw-500 pt-2 fs-12 team-border left-border" style="color: #ffffff;">
                                            <span class="px-3">{{staticText?.rfx.setup.team}}</span>
                                        </p>
                                    </div>
                                    <div class="col-md-2 pt-2">
                                        <section class="example-section">
                                            <mat-checkbox class="example-margin d-flex justify-content-end text-muted"
                                                (change)="showOptions($event)" id="teamCheck"
                                                [(ngModel)]="teamModel"></mat-checkbox>
                                        </section>
                                    </div>
                                </div>
                                <div class="row mx-auto">
                                    <div *ngFor="let i of data.opportunity_teams;" class="col-12">
                                        <p class="title-color fw-normal fs-16 fw-500 mb-0">
                                            {{i.function.display_name}}</p>
                                        <div class="row">
                                            <div class="col-md-3 p-3"
                                                *ngFor="let s of i.function.team_members;let j=index;">
                                                <div class="row py-2 preview-border">
                                                    <div class="col-md-2 pt-2 ps-1">
                                                        <span class="name"
                                                            [ngStyle]="{'backgroundColor': (j|getRandomColor:'chart').bgColor,'color': (j|getRandomColor:'chart').textColor}">{{getShortName(s.full_name)}}</span><!-- [ngClass]="{'titlecase-6':  s.full_name.includes('H'), 'titlecase-7': s.full_name.includes('Y'), 'titlecase-3': s.full_name.includes('D'), 'titlecase-5': s.full_name.includes('A') }"-->
                                                    </div>
                                                    <div class="col-md-10 px-4">
                                                        <p class="mb-0 fs-16 fw-500 ps-2 text-truncate"
                                                            matTooltip={{s.full_name}}
                                                            matTooltipClass="example-tooltip">
                                                            {{s.full_name}}
                                                        </p>
                                                        <p class="mb-0 ps-2 text-truncate text-muted"
                                                            matTooltip={{s.email}} matTooltipClass="example-tooltip">
                                                            {{s.email}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row px-3 py-2">
                            <div class="shadow px-2 bg-white rounded col-12">
                                <div class="row">
                                    <div class="col-md-10 ps-0">
                                        <p class="fw-500 pt-2 fs-12 mb-0 ml-1 empall-border eleft-border"
                                            style="color: #ffffff;"><span
                                                class="px-3">{{staticText?.rfx.setup.gdp_fullform}}</span>
                                        </p>
                                    </div>
                                    <div class="col-md-2 pt-2">
                                        <section class="example-section">
                                            <mat-checkbox class="example-margin d-flex justify-content-end text-muted"
                                                (change)="showOptions($event)" id="vendorCheck"
                                                [(ngModel)]="vendorModel"></mat-checkbox>
                                        </section>
                                    </div>
                                </div>
                                <div class="row mx-auto">
                                    <div class="col-md-4 p-0 py-1" *ngFor='let i of vendorList'>
                                        <div class="row  mx-2 vendor-border p-0">
                                            <div class="col-md-2 ps-1 pt-2">
                                                <ngx-avatar name="{{ i.vendor_details.name}}" size="38" [round]="true">
                                                </ngx-avatar>
                                            </div>
                                            <div class="col-md-10 px-3 pe-2">
                                                <div class="row">
                                                    <div class="col-md-12 p-1">
                                                        <p class="fw-500 fs-16 ps-1 mb-0 text-truncate"
                                                            style=" color:#222F3E;margin-top: 10px;"
                                                            matTooltip={{i.vendor_details.name}}
                                                            matTooltipClass="example-tooltip">
                                                            {{i.vendor_details.name}}</p>
                                                        <!-- <p class="fs-14 ps-1 mb-0" style="color:#7A828B">
                                                            {{i.vendor_details.team}}</p> -->
                                                    </div>
                                                </div>
                                                <!-- <div class="row">
                                                    <div class="col-md-3 ps-0 pe-5">
                                                        <p class="d-flex">
                                                            <mat-icon class="text-warning cursor-pointer fs-5">
                                                                star</mat-icon><span
                                                                class="d-flex fs-14 text-black fw-500"
                                                                matTooltip="Rating"
                                                                matTooltipClass="example-tooltip">{{i.vendor_details.rating}}</span>
                                                        </p>
                                                    </div>
                                                    <div class="col-md-3 ps-0 pe-0 ml-2">
                                                        <p class="d-flex"> <img src="./assets/images/project_count.svg">
                                                            <span class="d-flex px-1 fs-14" matTooltip="Projects"
                                                                matTooltipClass="example-tooltip"
                                                                style="color: #738199;">{{i.vendor_details.project_count}}</span>
                                                        </p>
                                                    </div>
                                                    <div class="col-md-3 ps-0">
                                                        <p class="d-flex"> <img src="./assets/images/person.svg">
                                                            <span
                                                                class="d-flex px-1 fs-14">{{staticText?.rfx.setup.profile}}</span>
                                                        </p>
                                                    </div>
                                                </div> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row px-3 py-2">
                            <div class="shadow pe-2 bg-white rounded col-12 pt-1">
                                <div class="row">
                                    <div class="col-md-10 ps-0">
                                        <p class="fw-500 pt-2 fs-12 time-border tleft-border" style="color: #ffffff;">
                                            <span class="px-3">{{staticText?.rfx.setup.timeline}}</span>
                                        </p>
                                    </div>
                                    <div class="col-md-2 pt-2">
                                        <section class="example-section">
                                            <mat-checkbox class="example-margin d-flex justify-content-end text-muted"
                                                (change)="showOptions($event)" id="timelineCheck"
                                                [(ngModel)]="timelineModel"></mat-checkbox>
                                        </section>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4  col-12" *ngFor="let i of data.opportunity_timeline">
                                        <p class="fs-14 title-color fw-normal px-3 mb-0">
                                            {{i.timeline_details.name || '-'}}</p>
                                        <p class="px-3 text-black">{{i.timeline_date || '-'}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <!-- <mat-tab label="Scope" *ngIf="data" id="previewTab">
                    <div class="list-height">
                        <app-preview-scope (sendSelected)="showSend($event)"></app-preview-scope>
                    </div>
                </mat-tab>
                <mat-tab label="Terms of Engagement" *ngIf="data" id="previewTab">
                    <div class="list-height">
                        <app-preview-terms (sendSelected)="showSend($event)"></app-preview-terms>
                    </div>
                </mat-tab> -->
                <mat-tab label="Evaluation Framework" *ngIf="data" id="previewTab">
                    <div class="list-height">
                        <app-preview-evaluation (sendSelected)="showSend($event)">
                        </app-preview-evaluation>
                    </div>
                </mat-tab>
                <mat-tab label="More Information" *ngIf="data" id="previewTab">
                    <div class="list-height">
                        <app-preview-more-info (sendSelected)="showSend($event)">
                        </app-preview-more-info>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
</div>

<div class="col footer">
    <div class="row mt-3">
        <div class="col-md-6">

            <div class="row">
                <div class="col-md-4">
                    <button id="rfx_prev_section" mat-button mat-stroked-button color="primary"
                        (click)="previousSection()">
                        <mat-icon class="">chevron_left</mat-icon>
                        <span class="fs-16">{{staticText?.rfx.prev_section}}</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="col-md-6 d-flex align-items-center justify-content-end">
            <button id="buttons_edit_btn" mat-button color="primary" class="mr-1" (click)="edit()"
                *ngIf="!disableFields">
                <span class="m-0 fs-16">
                    {{staticText?.buttons.edit_btn}}
                </span>
            </button>
            <button id="save" mat-button mat-stroked-button color="primary"  class="ml-1 mr-1" (click)="save()"
                *ngIf="!disableFields" [disabled]="disableFields">
                <span class="m-0 fs-16">
                    {{staticText?.buttons.save_btn}}
                </span>
            </button>
            <button id="buttons_publish_btn" mat-button mat-flat-button class="ml-1" color="primary"
                (click)="openModal(mailList)"
                *ngIf="data?.status?.slug =='opportunity_created' || data?.status?.slug =='opportunity_modified'">
                <span class="m-0 fs-16" style="color: white;">
                    {{staticText?.buttons.publish_btn}}
                </span>
            </button>
        </div>
    </div>
</div>

<ng-template #mailList>
    <div class="modal-dialog m-0" style="width: 520px;">
        <div class="modal-content">
            <app-modal-header-common [title]="staticText?.rfx.preview_section.dps"> </app-modal-header-common>

            <div class="modal-body pb-0">
                <div class="col-md-12">
                    <p id="select_dps" class="text-muted text-size primary">
                        {{staticText?.rfx.preview_section.select_dps}}</p>
                    <mat-list>
                        <mat-list-item id="padding" *ngFor="let i of data.opportunity_vendors;let j=index;"
                            class="row w-100 border-bottom m-0 mb-3">
                            <div class="col-md-12 px-3">
                                <div class="row">
                                    <div class="col-md-2 mt-2">
                                        <span class="name"
                                            [ngStyle]="{'backgroundColor': j|getRandomColor}">{{getShortName(i.vendor_details.name)}}</span><!--[ngClass]="{'titlecase-6':  i.vendor_details.name.includes('H'), 'titlecase-7': i.vendor_details.name.includes('Y'), 'titlecase-3': i.vendor_details.name.includes('D'), 'titlecase-5': i.vendor_details.name.includes('A') }"-->
                                    </div>
                                    <div class="col-md-8">
                                        <div class="fw-500 text-muted primary fs-14 text-truncate">
                                            {{i.vendor_details.name}}</div>
                                        <p id="vendor_details.team" class="text-muted text-size primary">
                                            {{i.vendor_details.team}}</p>
                                    </div>
                                    <div class="col-md-2">
                                        <section class="example-section mt-2">
                                            <mat-checkbox class="example-margin d-flex justify-content-end text-muted"
                                                [checked]=true (change)="onCheck($event)">
                                            </mat-checkbox>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </mat-list-item>
                    </mat-list>
                </div>

            </div>
            <div class="modal-footer border-0 p-0">
                <div class="row">
                    <div class="col-md-6 p-0">
                        <button id="cancel" mat-button mat-dialog-close class="my-3 px-4 p-0 border me-3 fs-16"
                            style="color: #1a85d6 !important"
                            (click)="cancel()">{{staticText?.buttons.cancel_btn}}</button>

                    </div>
                    <div class="col-md-6 ps-0">
                        <button id="sendEmail" mat-button mat-dialog-close (click)="sendEmail()"
                            class="my-3 px-4 p-0 me-3 next-color text-white fs-16">{{staticText?.buttons.confirm_btn}}</button>
                        <!-- <a mat-button href="https://mail.google.com/mail/u/0/?tab=rm&ogbl#inbox"
                            class="my-3 px-4 p-0 me-3 next-color text-white fs-16" target="_blank">Confirm</a> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>