<div class="row field-adjust" id="custom-height">
  <div *ngIf="!patchResponse" style="height: calc(100vh - 200px)">
    <app-loading></app-loading>
  </div>
  <div class="col-md-12" *ngIf="patchResponse">
    <div class="row">
      <div *ngIf="isRedirectFrom" class="w-auto p-0">
        <h3 class="screen-name" (click)="clickPrevious()">
          <mat-icon
            id="chevron_left"
            aria-hidden="false"
            aria-label="material-icons-filled "
            class="cursor-pointer"
            style="font-size: 35px"
            >chevron_left
          </mat-icon>
        </h3>
      </div>
      <div
        [ngClass]="isRedirectFrom ? 'col-11' : 'col-12'"
        class="title-holder"
      >
        <p id="title" class="fw-bold fs-24 mb-0">
          {{ stepperTitles.title }}
          <i
            class="fa fa-edit"
            (click)="showEdit()"
            *ngIf="stepperTitles.title == 'Opportunity Setup'"
          ></i>
        </p>
        <p class="m-0 mb-1" style="font-size: 13px">
          {{ patchResponse?.opportunity_name }} |
          {{ patchResponse?.opportunity_number }} |
          {{ patchResponse?.opportunity_type_details?.name }} |
          {{ patchResponse?.portfolio_details[0].name }} |
          {{ patchResponse?.portfolio_details[1].name }} |
          {{ patchResponse?.program?.name }} |
          {{ patchResponse?.owners[0].owner_details.full_name }}
        </p>
        <app-tag
          [dataObj]="getTagObject(patchResponse?.id)"
          [tagsIds]="patchResponse?.tag_ids"
          *ngIf="patchResponse"
        ></app-tag>
      </div>
      <!-- <div class="col-md-3 button-pos" *ngIf="!!stepperTitles.buttonName && !disableFields && stepperTitles.buttonName == 'Key Results'">
                <button id='+parameter' mat-button class="float-end danger fs-16" mat-flat-button
                    (click)="addPopup()">
                    <mat-icon id="add" aria-hidden="false" aria-label="material-icons-filled"
                        class="cursor-pointer fs-5">add
                    </mat-icon>
                    {{stepperTitles.buttonName}}
                </button>
            </div> -->
    </div>
    <hr />
    <mat-stepper
      [linear]="
        patchResponse?.status?.slug == 'opportunity_draft' ? true : false
      "
      labelPosition="bottom"
      [disableRipple]="true"
      #stepper
      (selectionChange)="selectedStepper($event)"
      id="setupStep"
      style="height: 67%"
    >
      <mat-step
        label="{{ staticText?.rfx?.setup.title }}"
        [stepControl]="setupForm?.disabled ? '' : setupForm"
        [state]="stepStates[0]"
      >
        <ng-template matStepLabel>
          <p
            id="setup"
            class="mat-design"
            matTooltip="{{ staticText?.rfx?.setup.title }}"
          >
            {{ staticText?.rfx?.setup.title }}
          </p>
        </ng-template>
        <div class="set-up custom-height pr-2">
          <div class="row">
            <div class="col-md-12">
              <form [formGroup]="setupForm">
                <div class="row mt-2" *ngIf="editShow">
                  <div class="col-md-12 mb-1">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label class="label-design">{{
                        staticText?.rfx.preview_section.opp_name
                      }}</mat-label>
                      <input
                        type="text"
                        matInput
                        fullWidth
                        [maxlength]="maxChars"
                        formControlName="name"
                        id="name"
                      />
                      <mat-error
                        *ngIf="(submitted || f.name.touched) && f.name.errors"
                      >
                        <small
                          class="text-danger"
                          *ngIf="f.name.errors.required"
                          >{{ staticText?.rfx.preview_section.opp_name }} is
                          required</small
                        >
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-md-6 mb-1">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label class="label-design">{{
                        staticText?.rfx.preview_section.opp_type
                      }}</mat-label>
                      <input
                        matInput
                        [matAutocomplete]="auto"
                        type="text"
                        formControlName="opportunity"
                        id="opportunity"
                      />
                      <mat-icon id="search" matSuffix class="muted"
                        >search</mat-icon
                      >
                      <mat-autocomplete
                        #auto="matAutocomplete"
                        [displayWith]="displayFnName"
                      >
                        <mat-option
                          *ngFor="let option of filterOptionType | async"
                          [value]="option"
                        >
                          {{ option.name }}
                        </mat-option>
                      </mat-autocomplete>
                      <mat-error
                        *ngIf="
                          (submitted || f.opportunity.touched) &&
                          f.opportunity.errors
                        "
                      >
                        <small
                          class="text-danger"
                          *ngIf="f.opportunity.errors.required"
                          >{{ staticText?.rfx.preview_section.opp_type }} is
                          required</small
                        >
                        <small
                          class="text-danger"
                          *ngIf="f.opportunity.errors.optionError"
                          >{{
                            staticText?.common?.autocomplete_option_error
                          }}</small
                        >
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-md-6 mb-1">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label class="label-design">{{
                        staticText?.rfx.preview_section.portfolio
                      }}</mat-label>
                      <input
                        matInput
                        [matAutocomplete]="auto1"
                        type="text"
                        formControlName="portfolio"
                        id="portfolio"
                      />
                      <mat-icon matSuffix class="muted">search</mat-icon>
                      <mat-autocomplete
                        #auto1="matAutocomplete"
                        [displayWith]="displayFnName"
                      >
                        <mat-option
                          *ngFor="let option of filterOptionPortfolio | async"
                          [value]="option"
                          (onSelectionChange)="
                            onSelectionChangePortfolio($event)
                          "
                        >
                          {{ option.name }}
                        </mat-option>
                      </mat-autocomplete>
                      <mat-error
                        *ngIf="
                          (submitted || f.portfolio.touched) &&
                          f.portfolio.errors
                        "
                      >
                        <small
                          class="text-danger"
                          *ngIf="f.portfolio.errors.required"
                          >{{ staticText?.rfx.preview_section.portfolio }} is
                          required</small
                        >
                        <small
                          class="text-danger"
                          *ngIf="f.portfolio.errors.optionError"
                          >{{
                            staticText?.common?.autocomplete_option_error
                          }}</small
                        >
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-md-6 mb-1">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label class="label-design">{{
                        staticText?.rfx.preview_section.sub_portfolio
                      }}</mat-label>
                      <input
                        matInput
                        [matAutocomplete]="auto2"
                        type="subPortfolio"
                        formControlName="subPortfolio"
                        id="4"
                      />
                      <mat-icon matSuffix class="muted">search</mat-icon>
                      <mat-autocomplete
                        #auto2="matAutocomplete"
                        [displayWith]="displayFnName"
                      >
                        <mat-option
                          *ngFor="
                            let option of filterOptionSubPortfolio | async
                          "
                          [value]="option"
                          (onSelectionChange)="
                            onSelectionChangeSubportPortfolio($event)
                          "
                        >
                          {{ option.name }}
                        </mat-option>
                      </mat-autocomplete>
                      <mat-error
                        *ngIf="
                          (submitted || f.subPortfolio.touched) &&
                          f.subPortfolio.errors
                        "
                      >
                        <small
                          class="text-danger"
                          *ngIf="f.subPortfolio.errors.required"
                          >{{
                            staticText?.rfx.preview_section.sub_portfolio
                          }}
                          is required</small
                        >
                        <small
                          class="text-danger"
                          *ngIf="f.subPortfolio.errors.optionError"
                          >{{
                            staticText?.common?.autocomplete_option_error
                          }}</small
                        >
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-md-6 mb-1">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label class="label-design">{{
                        staticText?.rfx.preview_section.program
                      }}</mat-label>
                      <input
                        matInput
                        [matAutocomplete]="auto5"
                        type="text"
                        formControlName="program"
                        id="4"
                      />
                      <mat-icon matSuffix class="muted">search</mat-icon>
                      <mat-autocomplete
                        #auto5="matAutocomplete"
                        [displayWith]="displayFnName"
                      >
                        <mat-option
                          *ngFor="let option of filterOptionProgram | async"
                          [value]="option"
                        >
                          {{ option.name }}
                        </mat-option>
                      </mat-autocomplete>
                      <mat-error
                        *ngIf="
                          (submitted || f.program.touched) && f.program.errors
                        "
                      >
                        <small
                          class="text-danger"
                          *ngIf="f.program.errors.required"
                          >{{ staticText?.rfx.preview_section.program }} is
                          required</small
                        >
                        <small
                          class="text-danger"
                          *ngIf="f.program.errors.optionError"
                          >{{
                            staticText?.common?.autocomplete_option_error
                          }}</small
                        >
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>

                <div *ngIf="editShow">
                  <ng-container *ngFor="let child of subPortFolioChild">
                    <div class="mb-2">
                      <mat-form-field appearance="outline" class="w-100">
                        <mat-label class="label-design">{{
                          child.name
                        }}</mat-label>
                        <input
                          matInput
                          [matAutocomplete]="auto3"
                          type="text"
                          formControlName="{{ child.key }}"
                          id="childkey"
                        />
                        <mat-icon matSuffix class="muted">search</mat-icon>
                        <mat-autocomplete
                          #auto3="matAutocomplete"
                          [displayWith]="displayFn"
                        >
                          <mat-option
                            *ngFor="let option of child.arrList"
                            [value]="option"
                            (onSelectionChange)="
                              onSelectionChangeSubportPortfolioChild($event)
                            "
                          >
                            {{ option.name }}
                          </mat-option>
                        </mat-autocomplete>
                        <mat-error
                          *ngIf="
                            (submitted || f[child.key].touched) &&
                            f[child.key].errors
                          "
                        >
                          <small
                            class="text-danger"
                            *ngIf="f[child.key].errors.required"
                            >{{ child.name }} is required</small
                          >
                          <small
                            class="text-danger"
                            *ngIf="f[child.key].errors.optionError"
                            >{{
                              staticText?.common?.autocomplete_option_error
                            }}</small
                          >
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </ng-container>
                  <div class="row mt-2">
                    <div class="col-md-6 mb-1">
                      <mat-form-field appearance="outline" class="w-100">
                        <mat-label class="label-design">{{
                          staticText?.rfx.preview_section.owners
                        }}</mat-label>
                        <input
                          matInput
                          type="text"
                          id="owners"
                          readonly
                          formControlName="owners"
                        />
                      </mat-form-field>
                    </div>
                    <!-- <div class="col-md-6 mb-1">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label
                                                    class="label-design">{{staticText?.rfx.preview_section.spoc}}</mat-label>
                                                <input matInput id='spoc' [matAutocomplete]="auto6" type="text"
                                                    formControlName="spoc">
                                                <mat-icon matSuffix class="muted">search</mat-icon>
                                                <mat-autocomplete #auto6="matAutocomplete"
                                                    [displayWith]="displayFnName">
                                                    <mat-option *ngFor="let option of filterOptionSpoc | async"
                                                        [value]="option">
                                                        {{option.name}}
                                                    </mat-option>
                                                </mat-autocomplete>
                                                <mat-error *ngIf="(submitted|| f.spoc.touched) && f.spoc.errors">
                                                    <small class="text-danger"
                                                        *ngIf="f.spoc.errors.required">{{staticText?.rfx.preview_section.spoc}}
                                                        is
                                                        required</small>
                                                    <small class="text-danger"
                                                        *ngIf="f.spoc.errors.optionError">{{staticText?.common?.autocomplete_option_error}}</small>
                                                </mat-error>
                                            </mat-form-field>
                                        </div> -->
                  </div>
                </div>

                <div class="row mt-2">
                  <div class="col-md-12 mb-1" *ngIf="disDescription">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label
                        for="exampleFormControl"
                        class="form-label label-design"
                      >
                        {{ disDescription }}
                      </mat-label>
                      <textarea
                        id="9"
                        autosize
                        matInput
                        formControlName="opportunity_brief"
                        class="fw-500"
                        [ngClass]="{
                          'is-invalid': submitted && f.opportunity_brief?.errors
                        }"
                      ></textarea>
                    </mat-form-field>
                    <mat-error *ngIf="submitted && f.opportunity_brief?.errors">
                      <small
                        class="text-danger"
                        *ngIf="f.opportunity_brief?.errors.required"
                        >{{ disDescription }} is required</small
                      >
                    </mat-error>
                  </div>
                  <div
                    class="col-md-6"
                    *ngFor="let option of filterSelectedItems()"
                  >
                    <div
                      class="row my-1"
                      *ngIf="
                        option.field_type == 'drop-down' &&
                        option?.display_name !== 'Program'
                      "
                    >
                      <div class="col-md-11">
                        <mat-form-field appearance="outline" class="w-100">
                          <mat-label class="label-design"
                            >{{ option.display_name }}
                          </mat-label>
                          <mat-select [formControlName]="option.field_name">
                            <mat-option
                              *ngFor="let item of option.field_values"
                              [value]="item.id"
                            >
                              {{ item.display_name }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                        <div *ngIf="submitted && f[option.field_name]?.errors">
                          <small
                            class="text-danger"
                            *ngIf="f[option.field_name]?.errors.required"
                            >{{ option.display_name }} is required</small
                          >
                        </div>
                      </div>

                      <div class="col-md-1 my-auto p-0">
                        <div class="d-flex align-items-end">
                          <span
                            id="cursor_pointer"
                            class="cursor-pointer"
                            (click)="removeColumn(option)"
                            *ngIf="!disableFields"
                          >
                            <img
                              src="./assets/images/Group 8814.svg"
                              style="width: 16px"
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div>
            <form [formGroup]="profileForm">
              <div class="row">
                <div class="col-md-6 mt-2" *ngIf="data.category">
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label class="label-design"
                      >{{ data.disCategory }}
                    </mat-label>
                    <mat-select
                      appearance="filled"
                      class="w-100"
                      formControlName="category"
                    >
                      <mat-option
                        *ngFor="let category of categoryList"
                        [value]="category.id"
                        >{{ category.display_name }}</mat-option
                      >
                    </mat-select>
                  </mat-form-field>
                  <mat-error *ngIf="profSubmitted && p.category?.errors">
                    <small
                      class="text-danger"
                      *ngIf="p.category?.errors.required"
                      >{{ data.disCategory }} is required</small
                    >
                  </mat-error>
                </div>
                <div class="col-md-6 my-1" *ngIf="data.classification">
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label class="label-design"
                      >{{ data.disClassification }}
                    </mat-label>
                    <mat-select
                      appearance="filled"
                      class="w-100"
                      formControlName="classification"
                    >
                      <mat-option
                        *ngFor="let i of clasificationList"
                        [value]="i.id"
                      >
                        {{ i.display_name }}</mat-option
                      >
                    </mat-select>
                  </mat-form-field>
                  <mat-error *ngIf="profSubmitted && p.classification?.errors">
                    <small
                      class="text-danger"
                      *ngIf="p.classification?.errors.required"
                      >{{ data.disClassification }} is required</small
                    >
                  </mat-error>
                </div>
                <div class="col-md-6 my-1" *ngIf="data.triggers">
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label class="label-design"
                      >{{ data.disTriggers }}
                    </mat-label>
                    <mat-select
                      appearance="filled"
                      class="w-100"
                      formControlName="triggers"
                    >
                      <mat-option
                        *ngFor="let trigger of triggerList"
                        [value]="trigger.id"
                      >
                        {{ trigger.display_name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-error *ngIf="profSubmitted && p.triggers?.errors">
                    <small
                      class="text-danger"
                      *ngIf="p.triggers?.errors.required"
                      >{{ data.disTriggers }} is required</small
                    >
                  </mat-error>
                </div>
                <div class="col-md-6 my-1" *ngIf="data.work">
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label class="label-design">{{
                      data.disWork
                    }}</mat-label>
                    <mat-select
                      appearance="filled"
                      class="w-100"
                      formControlName="work"
                    >
                      <mat-option
                        *ngFor="let work of workList"
                        [value]="work.id"
                      >
                        {{ work.display_name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-error *ngIf="profSubmitted && p.work?.errors">
                    <small class="text-danger" *ngIf="p.work?.errors.required"
                      >{{ data.disWork }} is required</small
                    >
                  </mat-error>
                </div>
                <div *ngIf="data.fundingStatus">
                  <div class="row">
                    <div class="col-md-6">
                      <label class="fs-16 headLabel">{{
                        data.disfundingStatus
                      }}</label
                      ><br />

                      <mat-radio-group
                        aria-label="Select an option"
                        class="mx-3"
                        formControlName="fundingStatus"
                      >
                        <mat-radio-button class="fs-6 fw-500" [value]="0"
                          >{{ staticText?.rfx.setup.funded }}
                        </mat-radio-button>
                        <mat-radio-button class="fs-6 fw-500" [value]="1"
                          >{{ staticText?.rfx.setup.not_funded }}
                        </mat-radio-button>
                      </mat-radio-group>
                      <mat-error
                        *ngIf="profSubmitted && p.fundingStatus?.errors"
                      >
                        <small
                          class="text-danger"
                          *ngIf="p.fundingStatus?.errors.required"
                          >{{ data.disfundingStatus }} is required</small
                        >
                      </mat-error>
                    </div>
                    <div class="col-md-6 my-4">
                      <mat-checkbox
                        class="example-margin"
                        [checked]="checkedFlag"
                        (change)="checkAddParameterFlag($event)"
                        >Add Custom Parameter</mat-checkbox
                      >
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div *ngIf="checkedFlag">
            <form [formGroup]="createParameterForm">
              <div class="custom-table">
                <table class="table table-bordered">
                  <thead style="position: sticky; top: 0; z-index: 1">
                    <tr>
                      <th class="fixed-header" scope="col">#</th>
                      <th class="fixed-header" scope="col">Parameter</th>
                      <th class="fixed-header" scope="col">Value</th>
                      <th class="fixed-header text-center col-2" scope="col">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="
                        let option of createParameterForm.controls;
                        let i = index
                      "
                    >
                      <td class="align-middle">{{ i + 1 }}</td>
                      <td class="align-middle">
                        <!-- <mat-form-field class="example-full-width" appearance="outline">
                                                    <mat-label class="label-design"></mat-label>
                                                    <input type="text" matInput  status="basic" 
                                                     [formControl]="option.value.custom_parameter" [ngModelOptions]="{standalone: true}" name="release_name">
                                                </mat-form-field> -->
                        <mat-form-field
                          class="example-full-width"
                          style="width: 100%"
                          appearance="outline"
                        >
                          <mat-label></mat-label>
                          <input
                            type="text"
                            placeholder="Select or Create Parameter"
                            matInput
                            [formControl]="option.value.custom_parameter"
                            [matAutocomplete]="autoFunctiontest"
                            (ngModelChange)="searchFun(i)"
                            (input)="onInputChanged($event.target.value)"
                            name="custom_parameter"
                          />
                          <mat-autocomplete
                            #autoFunctiontest="matAutocomplete"
                            (optionSelected)="
                              onOptionSelectionChanged($event, i)
                            "
                            [displayWith]="getFunctionName.bind(this)"
                          >
                            <mat-option
                              *ngFor="let item of getParameterList(i)"
                              [value]="item.id"
                              style="font-size: 0.8rem; height: 30px"
                            >
                              {{ item.display_name }}
                              <span class="thumbs">
                                <mat-icon style="transform: scale(0.6)">
                                  thumb_up
                                </mat-icon>
                              </span>
                            </mat-option>
                            <!-- <mat-option *ngIf="getParameterList(i).length == 0" [value]="inputValue" style="font-size: 0.8rem; height: 30px;">
                                                            Create New: <span>"{{option.value.custom_parameter.value}}"</span>
                                                        </mat-option> -->
                          </mat-autocomplete>
                        </mat-form-field>
                      </td>
                      <td class="align-middle">
                        <mat-form-field
                          class="example-full-width"
                          appearance="outline"
                        >
                          <mat-label class="label-design"></mat-label>
                          <input
                            type="text"
                            matInput
                            status="basic"
                            [formControl]="option.value.custom_parameter_value"
                            [ngModelOptions]="{ standalone: true }"
                            name="release_name"
                          />
                        </mat-form-field>
                      </td>
                      <td class="align-middle text-center">
                        <div
                          style="
                            display: flex;
                            align-items: center;
                            justify-content: space-around;
                          "
                        >
                          <span
                            class="cursor-pointer"
                            (click)="removeParameter(i)"
                            *ngIf="!disableFields"
                          >
                            <span
                              class="cursor-pointer"
                              (click)="removeFunctionColumn(option)"
                              *ngIf="!disableFields"
                            >
                              <img
                                src="./assets/images/comment-delete.svg"
                                style="width: 16px"
                              />
                            </span>
                          </span>

                          <span
                            class="cursor-pointer"
                            (click)="addFunctionItems(i)"
                            *ngIf="
                              !disableFields &&
                              createParameterForm.controls.length - 1 === i
                            "
                          >
                            <mat-icon
                              aria-hidden="false"
                              aria-label="material-icons-filled"
                              class="cursor-pointer fs-5"
                              style="margin-top: 10px"
                              >add
                            </mat-icon>
                          </span>
                        </div>
                      </td>
                    </tr>
                    <tr *ngIf="createParameterForm?.controls?.length <= 0">
                      <td></td>
                      <td></td>
                      <td></td>
                      <td class="w-25">
                        <a class="add-btton" (click)="addFunctionItems()">
                          <mat-icon
                            aria-hidden="false"
                            aria-label="material-icons-filled"
                            class="cursor-pointer fs-5"
                            >add
                          </mat-icon>
                          Add Parameter
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </form>
          </div>
        </div>

        <mat-card-actions
          class="border-top foot-position mb-0 py-3 footer-align pr-2"
        >
          <div class="row" *ngIf="showNext">
            <div class="col-md-12 text-right">
              <button
                id="setupNext"
                mat-flat-button
                mat-button
                colr="primary"
                class="bg-primary text-white"
                matStepperNext
                (click)="next('Setup')"
              >
                <span id="next_btn1234" class="m-0 ps-2">
                  {{ staticText?.buttons?.next_btn }}
                </span>
                <mat-icon class="">chevron_right</mat-icon>
              </button>
            </div>
          </div>
        </mat-card-actions>
      </mat-step>

      <!-- <mat-step label="{{staticText?.rfx.setup?.profile}}"
                [stepControl]="profileForm?.disabled ? '':profileForm" [state]="stepStates[1]">
                <ng-template matStepLabel>
                    <p class="mat-design" matTooltip="{{staticText?.rfx.setup?.profile}}">
                        {{staticText?.rfx.setup?.profile}}</p>
                </ng-template>
                <div class="row custom-height">
                    <div class="col-md-12 p-0">
                        <form [formGroup]="profileForm">
                            <div class="mt-2" *ngIf="data.category">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label class="label-design">{{data.disCategory}}
                                    </mat-label>
                                    <mat-select appearance="filled" class="w-100"
                                        formControlName="category">
                                        <mat-option *ngFor="let category of categoryList"
                                            [value]="category.id">{{category.display_name}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-error *ngIf="profSubmitted && p.category?.errors">
                                    <small class="text-danger"
                                        *ngIf="p.category?.errors.required">{{data.disCategory}} is
                                        required</small>
                                </mat-error>
                            </div>
                            <div class="my-1" *ngIf="data.classification">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label class="label-design">{{data.disClassification}}
                                    </mat-label>
                                    <mat-select appearance="filled" class="w-100"
                                        formControlName="classification">
                                        <mat-option *ngFor="let i of clasificationList" [value]="i.id">
                                            {{i.display_name}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-error *ngIf="profSubmitted && p.classification?.errors">
                                    <small class="text-danger"
                                        *ngIf="p.classification?.errors.required">{{data.disClassification}}
                                        is
                                        required</small>
                                </mat-error>
                            </div>
                            <div class="my-1" *ngIf="data.triggers">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label class="label-design">{{data.disTriggers}}
                                    </mat-label>
                                    <mat-select appearance="filled" class="w-100"
                                        formControlName="triggers">
                                        <mat-option *ngFor="let trigger of triggerList"
                                            [value]="trigger.id">
                                            {{trigger.display_name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-error *ngIf="profSubmitted && p.triggers?.errors">
                                    <small class="text-danger"
                                        *ngIf="p.triggers?.errors.required">{{data.disTriggers}} is
                                        required</small>
                                </mat-error>
                            </div>
                            <div class="my-1" *ngIf="data.work">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label class="label-design">{{data.disWork}}</mat-label>
                                    <mat-select appearance="filled" class="w-100" formControlName="work">
                                        <mat-option *ngFor="let work of workList" [value]="work.id">
                                            {{work.display_name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-error *ngIf="profSubmitted && p.work?.errors">
                                    <small class="text-danger"
                                        *ngIf="p.work?.errors.required">{{data.disWork}} is
                                        required</small>
                                </mat-error>
                            </div>
                            <div class="mb-1" *ngIf="data.fundingStatus">
                                <div class="row">
                                    <div class="col-12 p-0 mx-3">
                                        <label class="fs-16 headLabel">{{data.disfundingStatus}}</label>
                                    </div>
                                    <div class="col-12 p-0">
                                        <mat-radio-group aria-label="Select an option" class="mx-3"
                                            formControlName="fundingStatus">
                                            <mat-radio-button class="fs-6 fw-500"
                                                [value]='0'>{{staticText?.rfx.setup.funded}}
                                            </mat-radio-button>
                                            <mat-radio-button class="fs-6  fw-500"
                                                [value]='1'>{{staticText?.rfx.setup.not_funded}}
                                            </mat-radio-button>
                                        </mat-radio-group>
                                        <mat-error *ngIf="profSubmitted && p.fundingStatus?.errors">
                                            <small class="text-danger"
                                                *ngIf="p.fundingStatus?.errors.required">{{data.disfundingStatus}}
                                                is
                                                required</small>
                                        </mat-error>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <mat-card-actions class="border-top mb-0 py-3 foot-position  footer-align">
                    <div class="row" *ngIf="showNext">
                        <div class="col-md-6">
                            <button id='profileBack' mat-button
                                class="primary border button-hover text-white fs-16" matStepperPrevious>
                                <mat-icon class="">chevron_left</mat-icon>
                                <span id="buttons_back78" class="m-0 pe-2">
                                    {{staticText?.buttons?.back}}
                                </span>
                            </button>
                        </div>
                        <div class="col-md-6 text-right">
                            <button id='profileNext' mat-button class="next-color text-white fs-16"
                                matStepperNext (click)="next('Profile')">
                                <span class="m-0 ps-2">
                                    {{staticText?.buttons?.next_btn}}
                                </span>
                                <mat-icon class="">chevron_right</mat-icon>
                            </button>
                        </div>
                    </div>
                </mat-card-actions>

            </mat-step> -->

      <mat-step
        label="{{ staticText?.okr_heatmap.krs }}"
        [state]="stepStates[2]"
      >
        <ng-template matStepLabel>
          <p class="mat-design" matTooltip="{{ staticText?.okr_heatmap.krs }}">
            {{ staticText?.okr_heatmap.krs }}
          </p>
        </ng-template>
        <div class="d-flex align-items-center">
          <mat-icon class="text-warning cursor-pointer fs-5"> star</mat-icon>
          <span> - Recommended </span>
        </div>
        <div style="height: calc(100vh - 450px); overflow: scroll">
          <app-selection-tree-listing
            [(items)]="okrArray"
            selectedOkr="selectedOkr"
            [disabled]="disableFields"
            [tree]="'program'"
            [context]="context"
          ></app-selection-tree-listing>
        </div>
        <mat-card-actions
          class="border-top mb-0 py-3 px-2 foot-position footer-align"
        >
          <div class="row" *ngIf="showNext">
            <div class="col-md-6">
              <button
                id="KeyResultsBack"
                mat-button
                mat-stroked-button
                color="primary"
                matStepperPrevious
              >
                <mat-icon class="">chevron_left</mat-icon>
                <span class="m-0 pe-2">
                  {{ staticText?.buttons.back }}
                </span>
              </button>
            </div>
            <div class="col-md-6 text-right">
              <button
                id="KeyResultsNext"
                mat-button
                mat-flat-button
                class="bg-primary text-white"
                color="primary"
                matStepperNext
                (click)="next('Key Results')"
              >
                <span class="m-0 ps-2">
                  {{ staticText?.buttons?.next_btn }}
                </span>
                <mat-icon class="">chevron_right</mat-icon>
              </button>
            </div>
          </div>
        </mat-card-actions>
      </mat-step>

      <!-- <mat-step label="{{staticText?.rfx.setup.team}}" [stepControl]="teamForm?.disabled ?'':teamForm"
                [state]="stepStates[3]">
                <ng-template matStepLabel>
                    <p class="mat-design" matTooltip="{{staticText?.rfx.setup.team}}">
                        {{staticText?.rfx.setup.team}} </p>
                </ng-template>
                <div class="custom-height">
                    <form [formGroup]="teamCreateForm">
                        <div class="custom-table">
                            <table class="table table-bordered">
                                <thead style="position: sticky;top: 0; z-index: 1">
                                    <tr>
                                        <th class="fixed-header" scope="col">#</th>
                                        <th class="fixed-header" scope="col" class="w-25">Function</th>
                                        <th class="fixed-header" scope="col">Team</th>
                                        <th class="fixed-header text-center col-2" scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let option of teamCreateForm.controls; let i = index">
                                        <td class="align-middle">{{i+1}}</td>
                                        <td class="align-middle w-25">
                                            <mat-form-field class="example-full-width" style="width: 100%" appearance="outline">
                                                <mat-label></mat-label>
                                                <input type="text"
                                                    placeholder="Select or Create Function" matInput
                                                    [formControl]="option.value.function"
                                                    [matAutocomplete]="autoFunction"
                                                    (ngModelChange)="searchFun(i)"
                                                    (input)="onInputChanged($event.target.value)"
                                                    name="function_name">
                                                <mat-autocomplete #autoFunction="matAutocomplete" (optionSelected)="onOptionSelectionChanged($event)"
                                                [displayWith]="getFunctionName.bind(this)">
                                                    <mat-option *ngFor="let item of getFunctionList(i)" [value]="item.id" style="font-size: 0.8rem; height: 30px;">
                                                        {{item.display_name}} 
                                                        <span *ngIf="!item.is_custom" class="thumbs"> 
                                                            <mat-icon style="transform: scale(0.6);">
                                                                thumb_up
                                                            </mat-icon> 
                                                        </span>
                                                    </mat-option>
                                                    <mat-option *ngIf="getFunctionList(i).length == 0" [value]="inputValue" style="font-size: 0.8rem; height: 30px;">
                                                        Create New: <span (click)="addNewFunction(i, option.value.function.value)">"{{option.value.function.value}}"</span>
                                                    </mat-option>
                                                </mat-autocomplete>
                                            </mat-form-field>
                                        </td>
                                        <td class="align-middle w-75">
                                            <mat-form-field class="example-chip-list" appearance="outline">
                                                <mat-chip-list #chipList aria-label="Members selection">
                                                <mat-chip
                                                    *ngFor="let members of option?.value.team_members.value"
                                                    [selectable]="selectable"
                                                    [removable]="removable && !disableFields"
                                                    (removed)="removeTeam(members, i)">
                                                    {{members.name || members.full_name}}
                                                    <mat-icon matChipRemove *ngIf="removable && !disableFields">cancel</mat-icon>
                                                </mat-chip>
                                                <input
                                                    placeholder="Team Members.."
                                                    *ngIf="!disableFields"
                                                    [formControl]="option.value.input"
                                                    [matAutocomplete]="auto"
                                                    [matChipInputFor]="chipList"
                                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                                    [matChipInputAddOnBlur]="addOnBlur"
                                                    (ngModelChange)="searchTeam(i)"
                                                    #operationTrigger="matAutocompleteTrigger"
                                                    name="team_name"
                                                    (click)="operationTrigger.openPanel()">
                                                </mat-chip-list>
                                                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedTeam($event, i)">
                                                    <mat-option *ngFor="let team of filteredTeamListFilter(i)" [value]="team" style="font-size: 0.8rem; height: 30px;">
                                                        {{team.name}}
                                                    </mat-option>
                                                </mat-autocomplete>
                                            </mat-form-field>
                                        </td>
                                        <td class="align-middle text-center">
                                            <div style="display: flex; align-items: center; justify-content: space-around;">
                                                <span class="cursor-pointer" (click)="removeFunction(i)"
                                                *ngIf="!disableFields">
                                                    <span class="cursor-pointer"
                                                        (click)="removeFunctionColumn(option)"
                                                        *ngIf="!disableFields">
                                                        <img src="./assets/images/comment-delete.svg"
                                                            style="width:16px;">
                                                    </span>
                                                </span>

                                                <span class="cursor-pointer" (click)="addFunctionItems(i)"
                                                *ngIf="!disableFields && teamCreateForm.controls.length - 1 === i">
                                                <mat-icon aria-hidden="false" aria-label="material-icons-filled"
                                                        class="cursor-pointer fs-5" style="margin-top: 10px;">add
                                                    </mat-icon>
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="teamCreateForm?.controls?.length <= 0">
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td class="w-25">
                                            <a class="add-btton"
                                                (click)="addFunctionItems()" *ngIf="!disableFields">
                                                <mat-icon aria-hidden="false" aria-label="material-icons-filled"
                                                    class="cursor-pointer fs-5">add
                                                </mat-icon>
                                                Add Function
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div>
                               
                            </div>
                        </div>
                    </form>
                </div>
                <mat-card-actions class="mb-0 foot-position  footer-align">
                    <div class="row" *ngIf="showNext">
                        <div class="col-md-6">
                            <button id='teamBack' mat-button
                                class="primary border button-hover fs-16 text-white" matStepperPrevious>
                                <mat-icon class="">chevron_left</mat-icon>
                                <span class="m-0 pe-2">
                                    {{staticText?.buttons.back}}
                                </span>
                            </button>
                        </div>
                        <div class="col-md-6 text-right">
                            <button id='teamNext' mat-button class="fs-16 next-color text-white"
                                matStepperNext (click)="next('Team')">
                                <span class="m-0 ps-2">
                                    {{staticText?.buttons?.next_btn}}
                                </span>
                                <mat-icon class="">chevron_right</mat-icon>
                            </button>
                        </div>
                    </div>
                </mat-card-actions>

            </mat-step> -->

      <!-- <mat-step label="OU/GDP" [state]="stepStates[4]">
                <ng-template matStepLabel>
                    <p class="mat-design" matTooltip="OU/GDP" matTooltipClass="example-tooltip">
                        {{staticText?.rfx.setup.gdps}}</p>
                </ng-template>                
                <div class="customs-height">
                    <form [formGroup]="gdpCreateForm">
                        <div class="custom-table">
                            <table class="table table-bordered">
                                <thead style="position: sticky;top: 0; z-index: 1">
                                    <tr>
                                        <th class="fixed-header" scope="col">#</th>
                                        <th class="fixed-header" scope="col" class="w-75">OU/GDP</th>
                                        <th class="fixed-header text-center col-2" scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let option of gdpCreateForm.controls; let i = index">
                                        <td class="align-middle">{{i+1}}</td>
                                        <td class="align-middle w-75">
                                            <mat-form-field class="example-full-width" style="width: 100%" appearance="outline">
                                                <mat-label></mat-label>
                                                <input type="text"
                                                    placeholder="Select OU/GDP" matInput
                                                    [formControl]="option.value.vendor"
                                                    [matAutocomplete]="autoVendorCom"
                                                    (ngModelChange)="searchVendor(i)"
                                                    (input)="onInputChanged($event.target.value)"
                                                    name="gdp_name">
                                                <mat-autocomplete #autoVendorCom="matAutocomplete" (optionSelected)="onVendorOptionSelectionChanged($event)"
                                                [displayWith]="getVendorName.bind(this)">
                                                    <mat-option *ngFor="let item of getVendors(i)" [value]="item.id" style="font-size: 0.8rem; height: 30px;">
                                                        {{item.name}} 
                                                    </mat-option>
                                                </mat-autocomplete>
                                            </mat-form-field>
                                        </td>
                                        <td class="align-middle">
                                            <div style="display: flex; align-items: center; justify-content: space-around;">
                                                <span class="cursor-pointer" (click)="removeVendor(i)"
                                                *ngIf="!disableFields">
                                                    <span class="cursor-pointer"
                                                        (click)="removeFunctionColumn(option)"
                                                        *ngIf="!disableFields">
                                                        <img src="./assets/images/comment-delete.svg"
                                                            style="width:16px;">
                                                    </span>
                                                </span>

                                                <span class="cursor-pointer" (click)="addDeliveryPartner(i)"
                                                *ngIf="!disableFields && gdpCreateForm.controls.length - 1 === i">
                                                <mat-icon aria-hidden="false" aria-label="material-icons-filled"
                                                        class="cursor-pointer fs-5" style="margin-top: 10px;">add
                                                    </mat-icon>
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="gdpCreateForm?.controls?.length <= 0">
                                        <td></td>
                                        <td></td>
                                        <td class="w-50">
                                            <a class="add-btton"
                                                (click)="addDeliveryPartner()" *ngIf="!disableFields">
                                                <mat-icon aria-hidden="false" aria-label="material-icons-filled"
                                                    class="cursor-pointer fs-5">add
                                                </mat-icon>
                                                Add Delivery Partner
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div>
                               
                            </div>
                        </div>
                    </form>
                </div>
                <mat-card-actions class="mb-0 foot-position  footer-align">
                    <div class="row" *ngIf="showNext">
                        <div class="col-md-6">
                            <button id='GDPsBack' mat-button
                                class="primary border button-hover fs-16 text-white" matStepperPrevious>
                                <mat-icon class="">chevron_left</mat-icon>
                                <span class="m-0 pe-2">
                                    {{staticText?.buttons.back}}
                                </span>
                            </button>
                        </div>
                        <div class="col-md-6 text-right">
                            <button id='GDPsNext' mat-button class="fs-16 next-color text-white"
                                matStepperNext (click)="next('OU/GDP')">
                                <span class="m-0 ps-2">
                                    {{staticText?.buttons?.next_btn}}
                                </span>
                                <mat-icon class="">chevron_right</mat-icon>
                            </button>
                        </div>
                    </div>
                </mat-card-actions>
            </mat-step> -->

      <!-- <mat-step label="Timeline" [state]="stepStates[5]">
                <ng-template matStepLabel>
                    <p class="mat-design" matTooltip="Timeline">
                        {{staticText?.rfx.setup.timeline}}</p>
                </ng-template>
                <div class="custom-height">
                    <form [formGroup]="timeLineCreateForm">
                        <div class="custom-table">
                            <table class="table table-bordered">
                                <thead style="position: sticky;top: 0; z-index: 1">
                                    <tr>
                                        <th class="fixed-header" scope="col">#</th>
                                        <th class="fixed-header" scope="col" class="w-50">Timeline For</th>
                                        <th class="fixed-header" scope="col" class="w-25">Date</th>
                                        <th class="fixed-header text-center col-2" scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let option of timeLineCreateForm.controls; let i = index">
                                        <td class="align-middle">{{i+1}}</td>
                                        <td class="align-middle w-50">
                                            <mat-form-field class="example-full-width" style="width: 100%" appearance="outline">
                                                <mat-label></mat-label>
                                                <input type="text"
                                                    placeholder="Select or Create Timeline" matInput
                                                    [formControl]="option.value.timeline"
                                                    [matAutocomplete]="autoTimeline"
                                                    (ngModelChange)="searchTimeline(i)"
                                                    name="timeline_name">
                                                <mat-autocomplete #autoTimeline="matAutocomplete"
                                                [displayWith]="getTimelineName.bind(this)">
                                                    <mat-option *ngFor="let item of getTimelineList(i)" [value]="item.id" style="font-size: 0.8rem; height: 30px;">
                                                        {{item.display_name}} 
                                                        <span *ngIf="!item.is_custom" class="thumbs"> 
                                                            <mat-icon style="transform: scale(0.6);">
                                                                thumb_up
                                                            </mat-icon> 
                                                        </span>
                                                    </mat-option>
                                                    <mat-option *ngIf="getTimelineList(i).length == 0" [value]="inputValue" style="font-size: 0.8rem; height: 30px;">
                                                        Create New: <span (click)="addNewTimeline(i, option.value.timeline.value)">"{{option.value.timeline.value}}"</span>
                                                    </mat-option>
                                                </mat-autocomplete>
                                            </mat-form-field>
                                        </td>
                                        <td class="align-middle w-25">
                                            <mat-form-field appearance="outline" class="w-100 py-1">
                                                <mat-label class="label-design">{{option.display_name}}
                                                </mat-label>
                                                <input matInput
                                                    [matDatepicker]="picker" readonly class="fw-500"
                                                    [formControl]="option.value.timeline_date" fullWidth
                                                    status="basic" (click)="picker.open()" name="timelinedate_name">
                                                <mat-datepicker-toggle matSuffix [for]="picker">
                                                </mat-datepicker-toggle>
                                                <mat-datepicker #picker>
                                                </mat-datepicker>
                                            </mat-form-field>

                                        </td>
                                        <td class="align-middle text-center">
                                            <div style="display: flex; align-items: center; justify-content: space-around;">
                                                <span class="cursor-pointer" (click)="removeTimeline(i)"
                                                *ngIf="!disableFields">
                                                    <span class="cursor-pointer"
                                                        *ngIf="!disableFields">
                                                        <img src="./assets/images/comment-delete.svg"
                                                            style="width:16px;">
                                                    </span>
                                                </span>

                                                <span class="cursor-pointer" (click)="addTimelineItems(i)"
                                                *ngIf="!disableFields && timeLineCreateForm.controls.length - 1 === i">
                                                <mat-icon aria-hidden="false" aria-label="material-icons-filled"
                                                        class="cursor-pointer fs-5" style="margin-top: 10px;">add
                                                    </mat-icon>
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="timeLineCreateForm?.controls?.length <= 0">
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td class="w-25">
                                            <a class="add-btton"
                                                (click)="addTimelineItems()" *ngIf="!disableFields">
                                                <mat-icon aria-hidden="false" aria-label="material-icons-filled"
                                                    class="cursor-pointer fs-5">add
                                                </mat-icon>
                                                Add Timeline
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div>
                               
                            </div>
                        </div>
                    </form>

                     <div class="row" *ngIf="selectedTimelineItems?.length == 0">
                        <div class="col-md-10 mx-auto align-center">
                            <img src="./assets/images/no-record-found.png" class="no-record">
                        </div>
                        <div class="col-md-6 mx-auto">
                            <p class=" text-center text-muted">{{staticText?.rfx.setup.timeline_note}}</p>
                        </div>
                    </div>
                    <form [formGroup]="timeLineForm">
                        <div class="row my-1">
                            <div *ngFor="let option of selectedTimelineItems; index as i" class="col-md-6">
                                <div class="row">
                                    <div class="col-md-10">
                                        <div class="pe-0">
                                            <mat-form-field appearance="outline" class="w-100 py-1">
                                                <mat-label class="label-design">{{option.display_name}}
                                                </mat-label>
                                                <input id='option_display_name' matInput
                                                    [matDatepicker]="picker" readonly class="fw-500"
                                                    [formControlName]="option.display_name" fullWidth
                                                    status="basic" (click)="picker.open()">
                                                <mat-datepicker-toggle matSuffix [for]="picker">
                                                </mat-datepicker-toggle>
                                                <mat-datepicker #picker>
                                                </mat-datepicker>
                                            </mat-form-field>
                                        </div>
                                        <div *ngIf="timeLineSubmitted && time[option.display_name]?.errors">
                                            <small class="text-danger"
                                                *ngIf="time[option.display_name]?.errors.required">{{option.display_name}}
                                                is required</small>
                                        </div>
                                    </div>
                                    <div class="col my-auto p-0">
                                        <span class="cursor-pointer" (click)="removeTimelineColumn(option)"
                                            *ngIf="!disableFields">
                                            <img src="./assets/images/Group 8814.svg" style="width:16px;">
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form> 
                </div>
                <mat-card-actions class="mb-0 foot-position  footer-align">
                    <div class="row" *ngIf="showNext">
                        <div class="col-md-6">
                            <button id='timelineBack' mat-button
                                class="primary border button-hover fs-16 text-white" matStepperPrevious>
                                <mat-icon class="">chevron_left</mat-icon>
                                <span class="m-0 pe-2">
                                    {{staticText?.buttons.back}}
                                </span>
                            </button>
                        </div>
                        <div class="col-md-6 text-right">
                            <button id='timelineNext' mat-button class="fs-16 next-color text-white"
                                matStepperNext (click)="next('Timeline')">
                                <span>
                                    {{staticText?.rfx.next_section}}
                                </span>
                                <mat-icon class="">chevron_right</mat-icon>
                            </button>
                        </div>
                    </div>
                </mat-card-actions>
            </mat-step> -->
    </mat-stepper>
  </div>
</div>

<div class="col-md-4 side-nav col-12">
  <!-- <div *ngIf="side && stepperTitles.buttonName == 'Key Results' && ! disableFields">
            <div class="help-border helpleft htopborder p-2 mt-3 d-flex justify-content-between border-bottom">
                <p class="fw-500 fs-18 mb-0"> {{staticText?.delivery.okr_log.add_kr}}</p>
                <span (click)="side=false; side1=false; sideHelp=true">
                    <img src="./assets/images/Group 8814.svg">
                </span>
            </div>
            <mat-card class="matcorner1 list-height-KeyResult p-0" style="border-radius: 0 0 0.8rem 0.8rem !important;">
                <div class="container-fluid py-2 px-3">
                    <div class="pt-2 pb-2 field-adjust">
                        <mat-form-field appearance="outline" id="searchInput" class="w-100">
                            <input id='searchText' type="text" matInput class="m-0" [(ngModel)]="searchText"
                                placeholder="Search key results and goals here...">
                            <mat-icon matPrefix class="muted iconPos">search</mat-icon>
                        </mat-form-field>
                    </div>

                    <div class="d-flex flex-column">
                        <div class="recom-scope p-2">
                            <label class="fw-500 fs-16 mb-2 text-black">{{staticText?.rfx.setup.recom_krs}}</label>
                            <app-list [list]="slaveKeyResult" [disableEdit]="disableFields"
                                [patchkeyResult]="slaveKeyResultClone" type="keyList" [filterListKey]="'is_custom'"
                                [filterListKeyValue]="false" [customkeydeleteicon]="true" [searchBoxText]="searchText"
                                [TreecardTagname]="'recommendedGoal'" [TreecardParentTagname]="'recommendedGoalParent'"
                                *ngIf="showAddField" (levelSelected)=addKeyColumn($event) [content]="textContents"
                                (levelClose)="deleteCustomKeyResult($event)">
                            </app-list>
                        </div>
                    </div>

                    <div class="recom-scope p-2 mt-2">
                        <div class="d-flex flex-row justify-content-start align-items-center para-color cursor-pointer pt-2 pb-2"
                            *ngIf="showAddField && !searchText && !disableFields">
                            <mat-icon style="font-size: large;">add</mat-icon>
                            <p class="mb-0" (click)="goAddKey()" *ngxPermissionsOnly="['opportunity.add_keyresults']">
                                {{staticText?.rfx.setup.add_custom_krs}}
                            </p>
                        </div>
                        <div class="d-flex flex-column">
                            <label class="fw-500 fs-16 text-black">{{staticText?.rfx.setup.custom_krs}}</label>
                            <div>
                                <app-list [list]="slaveKeyResult" [disableEdit]="disableFields"
                                    [patchkeyResult]="slaveKeyResultClone" [filterListKey]="'is_custom'"
                                    [filterListKeyValue]="true" [customkeydeleteicon]="true" type="keyList"
                                    [searchBoxText]="searchText" [TreecardTagname]="'customgoal'"
                                    [TreecardParentTagname]="'customparent'" *ngIf="showAddField"
                                    (levelSelected)=addKeyColumn($event) [content]="textContents"
                                    (levelClose)="deleteCustomKeyResult($event)">
                                </app-list>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-card>
        </div> -->

  <!-- <div *ngIf="customKeypanel && !side && side1 && !disableFields">
            <div class="help-border helpleft htopborder p-2 mt-3 d-flex justify-content-between border-bottom">
                <p class="fw-500 fs-18 mb-0"> {{staticText?.rfx.setup.add_custom_krs}}</p>
                <span (click)="side=false; side1=false; sideHelp=true">
                    <img src="./assets/images/Group 8814.svg">
                </span>
            </div>
            <mat-card class="matcorner1 list-height-FormKeyResult p-0"
                style="border-radius: 0 0 0.8rem 0.8rem !important;">
                <div class="container-fluid py-2 px-3">
                    <div class="d-flex flex-column field-adjust">
                        <form [formGroup]="keyForm">
                            <div class="d-flex flex-column">
                                <div>
                                    <mat-form-field appearance="outline" class="w-100 pt-2">
                                        <mat-label
                                            class="label-design">{{staticText?.delivery.okr_log.strategic_goal}}</mat-label>
                                        <input id='goalTitle' matInput [matAutocomplete]="auto4" type="text"
                                            formControlName="goalTitle">
                                        <mat-icon matSuffix class="muted">search</mat-icon>
                                        <mat-autocomplete #auto4="matAutocomplete" [displayWith]="displayFn"
                                            (optionSelected)='goalDesSelect($event.option.value)'>
                                            <mat-option *ngFor="let option of filterOptionTitle | async"
                                                [value]="option">
                                                {{option.goalTitle}}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                    <mat-error *ngIf="keySubmit && g.goalTitle?.errors">
                                        <small class="text-danger text-capitalize"
                                            *ngIf="g.goalTitle?.errors.required">{{staticText?.delivery.okr_log.strategic_goal_err}}</small>
                                    </mat-error>

                                    <mat-form-field class="w-100" appearance="outline">
                                        <mat-label
                                            class="label-design">{{staticText?.delivery.okr_log.strategic_goal_desc}}</mat-label>
                                        <textarea type="text" matInput fullWidth status="basic"
                                            formControlName="goalDescription"></textarea>
                                    </mat-form-field>
                                    <mat-error *ngIf="keySubmit && g.goalDescription?.errors">
                                        <small class="text-danger text-capitalize"
                                            *ngIf="g.goalDescription?.errors.required">{{staticText?.delivery.okr_log.strategic_goal_desc_err}}</small>
                                    </mat-error>
                                    <mat-form-field class="w-100" appearance="outline">
                                        <mat-label
                                            class="label-design">{{staticText?.delivery.okr_log.parent_kr}}</mat-label>
                                        <input id='keyTitle' type="text" matInput fullWidth status="basic"
                                            formControlName="keyTitle" [maxlength]="maxChars">
                                    </mat-form-field>
                                    <mat-error *ngIf="keySubmit && g.keyTitle?.errors">
                                        <small class="text-danger text-capitalize"
                                            *ngIf="g.keyTitle?.errors.required">{{staticText?.delivery.okr_log.kr_err}}</small>
                                    </mat-error>
                                    <mat-form-field class="w-100" appearance="outline">
                                        <mat-label
                                            class="label-design">{{staticText?.delivery.okr_log.parent_kr_desc}}</mat-label>
                                        <textarea type="text" matInput fullWidth status="basic"
                                            formControlName="keyDescription"></textarea>
                                    </mat-form-field>
                                    <mat-error *ngIf="keySubmit && g.keyDescription?.errors">
                                        <small class="text-danger text-capitalize"
                                            *ngIf="g.keyDescription?.errors.required">{{staticText?.delivery.okr_log.kr_desc_err}}</small>
                                    </mat-error>

                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label class="label-design">{{staticText?.delivery.okr_log.primary_owner}}
                                        </mat-label>
                                        <mat-select class="filter-select" formControlName="primaryOwner" required
                                            multiple>
                                            <mat-option *ngFor="let i of teamList" [value]="i">
                                                {{i.name || i.mail}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-error *ngIf="keySubmit && g.primaryOwner?.errors">
                                        <small class="text-danger text-capitalize"
                                            *ngIf="g.primaryOwner?.errors.required">{{staticText?.delivery.okr_log.primary_owner_err}}</small>
                                    </mat-error>

                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label class="label-design">{{staticText?.delivery.okr_log.support_owner}}
                                        </mat-label>
                                        <mat-select class="filter-select" formControlName="secondaryOwner" multiple>
                                            <mat-option *ngFor="let i of teamList" [value]="i">
                                                {{i.name || i.mail}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="pt-2 pb-2">
                                    <p class="text-muted mb-0  cursor-pointer" *ngIf="showPara"
                                        (click)="showSecondaryKey=true; showPara=false">
                                        &plus; {{staticText?.delivery.okr_log.add_secondary_kr}}</p>
                                </div>
                                <div *ngIf="showSecondaryKey">
                                    <mat-form-field class="w-100" appearance="outline">
                                        <mat-label class="label-design">Child Key Result Title</mat-label>
                                        <input id='childKeyTitle' type="text" matInput fullWidth status="basic"
                                            [maxlength]="maxChars" formControlName="childKeyTitle">
                                    </mat-form-field>
                                    <mat-error *ngIf="keySubmit && g.childKeyTitle?.errors">
                                        <small class="text-danger text-capitalize"
                                            *ngIf="g.childKeyTitle?.errors.required">{{staticText?.delivery.okr_log.kr_err}}</small>
                                    </mat-error>
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label class="label-design">{{staticText?.delivery.okr_log.primary_owner}}
                                        </mat-label>
                                        <mat-select class="filter-select" formControlName="primaryChildOwner" required
                                            multiple>
                                            <mat-option *ngFor="let i of teamList" [value]="i">
                                                {{i.name || i.mail}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-error *ngIf="keySubmit && g.primaryChildOwner?.errors">
                                        <small class="text-danger text-capitalize"
                                            *ngIf="g.primaryChildOwner?.errors.required">{{staticText?.delivery.okr_log.primary_owner_err}}</small>
                                    </mat-error>

                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label class="label-design">{{staticText?.delivery.okr_log.support_owner}}
                                        </mat-label>
                                        <mat-select class="filter-select" formControlName="secondaryChildOwner"
                                            multiple>
                                            <mat-option *ngFor="let i of teamList" [value]="i">
                                                {{i.name || i.mail}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                        </form>

                        <div>
                            <button id='addCustomKeyResults' mat-button mat-flat-button
                                class="bg-primary text-white w-100 border my-2" (click)="addCustomKeyResults()"
                                [disabled]="disableFields">{{staticText?.delivery.okr_log.add_new_kr}}
                            </button>
                        </div>
                    </div>
                </div>
            </mat-card>
        </div> -->

  <!-- <div class="" *ngIf="side && stepperTitles.buttonName == 'Function' && !isMemberSelected && !disableFields">
            <div class="help-border helpleft htopborder p-2 mt-3 d-flex justify-content-between border-bottom">
                <p class="fw-500 fs-18 mb-0"> {{staticText?.rfx.setup.functions}}</p>
                <span (click)="side=false; side1=false; sideHelp=true">
                    <img src="./assets/images/Group 8814.svg">
                </span>
            </div>
            <mat-card class="matcorner1 team-height" style="border-radius: 0 0 0.8rem 0.8rem !important;">
                <div class="col-md-12 px-0">
                    <div class="col-md-12 p-0 field-adjust">
                        <mat-form-field appearance="outline" class="w-100" id="searchInput">
                            <input id='searchNew' type="text" matInput class="w-100 m-0" [(ngModel)]="searchText"
                                placeholder="Search function here...">
                            <mat-icon matPrefix class="muted iconPos">search</mat-icon>
                        </mat-form-field>
                    </div>
                </div>
                <div class="recom-scope p-2">
                    <label class="fs-16 mb-2 text-black fw-500">{{staticText?.rfx.setup.rec_funtion}} </label>
                    <app-list [list]="functionList" [disableEdit]="disableFields" type="function"
                        [searchBoxText]="searchText" (levelSelected)=addFunctionColumn($event) [content]="textContents"
                        [search]="search">
                    </app-list>
                </div>
                <div class="recom-scope p-2 mt-2">
                    <div class="" *ngIf="showAddField && !disableFields">
                        <div class="mx-2">
                            <p class="para-color d-flex cursor-pointer mb-1" (click)="goToFunction()"
                                *ngxPermissionsOnly="['opportunity.add_function']"><span>
                                    <mat-icon class="cursor-pointer" style="font-size: large;">add
                                    </mat-icon>
                                </span>{{staticText?.rfx.setup.add_custom_fun}}</p>
                        </div>
                    </div>
                    <label class="fs-16 pt-1 text-black fw-500 mb-1"
                        *ngIf="showAddField">{{staticText?.rfx.setup.custom_fun}}</label>
                    <app-list [list]="customFunctionList" [disableEdit]="disableFields" type="function"
                        [searchBoxText]="searchText" *ngIf="showAddField"
                        (levelSelected)=addCustomFunctionColumn($event) [content]="textContents"
                        (levelClose)=removeFunctionColumn($event) (functionClose)="removeFunction($event)">
                    </app-list>
                </div>
            </mat-card>
        </div> -->

  <!-- <div class="" *ngIf="isMemberSelected && side && stepperTitles.buttonName == 'Function' && !disableFields">
            <div class="help-border helpleft htopborder  p-2 d-flex justify-content-between border-bottom">
                <p class="fs-18 fw-500 mb-0" style="color: black;">{{staticText?.rfx.setup.add_team}}</p>
                <img src="./assets/images/Group 8814.svg" (click)="closeFinancial()" class="cursor-pointer">
            </div>
            <div class="side-content">
                <div class="row mx-1">
                    <mat-card class="matcorner1 member-height" style="border-radius: 0 0 0.8rem 0.8rem !important;">
                        <hr class="hr-style">
                        <div class="col-md-12 py-2 px-0">
                            <div class="col-md-12 p-0 field-adjust">
                                <mat-form-field appearance="outline" class="w-100" id="searchInput">
                                    <input id='searchTextNew1' type="text" matInput class="w-100 m-0"
                                        [(ngModel)]="searchText" placeholder="Search team member here...">
                                    <mat-icon matPrefix class="muted iconPos">search</mat-icon>
                                </mat-form-field>
                            </div>
                        </div>
                        <app-list [list]="teamList" [disableEdit]="disableFields" type="team"
                            [searchBoxText]="searchText" (levelSelected)=addChips($event) [content]="textContents"
                            [search]="search">
                        </app-list>
                    </mat-card>
                </div>
            </div>
        </div> -->

  <!-- <div class="" *ngIf="teamFunction && !side && side1 && !disableFields">

            <div class="help-border helpleft htopborder p-2 mt-3 d-flex justify-content-between border-bottom">
                <p class="fw-500 fs-18 mb-0" style="color: black;"> {{staticText?.rfx.setup.add_fun}}</p>
                <span (click)="side1=false; sideHelp=true">
                    <img src="./assets/images/Group 8814.svg">
                </span>
            </div>
            <div>
                <mat-card class="t-cardborder list-height" style="border-radius: 0 0 0.8rem 0.8rem !important;">
                    <div class="col-md-12 pt-2" *ngIf="errorMsg">
                        <app-tips type="Error" (closetips)="tips = false" tipType="teamTip"></app-tips>
                    </div>
                    <div class="col-md-12 field-adjust p-0">
                        <mat-form-field class="py-2 w-100 pt-2" appearance="outline">
                            <mat-label class="label-design">{{staticText?.rfx.setup.fun_name}}</mat-label>
                            <input id='functionNameNew' type="text" matInput fullWidth status="basic"
                                placeholder="Enter {{staticText?.rfx.setup.fun_name | lowercase}} here..."
                                [(ngModel)]="functionName" [maxlength]="maxChars">
                            <button id='close' mat-button mat-icon-button matSuffix (click)="clearFunctionField()"
                                *ngIf="functionName">
                                <mat-icon class="fs-5">close</mat-icon>
                            </button>
                        </mat-form-field>
                        <div>
                            <button id='addFunction' mat-button mat-flat-button
                                class="next-color text-white w-100 border my-2" (click)="addFunction()"
                                [disabled]="disableFields">{{staticText?.rfx.setup.add_new_fun}}
                            </button>
                        </div>
                    </div>
                </mat-card>
            </div>
        </div> -->

  <!-- <div class="" *ngIf="side && stepperTitles.buttonName == 'Delivery Partner' && !disableFields">

            <div class="help-border helpleft htopborder p-2 mt-3 d-flex justify-content-between border-bottom">
                <p class="fw-500 fs-18 mb-0">{{staticText?.proposal.proposal_overview.add_dp}}</p>
                <span (click)="side=false; side1=false; sideHelp=true">
                    <img src="./assets/images/Group 8814.svg">
                </span>
            </div>
            <mat-card class="matcorner1 list-heights field-adjust" style="border-radius: 0 0 0.8rem 0.8rem !important;">
                <mat-form-field appearance="outline" class="" id="searchInput" class="w-100">
                    <input id='searchText2S' type="text" matInput class=" m-0" [(ngModel)]="searchText"
                        placeholder="Search vendor here...">
                    <mat-icon matPrefix class="muted iconPos">search</mat-icon>
                </mat-form-field>
                <div style="margin:0 -15px;">
                    <app-list [list]="vendorList" type="vendor" [disableEdit]="disableFields"
                        [searchBoxText]="searchText" (levelSelected)=addVendorColumn($event) [content]="textContents"
                        [search]="search">
                    </app-list>
                </div>
            </mat-card>

        </div> -->

  <!-- <div class="" *ngIf="side && stepperTitles.buttonName == 'Timeline' && !disableFields">
            <div class="help-border helpleft htopborder p-2 mt-3 d-flex justify-content-between border-bottom">
                <p class="fw-500 fs-18 mb-0"> {{staticText?.rfx.setup.add_timelines}}</p>
                <span (click)="side=false; side1=false; sideHelp=true">
                    <img src="./assets/images/Group 8814.svg">
                </span>
            </div>
            <mat-card class="matcorner1 list-height" style="border-radius: 0 0 0.8rem 0.8rem !important;">
                <div class="col-md-12 pt-1 px-0">
                    <div class="col-md-12 p-0 field-adjust">
                        <mat-form-field appearance="outline" class="w-100" id="searchInput">
                            <input id='Searchtimelinehere' type="text" matInput class="w-100 m-0"
                                [(ngModel)]="searchText" placeholder="Search timeline here...">
                            <mat-icon matPrefix class="muted iconPos">search</mat-icon>
                        </mat-form-field>
                    </div>
                </div>
                <div class="recom-scope p-2">
                    <label class="py-1 fw-500 fs-16 pt-3">{{staticText?.rfx.setup.recom_timeline}}</label>
                    <app-list [list]="timelineList" type="timeList" [disableEdit]="disableFields"
                        [searchBoxText]="searchText" (levelSelected)="addTimelineColumn($event)"
                        [content]="textContents" [search]="search">
                    </app-list>
                </div>
                <div class="recom-scope p-2 mt-2">
                    <div *ngIf="showAddField && !disableFields">
                        <div class="mx-2">
                            <p class="pt-2 para-color d-flex cursor-pointer mb-1" (click)="goToTimeline()"
                                *ngxPermissionsOnly="['opportunity.add_timeline']"><span>
                                    <mat-icon class="cursor-pointer" style="font-size: large;">add
                                    </mat-icon>
                                </span>{{staticText?.rfx.setup.create_custom_timeline}}</p>
                        </div>
                    </div>
                    <label class="py-1 fw-500 fs-16 mb-1"
                        *ngIf="showAddField">{{staticText?.rfx.setup.custom_timeline}}</label>
                    <app-list [list]="customTimeline" type="timeList" [disableEdit]="disableFields"
                        [searchBoxText]="searchText" *ngIf="showAddField"
                        (levelSelected)=addCustomTimelineColumn($event) [content]="textContents"
                        (levelClose)=deleteCustomTimeline($event)>
                    </app-list>
                </div>
            </mat-card>
        </div> -->

  <!-- <div class="" *ngIf="newTimeline && !side && side1">
            <div class="help-border helpleft htopborder p-2 mt-3 d-flex justify-content-between border-bottom">
                <p class="fs-18 fw-500 mb-0" style="color: black;">{{staticText?.rfx.setup.add_timeline}}
                </p>
                <img src="./assets/images/Group 8814.svg" (click)="side1=false;sideHelp=true" class="cursor-pointer">
            </div>
            <div>
                <mat-card class="t-cardborder list-height" style="border-radius: 0 0 0.8rem 0.8rem !important;">
                    <div class="col-md-12 pt-2" *ngIf="errorMsg">
                        <app-tips type="Error" (closetips)="tips = false" tipType="timelineTip"></app-tips>
                    </div>
                    <div class="col-md-12 field-adjust">
                        <mat-form-field class="py-2 w-100 pt-4" appearance="outline">
                            <mat-label class="label-design">{{staticText?.rfx.setup.timeline_name}}</mat-label>
                            <input id='timelineName' type="text" matInput fullWidth status="basic"
                                [(ngModel)]="timelineName" [maxlength]="maxChars">
                            <button id="clearSearchField" mat-button mat-icon-button matSuffix
                                (click)="clearSearchField()" *ngIf="timelineName">
                                <mat-icon class="fs-5">close</mat-icon>
                            </button>
                        </mat-form-field>
                        <div>
                            <button id='addNewTimeline' mat-button mat-flat-button
                                class="next-color text-white w-100 border my-2" (click)="addNewTimeline()"
                                [disabled]="disableFields">{{staticText?.rfx.setup.add_custom_timeline}}
                            </button>
                        </div>
                    </div>
                </mat-card>
            </div>
        </div> -->
  <!-- <div *ngIf="sideHelp && !side && stepperTitles.title == 'Opportunity Setup'">
            <div class="row container-fluid pt-3 p-0">
                <app-list [list]="items" [type]="'help'"></app-list>
            </div>
        </div> -->

  <!-- <div *ngIf="sideHelp && stepperTitles.title == 'Opportunity Profile'">

            <div class="row container-fluid pt-3 p-0">
                <app-list [list]="items" [type]="'profileHelp'"></app-list>
            </div>

        </div> -->

  <!-- <div *ngIf="sideHelp && !side && stepperTitles.title == 'Opportunity Key Results'">

            <div class="row container-fluid pt-3 p-0">
                <app-list [list]="items" [type]="'keyResultHelp'"></app-list>
            </div>

        </div> -->

  <!-- <div *ngIf="sideHelp && !side && stepperTitles.title == 'Opportunity Team'">

            <div class="row container-fluid pt-3 p-0">
                <app-list [list]="items" [type]="'teamHelp'"></app-list>
            </div>

        </div> -->

  <!-- <div *ngIf="sideHelp && !side && stepperTitles.title == 'Opportunity Empanelled Vendors'">

            <div class="row container-fluid pt-3 p-0">
                <app-list [list]="items" [type]="'vendorHelp'"></app-list>
            </div>

        </div> -->

  <!-- <div *ngIf="sideHelp && !side && stepperTitles.title == 'Opportunity Timeline'">

            <div class="row container-fluid pt-3 p-0">
                <app-list [list]="items" [type]="'timeLineHelp'"></app-list>
            </div>

        </div> -->

  <!-- <div>
            <div class="row container-fluid pt-3 p-0">
                
            </div>
        </div> -->
</div>
