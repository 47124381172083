<div class="row">
  <div class="col-md-12">
    <form [formGroup]="profileForm">
      <div class="mb-3" *ngIf="data.category">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label
            >{{ data.disCategory }}<sup class="text-danger">*</sup></mat-label
          >
          <mat-select
            placeholder="Select from the list"
            appearance="filled"
            class="w-100"
            formControlName="category"
          >
            <mat-option
              *ngFor="let category of categoryList"
              [value]="category.id"
              >{{ category.display_name }}</mat-option
            >
          </mat-select>
        </mat-form-field>
        <mat-error *ngIf="profSumbit && f.category.errors">
          <small class="text-danger" *ngIf="f.category.errors.required"
            >{{ data.disCategory }} is required</small
          >
        </mat-error>
      </div>
      <div class="mb-3" *ngIf="data.classification">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label
            >{{ data.disClassification
            }}<sup class="text-danger">*</sup></mat-label
          >
          <mat-select
            placeholder="Select from the list"
            appearance="filled"
            class="w-100"
            formControlName="classification"
          >
            <mat-option *ngFor="let i of clasificationList" [value]="i.id">{{
              i.display_name
            }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-error *ngIf="profSumbit && f.classification.errors">
          <small class="text-danger" *ngIf="f.classification.errors.required"
            >{{ data.disClassification }} is required</small
          >
        </mat-error>
      </div>
      <div class="mb-3" *ngIf="data.triggers">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label
            >{{ data.disTriggers }}<sup class="text-danger">*</sup></mat-label
          >
          <mat-select
            placeholder="Select from the list"
            appearance="filled"
            class="w-100"
            formControlName="triggers"
          >
            <mat-option
              *ngFor="let trigger of triggerList"
              [value]="trigger.id"
            >
              {{ trigger.display_name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-error *ngIf="profSumbit && f.triggers.errors">
          <small class="text-danger" *ngIf="f.triggers.errors.required"
            >{{ data.disTriggers }} is required</small
          >
        </mat-error>
      </div>
      <div class="mb-3" *ngIf="data.work">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label
            >{{ data.disWork }}<sup class="text-danger">*</sup></mat-label
          >
          <mat-select
            placeholder="Select from the list"
            appearance="filled"
            class="w-100"
            formControlName="work"
          >
            <mat-option *ngFor="let work of workList" [value]="work.id">
              {{ work.display_name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-error *ngIf="profSumbit && f.work.errors">
          <small class="text-danger" *ngIf="f.work.errors.required"
            >{{ data.disWork }} is required</small
          >
        </mat-error>
      </div>
      <div class="mb-3" *ngIf="data.fundingStatus">
        <div class="row">
          <div class="col-12 mb-2 mx-3 text-muted">
            <label class="f-small"
              >{{ data.disfundingStatus
              }}<sup class="text-danger">*</sup></label
            >
          </div>
          <div class="col-12">
            <mat-radio-group
              aria-label="Select an option"
              class="mx-3"
              formControlName="fundingStatus"
            >
              <mat-radio-button class="fs-6" value="true"
                ><small>Funded</small></mat-radio-button
              >
              <mat-radio-button class="fs-6" value="false"
                ><small>Not Funded</small></mat-radio-button
              >
            </mat-radio-group>
            <mat-error *ngIf="profSumbit && f.fundingStatus.errors">
              <small class="text-danger" *ngIf="f.fundingStatus.errors.required"
                >{{ data.disfundingStatus }} is required</small
              >
            </mat-error>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
