import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OpportunityRoutingModule } from './opportunity-routing.module';
import { ProfileComponent } from './profile/profile.component';
import { SetupComponent } from './setup/setup.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatStepperModule } from '@angular/material/stepper';
import { MatRadioModule } from '@angular/material/radio';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AvatarModule } from 'ngx-avatar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { ScopeComponent,FormGroupNameScopePipe } from './scope/scope.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { EvaluationMatrixComponent, FormGroupNamePipe } from './evaluation-matrix/evaluation-matrix.component';
import { MatListModule } from '@angular/material/list';
import { MoreInformationComponent } from './more-information/more-information.component';

import { TermsComponent } from './terms/terms.component';
import { PreviewComponent } from './preview/preview.component';
import { PreviewScopeComponent } from './preview-scope/preview-scope.component';
import { PreviewEvaluationComponent } from './preview-evaluation/preview-evaluation.component';
import { PreviewTermsComponent } from './preview-terms/preview-terms.component';
import { PreviewMoreInfoComponent } from './preview-more-info/preview-more-info.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import {AutosizeModule} from 'ngx-autosize';
import { ToeComponent } from './toe/toe.component';
import { CustomPropertiesComponent } from './custom-properties/custom-properties.component';
import { DistributionComponent } from './distribution/distribution.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@NgModule({
  declarations: [
    ProfileComponent,
    SetupComponent,
    ScopeComponent,
    EvaluationMatrixComponent,
    MoreInformationComponent,
    TermsComponent,
    PreviewComponent,
    PreviewScopeComponent,
    PreviewEvaluationComponent,
    PreviewTermsComponent,
    PreviewMoreInfoComponent,
    FormGroupNamePipe,
    FormGroupNameScopePipe,
    ToeComponent,
    CustomPropertiesComponent,
    DistributionComponent
  ],
  imports: [
    CommonModule,
    OpportunityRoutingModule,
    SharedModule,
    NgxPermissionsModule,
    MatIconModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatStepperModule,
    MatRadioModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatCardModule,
    MatChipsModule,
    NgxDatatableModule,
    AvatarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTooltipModule,
    MatTabsModule,
    MatExpansionModule,
    MatSelectModule,
    MatMenuModule,
    MatDialogModule,
    MatListModule,
    MatCheckboxModule,
    Ng2SearchPipeModule,
    AutosizeModule,
    MatSlideToggleModule
  ],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false }
    },
    {
      provide: MatDialogRef,
      useValue: {}
    },
  ]
})
export class OpportunityModule { }
