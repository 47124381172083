import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { ProgramService } from 'src/app/shared/services/program.service';
import { ToastrService } from 'ngx-toastr';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
import { ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-preview-more-info',
  templateUrl: './preview-more-info.component.html',
  styleUrls: ['./preview-more-info.component.scss'],
  encapsulation:ViewEncapsulation.Emulated
})
export class PreviewMoreInfoComponent implements OnInit {
  staticText: any = (textConfiguration as any).default;
  opportunityId: any;
  selectedItems: any;
  newFileArray: any [] = [];
  @Output() sendSelected = new EventEmitter<{}>();
  exportPayload: any;
  moreInfoModel = true;


  constructor(
    private ps: ProgramService,
    private route: ActivatedRoute,
    private toastrService: ToastrService,
  ) { }

  ngOnInit(): void {
    this.route.parent?.parent?.params.subscribe((res: any) => {
      this.opportunityId = atob(res.id);
      this.getOpportunityById();
    });
  }

  getOpportunityById() {
    this.ps.getOpportunityMoreInfo(this.opportunityId).subscribe((resp: any) => {
      let res = resp?.records[0]


      this.selectedItems = res.more_info;      
      (this.selectedItems || []).forEach((elm: any) => {
        const ext = elm.file.substring(elm.file.lastIndexOf('.') + 1)
        elm.section_details.last_updated_on = moment(elm.section_details.last_updated_on).format('ll');
        const newObj = { file: elm.file, date: elm.section_details.last_updated_on, doc: ext?.toLowerCase(), name: elm.section_details.display_name}; 
        this.newFileArray.push(newObj);
      });   
    }, error => {
      throw error;
    });
  }

  showOptions(e: any) {
    if(e.source.id == 'moreInfoCheck') {
      this.toastrService.warning('Please save the changes');
      this.ps.getOpportunityFormData('more_info').subscribe((res: any) => {
        const sectionId = res.records[0].form_details.id;
        this.exportPayload = {
          section: sectionId,
          export: e.checked
        };
        this.sendSelected.emit(this.exportPayload);
      }, error => {
        throw error;
      });
    }
  }

}
