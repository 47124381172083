<div class="row mb-3">
    <div class="p-0 d-flex">
        <div *ngIf="isRedirectFrom" class="w-auto p-0">
            <h3 class="screen-name" (click)="clickPrevious()">
                <mat-icon id="chevron_left" aria-hidden="false" aria-label="material-icons-filled "
                    class="cursor-pointer" style="font-size: 35px;">chevron_left
                </mat-icon>
            </h3>
        </div>
        <div [ngClass]="isRedirectFrom?'col-9':'col-10'">
            <p id="eval_matrix" class="fw-bold fs-24 mb-0">{{staticText?.rfx?.eval_matrix}}</p>
            <p class="m-0 mb-1" style="font-size: 13px;">{{opportunityInfo?.opportunity_name}} | {{opportunityInfo?.opportunity_number}} |
                {{opportunityInfo?.opportunity_type}} | {{opportunityInfo?.portfolio}} |
                {{opportunityInfo?.subportfolio}}
                | {{opportunityInfo?.program}} | {{opportunityInfo?.owner_name}} |
                {{opportunityInfo?.single_point_of_contact}}
            </p>
            <app-tag [dataObj]="getTagObject(data?.id)" [tagsIds]="data?.tag_ids" *ngIf="data"></app-tag>
        </div>
        <!-- <div class="col-md-2 text-end">
            <button id="more_vert" mat-button [matMenuTriggerFor]="menu">
                <mat-icon id="matMenuTriggerFor" class="m-0">more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button id="showSettings" mat-menu-item class="m-0 fs-6" (click)="showSettings('setting')">
                    {{staticText?.rfx?.settings}}
                </button>
                <button id="rangeGuide" mat-menu-item class="m-0 fs-6" (click)="showSettings('rangeGuide')">
                    {{staticText?.rfx?.range_guide}}
                </button>
            </mat-menu>
        </div> -->
    </div>
</div>
<!-- <div class="row">
                    <div class="col-md-10">
                        <p class="fw-bold fs-24 mb-0">{{staticText?.rfx?.eval_matrix}}</p>
                    </div>
                </div> -->
<div class="row">
    <div class="col-md-11 border-bottom ps-5 setWidth">
    </div>
</div>
<br>
<mat-stepper 
            labelPosition="bottom" [disableRipple]="true" #stepper
             id="setupStep" style="height: 67%;" (selectionChange)="selectedStepper($event)">
            <mat-step label="Scope Framework"  [state]="stepStates[1]">
                <ng-template matStepLabel>
                    <p class="mat-design" matTooltip="Scope Framework">
                        Scope </p>
                </ng-template>
                <div class="row">
                    <div class="col-md-12 scroll">
                        <div class="row  m-0 my-2" *ngFor="let k of groupScope; let j=index">
                            <div class="fw-bold mb-1 col-md-12 ps-2 fs-18" style="letter-spacing: 1px; text-transform: capitalize;">
                                <p id="k_name" class="mb-1">{{k.name}}</p>
                            </div>
                            <div>
                                <div class="fixBorder">
                                    <div [formGroup]="matrixForm">
                                        <div formArrayName="items">
                                            <div class="row my-1 d-flex align-items-center p-0"
                                                *ngFor="let option of k.values; let k=index">
                                                <div class="col-md-6">
                                                    <div class="ps-2 d-flex align-items-center">
                                                        <!-- <img src="assets/images/Scope icon.svg" alt="image" class="pt-3"> -->
                                                        <p class="mb-0">{{k+1}}.</p>
                                                        <p id="option_description" class="mb-0 ps-2 fs-14">
                                                            {{option.description}}</p>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div [formGroupName]="(option|formGroupName:matrixForm.get('items'))" class="row">
                                                        <div class="col-md-7 fs-12 d-flex align-items-center">
                                                            <!-- {{option?.criticality | json}} -->
                                                            <!-- <mat-form-field appearance="outline" class="w-100 form-color"> -->
                                                                <div class="" style="display: flex; align-items: center;  justify-content: center">
                                                                    <mat-label>{{criticalityList[1]?.title}}&nbsp;</mat-label>
                                                                    <mat-slide-toggle [checked]="option?.criticality?.id == getCriticalityId('Mandatory')" (change)="toggle($event, 'matrixForm',j, k)" [disabled]="resSettings?.criticality == false" ></mat-slide-toggle>
                                                                    <mat-label>&nbsp;{{criticalityList[0].title}}</mat-label>
                                                                </div>

                                                                <!-- <mat-radio-group placeholder="Select criticality"
                                                                    formControlName="criticality"  
                                                                    appearance="filled" formControlName="criticality"
                                                                    (selectionChange)="crticalityChange($event)"
                                                                    
                                                                    >
                                                                    <mat-radio-button *ngFor="let item of criticalityList; let i=index"
                                                                        [value]="item.id" >
                                                                        {{item.title}}
                                                                    </mat-radio-button>
                                                                </mat-radio-group> -->
                                                            <!-- </mat-form-field> -->
                                                        </div>
                                                        <div class="col-md-5 text-center d-flex align-items-center">
                                                            <input matInput type="number" matTooltip="Weightage (in %)" placeholder="Weightage (in %)" min="0"
                                                                oninput="this.value = 
                                                                            !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null"
                                                                class="w-75 py-2 rounded border" formControlName="weightage"
                                                                [disabled]="resSettings?.weightage == false"
                                                                [ngClass]="{'select-disabled': resSettings?.weightage == false}"
                                                                (input)="onWeightageChange($event, option)"
                                                                style=" background-color: white;padding: 0 0.4em 0 0.4em;">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <small class="text-danger float-end w-25" *ngIf="k.showValidation">
                                    {{staticText?.rfx?.weight_err}} </small>
                            </div>
                        </div>
                        <div class="row  m-0 my-2" *ngIf="apiResponseReceived&&groupScope?.length == 0">
                            <div class="col-md-10 mx-auto align-center">
                                <img src="./assets/images/no-record-found.png" class="no-record">
                            </div>
                            <div class="col-md-6 mx-auto">
                                <p id="no_results_found" class=" text-center text-muted">
                                    {{staticText?.common.no_results_found}} </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 mx-auto col-12">
                    <div class="row border-top">
                        <div class="col-md-6 mt-3 d-flex justify-content-start">
                            <button id="previous" mat-button mat-stroked-button
                                (click)="previous()">
                                <mat-icon class="">chevron_left</mat-icon>
                                <span id="prev_section" class="m-0 pe-2">
                                    {{staticText?.rfx?.prev_section}}
                                </span>
                            </button>
                        </div>
                        <div class="col-md-6 mt-3 d-flex justify-content-end">
                            <button id="updateEvaluation" mat-button mat-flat-button class="bg-primary text-white" color="primary"
                                (click)="updateEvaluation()">
                                <span id="next_section" class="m-0 ps-2">
                                    {{staticText?.buttons?.next_btn}}
                                </span>
                                <mat-icon class="">chevron_right</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </mat-step>
            <mat-step label="Terms of Engagement Framework"  [state]="stepStates[2]">
                <ng-template matStepLabel>
                    <p class="mat-design" matTooltip="Terms of Engagement Framework">
                        Terms of Engagement</p>
                </ng-template>

                <div class="row">
                    <div class="col-md-12 p-0 scroll" [formGroup]="evaluationTermMatrix">
                        <div class="row  m-0 my-2" >
                            <div *ngFor="let cat of evaluationTermMatrix.controls; let catIndex=index">
                                <div class="fw-bold mb-1 col-md-12 ps-2 fs-18" style="letter-spacing: 1px; text-transform: capitalize;">
                                    <p  class="mb-1">{{cat.value.cat_name}}</p>
                                </div>
                                <div>
                                    <div class="fixBorder">
                                        <div>
                                            <div >
                                                <div *ngFor="let term of cat.value.terms; let termIndex=index">
                                                    <div class="row mb-1 d-flex align-items-center">
                                                        <div class="col-md-6 pe-0">
                                                            <div class="ps-2 d-flex align-items-center">
                                                                <!-- <img src="assets/images/Scope icon.svg" alt="image" class="pt-3"> -->
                                                                <p class="mb-0">{{termIndex+1}}.</p>
                                                                <p id="option_description" class="mb-0 ps-2 fs-14">
                                                                {{term.term_name}}<br>
                                                                    <span *ngIf="term?.comment" style="color:rgb(166, 112, 112)">[ask : {{term?.comment}}]</span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="row">
                                                                <div class="col-md-7 fs-12 pr-1 pl-2 mt-1">
                                                                    <div class="" style="display: flex; align-items: center; justify-content: center">
                                                                        <mat-label>{{criticalityList[1].title}}&nbsp;</mat-label>
                                                                        <mat-slide-toggle [checked]="term.criticality == getCriticalityId('Mandatory')" (change)="toggle($event, 'evaluationTermMatrix',catIndex, termIndex)" [disabled]="resSettings?.criticality == false" ></mat-slide-toggle>
                                                                        <mat-label>&nbsp;{{criticalityList[0].title}}</mat-label>
                                                                    </div>
                                                                    <!-- <mat-form-field appearance="outline" class="form-color"> -->
                                                                    
                                                                        <!-- <mat-radio-group placeholder="Select criticality" 
                                                                             class=" rounded" [(ngModel)]="term.criticality" [ngModelOptions]="{standalone: true}" >
                                                                            <mat-radio-button *ngFor="let item of criticalityList; let i=index" 
                                                                                [value]="item.id" name="{{'opt'+item.id + catIndex + termIndex}}">
                                                                                {{item.title}}
                                                                            </mat-radio-button>
                                                                        </mat-radio-group> -->
                                                                    <!-- </mat-form-field> -->
                                                                </div>
                                                                <div class="col-md-5 my-auto text-center d-flex align-items-center">
                                                                    <input matInput type="number" matTooltip="Weightage (in %)" placeholder="Weightage (in %)" min="0" [(ngModel)]="term.weightage" [ngModelOptions]="{standalone: true}"
                                                                        oninput="this.value = 
                                                                                    !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null"
                                                                        class="w-75 py-2 rounded border" name="weightage"
                                                                        (input)="onWeightageTermsChange($event, cat, term)"
                                                                       style=" background-color: white;padding: 0 0.4em 0 0.4em;width:20px;">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <small class="text-danger float-end w-25" *ngIf="cat.value.showValidation">
                                        {{staticText?.rfx?.weight_err}} </small>
                                </div>
                            </div>
                        </div>
                        <div class="row  m-0 my-2" *ngIf="apiResponseReceived&&groupScope?.length == 0">
                            <div class="col-md-10 mx-auto align-center">
                                <img src="./assets/images/no-record-found.png" class="no-record">
                            </div>
                            <div class="col-md-6 mx-auto">
                                <p id="no_results_found" class=" text-center text-muted">
                                    {{staticText?.common.no_results_found}} </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-12 mx-auto col-12">
                    <div class="row border-top">
                        <div class="col-md-6 mt-3 d-flex justify-content-start">
                            <button id="previous" mat-button mat-stroked-button
                                (click)="back()">
                                <mat-icon class="">chevron_left</mat-icon>
                                <span id="prev_section" class="m-0 pe-2">
                                    {{staticText?.buttons?.back}}
                                </span>
                            </button>
                        </div>
                        <div class="col-md-6 mt-3 d-flex justify-content-end">
                            <button id="updateEvaluationTerm" mat-button mat-flat-button class="bg-primary text-white" color="primary"
                                (click)="updateEvaluationTerms()">
                                <span id="next_section" class="m-0 ps-2">
                                    {{staticText?.rfx?.next_section}}
                                </span>
                                <mat-icon class="">chevron_right</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
</mat-step>
</mat-stepper> 

<!-- </mat-card>
        </div>
    </div> -->
<div class="col-md-4 px-3" *ngIf="showSetting && !showRG">
    <div class="help-border hleft htopborder  p-3 d-flex justify-content-between border-bottom">
        <p id="rfx_settings_" class="fw-bold mb-0" style="color: black;">{{staticText?.rfx?.settings}}</p>
        <img src="./assets/images/Group 8814.svg"
            (click)="showRightPane = false; showRightPane = false; showSetting=false;" class="cursor-pointer" style="width:21px; position: relative;
            bottom: 2px;">
    </div>
    <mat-card class="matcorner1 list-height">
        <div class="row mx-1">
        </div>
        <form [formGroup]="evaluationForm" class="mt-4"
            *ngxPermissionsOnly="['opportunity.add_opportunityevaluationsettings']">
            <div class="mb-3">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{staticText?.rfx?.score_range}}</mat-label>
                    <mat-select appearance="filled" class="w-100" formControlName="score_range">
                        <mat-option *ngFor="let score of scoreRangeList" [value]="score.id">
                            {{score.display_name}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-error *ngIf="settingsSubmit && f.score_range.errors">
                    <small class="text-danger" *ngIf="f.score_range.errors.required">
                        {{staticText?.rfx?.score_range_err}} </small>
                </mat-error>
            </div>
            <div class="mb-3">
                <div class="row">
                    <div class="col-12 mx-3 text-muted">
                        <label class="f-small">{{staticText?.rfx?.criticality}} </label>
                        <p id="set_criticality_" (click)="showCriticality=true; showSetting=false"
                            class="float-end me-3 primary cursor-pointer text-decoration-underline">
                            {{staticText?.rfx?.set_criticality}} </p>
                    </div>
                    <div class="col-12">
                        <mat-radio-group aria-label="Select an option" class="mx-3" formControlName="criticality">
                            <mat-radio-button class="fs-6 me-4" [value]='1' [checked]="critiCheck">
                                <small>{{staticText?.rfx?.enable}}</small>
                            </mat-radio-button>
                            <mat-radio-button class="fs-6 me-4" [value]='0'><small>{{staticText?.rfx?.disable}}</small>
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <div class="row">
                    <div class="col-12 mb-2 mx-3 text-muted">
                        <label class="f-small">{{staticText?.proposal?.proposal_evaluation.weightage}}</label>
                    </div>
                    <div class="col-12">
                        <mat-radio-group aria-label="Select an option" class="mx-3" formControlName="weightage">
                            <mat-radio-button class="fs-6 me-4" [value]='1' [checked]="weightCheck">
                                <small>{{staticText?.rfx?.enable}}</small>
                            </mat-radio-button>
                            <mat-radio-button class="fs-6 me-4" [value]='0'
                                [ngClass]="{'select-disabled': groupScope.length > 0}"><small>{{staticText?.rfx?.disable}}</small>
                            </mat-radio-button>
                        </mat-radio-group>
                        <mat-error *ngIf="settingsSubmit && f.weightage.errors">
                            <small class="text-danger"
                                *ngIf="f.weightage.errors.required">{{staticText?.rfx?.weight_req}} </small>
                        </mat-error>
                    </div>
                    <div class="col-12 mt-3">
                        <button id="updateSetting" mat-button mat-flat-button
                            class="next-color text-white w-100 border my-3" (click)="updateSetting()"
                            [disabled]="disableFields">{{staticText?.rfx?.save_settings}}</button>
                    </div>
                </div>
            </div>
        </form>
    </mat-card>
</div>

<div class="col-md-4 px-4" *ngIf="showCriticality">
    <div class="help-border helpleft htopborder p-3  d-flex justify-content-between border-bottom">
        <p id="set_criticality_1" class="text-black fw-bold mb-0">{{staticText?.rfx?.set_criticality}}</p>
        <img src="./assets/images/Group 8814.svg" (click)="showSetting = true; showCriticality=false;"
            class="cursor-pointer" style="width:21px; position: relative;
            bottom: 11px;">
    </div>
    <mat-card class="matcorner1 list-height p-0">
        <div class="row mx-1 pt-3">
            <label class="pt-3 text-dark">{{staticText?.rfx?.criticality_content}}</label>
            <app-list [list]="criticalityList" [disableEdit]="disableFields" type="critiList"
                (levelClose)="deleteCriticality($event)">
            </app-list>
            <ng-container *ngIf="!disableFields">
                <p id="add_criticality" class="pt-4 para-color d-flex cursor-pointer" (click)="addCriticality()"
                    *ngxPermissionsOnly="['opportunity.add_criticality']"><span>
                        <mat-icon id="cursor-pointer" class="cursor-pointer" style="font-size: large;">add
                        </mat-icon>
                    </span>{{staticText?.rfx?.add_criticality}}</p>
            </ng-container>
        </div>
    </mat-card>
</div>

<div class="col-md-4 px-4" *ngIf="showAddCriticality">
    <div class="help-border helpleft htopborder p-3 d-flex justify-content-between border-bottom">
        <p id="add_criticality_1" class="text-black fw-bold me-5 pe-4 mb-0">{{staticText?.rfx?.add_criticality}}</p>
        <img src="./assets/images/Group 8814.svg" (click)="showCriticality=true; showAddCriticality=false;"
            class="cursor-pointer" style="width:21px; position: relative;
                bottom: 11px;">
    </div>
    <mat-card class="matcorner1 list-height p-0">
        <div class="row mx-1 pt-3">
            <div class="col-md-12">
                <mat-form-field class="py-2 w-100 pt-4" appearance="outline">
                    <mat-label>{{staticText?.rfx?.criticality_title}}</mat-label>
                    <input type="text" matInput fullWidth status="basic" [(ngModel)]="critiTitle">
                </mat-form-field>
                <mat-form-field class="py-2 w-100 pt-4" appearance="outline">
                    <mat-label>{{staticText?.rfx?.cric_desc}}</mat-label>
                    <input type="text" matInput fullWidth status="basic" [(ngModel)]="critiDescription">
                </mat-form-field>
                <div class="footer-right border-top">
                    <button id="createCrticality" mat-button mat-flat-button
                        class="text-white w-100 border my-3 next-color"
                        (click)="createCrticality()">{{staticText?.rfx?.add_criticality}}
                    </button>
                </div>
            </div>
        </div>
    </mat-card>
</div>

<div class="col-md-4 px-4" *ngIf="showRG && !showSetting">
    <div class="">
        <div class="row">
            <mat-card class="p-2 matcorner-border">
                <div class="d-flex justify-content-between">
                    <p id="range_guides" class="fs-18 py-2 ps-2 mb-0 text-blackfw-bold">
                        {{staticText?.rfx?.range_guides}}
                    </p>
                    <img src="./assets/images/Group 8814.svg" class="float-end cursor-pointer text-muted"
                        style="width:22px;" (click)="showRG=false; showRightPane=false;">
                </div>
            </mat-card>

            <label
                class="fs-16 my-3 ps-3 fw-bold py-2 primary">{{staticText?.proposal?.proposal_evaluation.weightage}}</label>
            <mat-card class="matcorner-border custom-Height-card">
                <p id="weight_content" class="mt-3 mx-2 fs-16 text-black">{{staticText?.rfx?.weight_content}}</p>

                <app-list [list]="weightageList" class="p-0 text-black" type="weightList">
                </app-list>
                <div class="row border border-light bg-label mt-4 py-2 p-0 weight-width">
                    <label class="fs-16 primary" style="padding-top: 10px;">{{staticText?.rfx?.criticality}}<span
                            id="showCriticality_true_false"
                            class="material-icons-outlined float-end cursor-pointer primary"
                            (click)="showCriticality=true; showRG=false;"
                            style="padding-right: 122px;">{{staticText?.rfx?.settings}}</span></label>
                </div>
                <p id="criticality_content_1" class="mt-3 mx-2 text-black">{{staticText?.rfx?.criticality_content}}
                </p>
                <app-list [list]="criticalityList" type="critiRange">
                </app-list>
            </mat-card>
        </div>
    </div>
</div>

<!-- <div class="col-md-4" *ngIf="!showRG && !showSetting && !showCriticality && !showAddCriticality">
    <div class="empty-pane"></div>
</div> -->