<div class="row">
    <div class="col-md-12">
        <div class="row mt-1 mb-2">
            <div class="p-0 d-flex">
                <div *ngIf="isRedirectFrom" class="w-auto p-0">
                    <h3 class="screen-name" (click)="clickPrevious1()">
                        <mat-icon id="chevron_left" aria-hidden="false" aria-label="material-icons-filled "
                            class="cursor-pointer" style="font-size: 35px;">chevron_left
                        </mat-icon>
                    </h3>
                </div>
                <div [ngClass]="isRedirectFrom?'col-11':'col-12'">
                    <p class="heading-color fw-bold mb-0">{{staticText?.rfx?.more_info}}</p>
                    <p class="m-0 mb-1" style="font-size: 13px;">{{opportunityInfo?.opportunity_name}} | {{opportunityInfo?.opportunity_number}} |
                        {{opportunityInfo?.opportunity_type}} | {{opportunityInfo?.portfolio}} |
                        {{opportunityInfo?.subportfolio}}
                        | {{opportunityInfo?.program}} | {{opportunityInfo?.owner_name}} |
                        {{opportunityInfo?.single_point_of_contact}}
                    </p>
                    <app-tag [dataObj]="getTagObject(data?.id)" [tagsIds]="data?.tag_ids" *ngIf="data"></app-tag>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="custom-height">
        <form [formGroup]="moreInfoCreateForm">
            <div class="custom-table">
                <table class="table table-bordered">
                    <thead style="position: sticky;top: 0; z-index: 1">
                        <tr>
                            <th class="fixed-header" scope="col">#</th>
                            <th class="fixed-header" scope="col" class="w-50">Document Name</th>
                            <th class="fixed-header" scope="col" class="w-50">File</th>
                            <th class="fixed-header text-center col-2" scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let option of moreInfoCreateForm.controls; let i = index" class="wprr">
                            <td class="align-middle">{{i+1}}</td>
                            <td class="align-middle w-50">
                                <mat-form-field class="example-full-width" style="width: 100%" appearance="outline">
                                    <mat-label></mat-label>
                                    <input type="text"
                                        placeholder="Select Document" matInput
                                        [formControl]="option.value.section"
                                        [matAutocomplete]="autoDocumentCom"
                                        (ngModelChange)="searchDocument(i)"
                                        name="document_name">
                                    <mat-autocomplete #autoDocumentCom="matAutocomplete" [displayWith]="getDocumentName.bind(this)">
                                        <mat-option *ngFor="let item of getDocument(i)" [value]="item.id" style="font-size: 0.8rem; height: 30px;">
                                            {{item.name}} 
                                            <span *ngIf="!item.is_custom" class="thumbs"> 
                                                <mat-icon style="transform: scale(0.6);">
                                                    thumb_up
                                                </mat-icon> 
                                            </span>
                                            <span *ngIf="item.delete" class="delete-cion" (click)="deleteCustom(item.id); $event.stopPropagation()"> 
                                                <mat-icon style="transform: scale(0.6);">
                                                    delete_outline
                                                </mat-icon> 
                                            </span>
                                        </mat-option>
                                        <mat-option *ngIf="getDocument(i).length == 0" [value]="inputValue" style="font-size: 0.8rem; height: 30px;">
                                            <span (click)="addNewDocument(i, option.value.section.value)">Create New: <span>"{{option.value.section.value}}"</span></span>
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </td>
                            <td class="align-middle w-50">
                                <div class="upload" *ngIf="!disableFields && option.value.file.value == '' && moreInfoCreateForm.controls[i].value.section.value != ''">
                                    <div for="file" class="w-100 cursor-pointer d-flex justify-content-center"
                                        (click)="fileInput1.click()">
                                        <mat-icon id="cloud_upload"
                                            class="material-icons my-auto secondary">cloud_upload</mat-icon>
                                        <small class="mt-1 secondary">
                                            {{staticText?.common.upload_doc}}</small>
                                    </div>
                                    <input hidden (change)="onFileSelected($event, option, i)" #fileInput1 type="file"
                                        name="file" id="file">
                                    <p class="fw-light font-size mb-0" style="margin-top: -6px; font-size: 0.7rem; text-align: center;">
                                        {{staticText?.action_tracker.doc_maxsize}}</p>
                                </div>
                                <div *ngIf="option.value.file.value">
                                    <div class="row file-border">
                                        <div class="col-md-9 d-flex">
                                            <div class="me-2" style=" display: flex; align-items: center;">
                                                <img *ngIf="option.value.extension.value == 'doc' || option.value.extension.value == 'docx'" src="./assets/images/word.svg"
                                                    height="25">
                                                <img *ngIf="option.value.extension.value == 'pdf'" src="./assets/images/pdf.svg" height="25">
                                                <img *ngIf="option.value.extension.value == 'xls' || option.value.extension.value == 'xlsx'" src="./assets/images/excel.svg"
                                                    height="25">
                                                <img *ngIf="option.value.extension.value == 'csv'" src="./assets/images/csv.png" height="25">
                                                <mat-icon id="description" style="transform: scale(0.8); margin-top: 2px !important;  margin-left: -7px;"
                                                    *ngIf="option.value.extension.value !== 'pdf' && option.value.extension.value !== 'doc' && option.value.extension.value !== 'docx' && option.value.extension.value !== 'xls' && option.value.extension.value !== 'xlsx' && option.value.extension.value !== 'csv'"
                                                    mat-list-icon class="material-icons my-auto fs-1 text-danger mr-2">
                                                    description</mat-icon>
                                            </div>
                                            <div class="ms-0 mt-1">
                                                <p class="fs-14 mb-0" style="
                                                            max-width: 11rem;
                                                            overflow: hidden;
                                                            text-overflow: ellipsis;
                                                            white-space: nowrap;  ">{{option.value.file.value}}</p>
                
                                                <p class="text-muted mb-0"
                                                    style="font-size:0.7rem; white-space: nowrap; padding-right: 2rem;">
                                                    {{staticText?.common?.last_updated}}: {{option.value.date.value}}</p>
                                            </div>
                
                                        </div>
                                        <div class="col-md-2 ml-1 d-flex pt-2 ps-0">
                                            <img (click)="downloadFile(option, i)" class="cursor-pointer"
                                                src="./assets/images/download_icon.svg" height="25">
                                            <img (click)="deleteFile(option, i)" class="px-2 cursor-pointer" *ngIf="!disableFields"
                                                src="./assets/images/delete_icon.svg" height="25">
                                        </div>
                                        <div class="col-md-1"></div>
                                    </div>
                                </div>
                            </td>
                            <td class="align-middle">
                                <div style="display: flex; align-items: center; justify-content: space-around;">
                                    <span class="cursor-pointer" (click)="removeDocument(i)"
                                    *ngIf="!disableFields">
                                        <span class="cursor-pointer">
                                            <img src="./assets/images/comment-delete.svg"
                                                style="width:16px;">
                                        </span>
                                    </span>

                                    <span class="cursor-pointer" (click)="addDocumentRo(i)"
                                    *ngIf="!disableFields && moreInfoCreateForm.controls.length - 1 === i">
                                    <mat-icon aria-hidden="false" aria-label="material-icons-filled"
                                            class="cursor-pointer fs-5" style="margin-top: 10px;">add
                                        </mat-icon>
                                    </span>
                                </div>
                            </td>
                        </tr>
                        <tr *ngIf="moreInfoCreateForm?.controls?.length <= 0">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td class="w-50">
                                <a class="add-btton"
                                    (click)="addDocumentRo()" *ngIf="!disableFields">
                                    <mat-icon aria-hidden="false" aria-label="material-icons-filled"
                                        class="cursor-pointer fs-5">add
                                    </mat-icon>
                                    Add Document
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div>
                   
                </div>
            </div>
        </form>
    </div>
</div>
<div class="row mx-3 border-top footer-align">
    <div class="col-md-6 mt-3 d-flex justify-content-start p-0">
        <button id="clickPrevious" mat-button mat-stroked-button
            (click)="clickPrevious()">
            <mat-icon class="">chevron_left</mat-icon>
            <span class="m-0 pe-2">
                {{staticText?.rfx.prev_section}}
            </span>
        </button>
    </div>
    <div class="col-md-6 mt-3 d-flex justify-content-end p-0">
        <button id="next_section" mat-button mat-flat-button class="bg-primary text-white" color="primary" (click)="clickNext()">
            <span class="m-0 ps-2">
                {{staticText?.rfx.next_section}}
            </span>
            <mat-icon class="">chevron_right</mat-icon>
        </button>
    </div>
</div>