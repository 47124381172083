import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EvaluationService {

  constructor(private http: HttpClient) { }

  createCriticality(payload: any) {
    return this.http.post(`opportunity/create-criticality/`, payload);
  }

  deleteCriticality(id: any) {
    return this.http.delete(`opportunity/delete-criticality/${id}/`);
  }

  getSettings(id: any) {
    return this.http.get(`opportunity/get-evaluation-settings/?opportunity=${id}`);   
  }

  updateSettings(payload: any) {
    return this.http.post(`opportunity/create-update-evaluation-settings/`, payload);
  }
}
