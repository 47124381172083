import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProgramService } from 'src/app/shared/services/program.service';
import * as moment from 'moment';
import { ScopeService } from 'src/app/shared/services/scope.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

import * as textConfiguration from 'src/assets/static-text-configuration.json';
import { ViewEncapsulation } from '@angular/core';
@Component({
  selector: 'app-preview-scope',
  templateUrl: './preview-scope.component.html',
  styleUrls: ['./preview-scope.component.scss'],
  encapsulation:ViewEncapsulation.Emulated
})
export class PreviewScopeComponent implements OnInit {
  staticText: any = (textConfiguration as any).default;
  opportunityId: any;
  selectedCategoryItems: any;
  totalFiles: any[] = [];
  @Output() sendSelected = new EventEmitter<{}>();
  exportPayload: any;
  scopeModel = true;
  scopeDocumentModal = true;

  constructor(
    private ps: ProgramService,
    private route: ActivatedRoute,
    private scopeService: ScopeService,
    private spinner: NgxSpinnerService,
    private toastrService: ToastrService
  ) { }

  ngOnInit(): void {
    this.route.parent?.parent?.params.subscribe((res: any) => {
      this.opportunityId = atob(res.id);
      this.getOpportunityById();
      this.getFile();
    });
  }

  getOpportunityById() {
    this.ps.getOpportunityScopeData(this.opportunityId).subscribe((res: any) => {
      console.log("res res?.opportunity_scope", res);
      this.selectedCategoryItems = res.opportunity_scope;
      console.log("this.selectedCategoryItems", this.selectedCategoryItems);
      (this.selectedCategoryItems || []).forEach((elm: any) => {
        elm.category.scope.forEach((s: any) => {
          s.parameter.forEach((p: any) => {
            if (p.display_name == 'Date') {
              p.scope_parameter_value = p.scope_parameter_value == 'Invalid date' ? '-' : moment.utc(p.scope_parameter_value).format('YYYY-MM-DD');
              p.scope_parameter_value = p.scope_parameter_value == 'Invalid date' ? '-' : moment(p.scope_parameter_value).format('LL');
            }
          });
        });
      });
    }, error => {
      throw error;
    });
  }

  showOptions(e: any) {
    if (e.source.id == 'scopeCheck') {
      this.toastrService.warning('Please save the changes');

      this.ps.getOpportunityFormData('opportunity_scopes').subscribe((res: any) => {
        const sectionId = res.records[0].form_details.id;
        this.exportPayload = {
          section: sectionId,
          export: e.checked
        };
        this.sendSelected.emit(this.exportPayload);
      }, error => {
        throw error;
      });
    }
  }

  getFile() {
    this.scopeService.getFile(this.opportunityId).subscribe((res: any) => {
      res.forEach((e: any) => {
        const fileName = e.file;
        const ext = fileName.substring(fileName.lastIndexOf('.') + 1);
        const newObj = { file: fileName, doc: ext, size: e.size / 1000000 };

        this.totalFiles.push(newObj);

        console.log("totalFiles", this.totalFiles);
      });
    }, error => {
      throw error;
    });
  }

  downloadFile(e: any) {
    var payload = {
      file_name: e.file
    }
    return;
    this.spinner.show();
    this.scopeService.downloadFile(payload, this.opportunityId).subscribe((res: any) => {
      let downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(res);
      downloadLink.setAttribute('download', e.file);
      document.body.appendChild(downloadLink);
      downloadLink.click();
      this.toastrService.success('File downloaded successfully');
      this.spinner.hide();
    }, error => {
      throw error;
    });
  }

}
