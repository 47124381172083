import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MoreInformationService {

  constructor(private http: HttpClient) { }

  createSection(payload: any) {
    return this.http.post(`opportunity/create-section/`, payload, {observe: 'response'});
  }

  deleteSection(id: any) {
    return this.http.delete(`opportunity/delete-section/${id}/`);
  }

  uploadFile(payload: any, id: any) {
    return this.http.post(`opportunity/more-info-file-upload/${id}/`, payload);
  }

  deleteFile(payload: any, id: any) {
    return this.http.post(`opportunity/more-info-file-delete/${id}/`, payload);
  }

  downloadFile(payload: any, id: any) {
    return this.http.post(`opportunity/more-info-file-download/${id}/`, payload, {responseType: 'blob'});
  }

}
