import { formatDate } from '@angular/common';
import { OnDestroy, ViewEncapsulation } from '@angular/core';
import { ChangeDetectorRef, Component, OnInit, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { isNullOrUndefined } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DataService } from 'src/app/shared/services/data.service';
import { FormValidatorService } from 'src/app/shared/services/form-validator/form-validator.service';
import { LodashService } from 'src/app/shared/services/lodash.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { ProgramService } from 'src/app/shared/services/program.service';
import { TermsService } from 'src/app/shared/services/terms.service';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
var _ = require('lodash');

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss'],
  encapsulation:ViewEncapsulation.Emulated
})
export class TermsComponent implements OnInit,OnDestroy {
  staticText: any = (textConfiguration as any).default;

  termsForm: FormGroup | any;
  toeForm: FormGroup | any;

  tips = true;
  search = false;
  section = false;
  submitted = false;
  toeSubmitted = false;
  panelOpenState = false;
  clickedbtn: boolean = false;
  formInvalid: boolean = false;

  masterTermArray: any[] = [];  
  masterTermArrayInitial: any[]=[];
  formArray: any[] = [];
  matValues: any[] = [];
  totalFiles: any[] = [];
  patchToeData: any[] = [];
  termFileArray: any[] = [];
  patchFilterdata: any[] = [];
  customTermsList: any[] = [];
  slaveTermListRecords: any[] = [];
  constantSubCategory: any[] = [];

  filterOptionTitle: Observable<any[]> | any;

  textContents = {
    searchText: 'Terms searched is not found in the list'
  }
  searchText = '';

  list: any;
  items: any;
  catExist: any;
  catPayload: any;
  updateItems: any;
  opportunityId: any;
  subCatExist: any;
  patchResponse: any;
  showTab = 'help';
  listItems = []
  allTerms:any=[];
  disableFields: boolean=false;
  isRedirectFrom = false;
  projectID:any='';
  sub:any;
  constructor(private formBuilder: FormBuilder,
    private ps: ProgramService,
    private spinner: NgxSpinnerService,
    private toastrService: ToastrService,
    private dataService: DataService,
    private lodash: LodashService,
    private termsService: TermsService,
    private route: ActivatedRoute,
    private router: Router,
    private _formValidator: FormValidatorService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {  
    this.messageService.setGnews('Opportunity Terms of Engagement')
    this.sub = this.route
      .queryParams
      .subscribe((params:any) => {
        this.isRedirectFrom = params['redirectFrom'] || false;
        this.projectID = params['id']; 
      });
    this.route.parent?.parent?.params.subscribe((res: any) => {
      this.opportunityId = atob(res.id)
    });
    this.createForm();
    this.getOpportunityById();
    this.getFile();
    this.filterOptionTitle = this.termsForm.get('category')!.valueChanges
      .pipe(
        startWith(''),
        map((value: any) => typeof value === 'string' ? value : value?.categoryTitle),
        map((categoryTitle: any) => categoryTitle ? this.typeFilter(categoryTitle) : this.matValues.slice())
      );
  }

  getOpportunityById() {
    this.spinner.show();
    this.ps.getOpportunityById(this.opportunityId).subscribe((res: any) => {
      this.patchResponse = res;
      this.ps.setStatusValue(res?.status);
      if(res.status.slug == 'opportunity_evaluating' || res.status.slug == 'opportunity_sow_signed' || res.status.slug == 'opportunity_cancelled'){
        this.disableFields =true;
      }
      this.valuePatch(this.patchResponse);
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      throw error;
    });
  }


  valuePatch(data: any) {
    this.allTerms =[];
    if (data.contract_terms) {
      let termArray: any[] = []
      data.contract_terms.forEach((element: any) => {
        termArray.push(element.term_category)
        element.term_category.term_sub_category.forEach((Measurement: any) => {
          //this.allTerms = this.allTerms.concat(Measurement.term_measurement_criteria);
          Measurement.term_measurement_criteria.forEach((measurement: any) => {
            this.createItem(measurement);
          });
        });
      });
      this.masterTermArray = termArray;
      this.masterTermArrayInitial = JSON.parse(JSON.stringify(termArray));
      console.log(this.masterTermArray);
      this.getData();
    }

  }

  private typeFilter(value: string): any {
    const filterValue = value.toLowerCase();
    return this.matValues.filter((i: any) => i.categoryTitle.toLowerCase().includes(filterValue));
  }

  displayFn(data: any) {
    return data && data.categoryTitle ? data.categoryTitle : '';
  }

  validateData(type: any) {
  }

  createForm() {
    this.toeForm = new FormGroup({
      items: new FormArray([])
    });

    this.termsForm = this.formBuilder.group({
      category: ['', [Validators.required,this._formValidator.autocompleteObjectValidator]],
      subCategory: [''],
      measurement: ['', Validators.required],
    });
  }

  openToe() {
    this.showTab = 'add_terms';
  }

  get f() {
    return this.termsForm.controls;
  }

  createItem(data: any): FormGroup {
    return this.formBuilder.group({
      milestone: [data?.milestone || '', Validators.required],
      deadline: [data?.deadline || '', Validators.required],
      notes: [data?.notes || '', Validators.required],
      criteria_uuid: [data?.measurement_criteria_uuid || '']
    });
  }

  toeValueFormArray() {
    return this.toeForm.get('items') as FormArray;
  }

  toeFormArrayControls(i: any): FormGroup {
    return this.toeValueFormArray().controls[i] as FormGroup;
  }


  uploadBar() {
    this.showTab = 'upload';
  }

  onFileSelected(e: any) {
    const file = e;
    const fd: any = new FormData();
    fd.append('file_name', file.target.files[0].name);
    fd.append('file', file.target.files[0]);
    if (file.target.files[0].size > 2000000) {
      this.toastrService.error('Please upload less than 2MB');
      return;
    }
    this.spinner.show();
    this.termsService.uploadFile(fd, this.opportunityId).subscribe((res: any) => {
      this.toastrService.success('File uploaded successfully');
      this.spinner.hide();
      this.getFile();
    }, error => {
      throw error;
    });
  }

  getFile() {
    this.totalFiles = [];
    this.spinner.show();
    this.termsService.getFile(this.opportunityId).subscribe((res: any) => {
      if (res.length > 0) {
        (res || []).forEach((e: any) => {
          const fileName = e.file;
          const ext = fileName.substring(fileName.lastIndexOf('.') + 1);
          const newObj = { file: fileName, doc: ext?.toLowerCase(), size: e.size / 1000000 };
          this.totalFiles.push(newObj);
        });
      }
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      throw error;
    });
  }

  downloadFile(e: any) {
    var payload = {
      file_name: e.file
    }
    this.spinner.show();
    this.termsService.downloadFile(payload, this.opportunityId).subscribe((res: any) => {
      let downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(res);
      downloadLink.setAttribute('download', e.file);
      document.body.appendChild(downloadLink);
      downloadLink.click();
      this.toastrService.success('File downloaded successfully');
      this.spinner.hide();
    }, error => {
      throw error;
    });
  }

  deleteFile(e: any) {
    var payload = {
      file_name: e.file
    }
    this.spinner.show();
    this.termsService.deleteFile(payload, this.opportunityId).subscribe((res: any) => {
      this.toastrService.success('File deleted successfully');
      this.spinner.hide();
      this.getFile();
    }, error => {
      throw error;
    });
  }


  addCustomTerms() {
    this.list.forEach((e: any) => {
      if (e.term_sub_category?.length > 0) {
        e.term_sub_category.forEach((t: any) => {
          this.constantSubCategory.push(t)
        });
      }
    });
    this.submitted = true;
    if (this.termsForm.invalid) {
      this.spinner.hide();
      return;
    }
    if (typeof (this.termsForm.value.category) == 'object') {
      this.termsForm.value.category = this.termsForm.value.category.categoryTitle
    } if (typeof (this.termsForm.value.category) == 'string') {
      this.termsForm.value.category = this.termsForm.value.category
    }
    this.catExist = false;
    const catObj = this.list.find((i: any) => i.category_display_name == this.termsForm.value.category);
    const subCatObj = this.constantSubCategory.find((i: any) => i.sub_category_display_name == this.termsForm.value.subCategory);
    if (catObj) {
      this.catExist = this.list.some((i: any) => i.category_display_name == this.termsForm.value.category);
    }
    if (subCatObj) {
      this.subCatExist = this.constantSubCategory.some((i: any) => i.sub_category_display_name == this.termsForm.value.subCategory);
    } else {
      this.subCatExist = false;
    }

    if (this.catExist && !this.subCatExist) {
      this.catPayload = {
        term_category: {
          uuid: catObj.category_uuid,
        },
        term_sub_category: {
          name: this.termsForm.value.subCategory ? this.termsForm.value.subCategory : 'Not Specified',
          display_name: this.termsForm.value.subCategory ? this.termsForm.value.subCategory : 'Not Specified',
          is_custom: true
        },
        term_measurement_criteria: {
          name: this.termsForm.value.measurement,
          display_name: this.termsForm.value.measurement,
          is_custom: true
        }
      }
    } else if (this.catExist == true && this.subCatExist == true) {
      this.catPayload = {
        term_category: {
          uuid: catObj.category_uuid,
        },
        term_sub_category: {
          uuid: subCatObj.sub_category_uuid
        },
        term_measurement_criteria: {
          name: this.termsForm.value.measurement,
          display_name: this.termsForm.value.measurement,
          is_custom: true
        }
      }
    } else {
      this.catPayload = {
        term_category: {
          name: this.termsForm.value.category,
          display_name: this.termsForm.value.category,
          is_custom: true
        },
        term_sub_category: {
          name: this.termsForm.value.subCategory ? this.termsForm.value.subCategory : 'Not Specified',
          display_name: this.termsForm.value.subCategory ? this.termsForm.value.subCategory : 'Not Specified',
          is_custom: true
        },
        term_measurement_criteria: {
          name: this.termsForm.value.measurement,
          display_name: this.termsForm.value.measurement,
          is_custom: true
        }
      }
    }
    this.spinner.show()
    this.termsService.createCustomTerms(this.catPayload).subscribe((res: any) => {
      this.spinner.hide()
      if (res.status == 201) {
        this.toastrService.success('Custom Terms created successfully');
        this.showTab = 'add_terms'
        this.getData();
        this.spinner.hide();
        return;
      } else if (res.status == 200) {
        this.toastrService.error('Custom Term already exist in our records');
        this.spinner.hide();
      }
    }, error => {
      this.toastrService.error('Could not get data');
      this.spinner.hide();
      throw error;
    });
  }


  filterDuplicates(listArray: any[], patchArray: any[]) {
    if (listArray && patchArray) {
      patchArray?.forEach((patchCategoryArray: any) => {
        patchCategoryArray?.term_sub_category?.forEach((patchSubCategoryArray: any) => {
          patchSubCategoryArray?.term_measurement_criteria?.forEach((patchMeasurementArray: any) => {
            let findCategory = listArray.find((findCategory: any) => findCategory?.category_uuid == patchCategoryArray?.category_uuid);
            let categoryIndex = listArray.findIndex((categoryIndx: any) => categoryIndx?.category_uuid == patchCategoryArray?.category_uuid);
            if (findCategory) {
              let subParentIndex = findCategory?.term_sub_category?.findIndex((subIndx: any) => subIndx?.sub_category_uuid == patchSubCategoryArray?.sub_category_uuid);
              findCategory?.term_sub_category?.forEach((findSubCategory: any) => {
                if (findSubCategory.sub_category_uuid == patchSubCategoryArray?.sub_category_uuid) {
                  let measurementIndex = findSubCategory?.term_measurement_criteria?.findIndex((measurementIndx: any) => measurementIndx.measurement_criteria_uuid == patchMeasurementArray.measurement_criteria_uuid);
                  findSubCategory?.term_measurement_criteria?.splice(measurementIndex, 1);
                  if (findSubCategory?.term_measurement_criteria?.length == 0 && findCategory?.term_sub_category?.length == 0) {
                    listArray?.splice(categoryIndex, 1);
                  }
                  if (findSubCategory?.term_measurement_criteria?.length == 0) {
                    findCategory?.term_sub_category.splice(subParentIndex, 1);
                    if (findCategory?.term_sub_category?.length == 0) {
                      listArray?.splice(categoryIndex, 1);
                    }
                    let parentIndex = findCategory?.term_sub_category?.findIndex((subIndx: any) => subIndx?.sub_category_uuid == patchSubCategoryArray?.sub_category_uuid);
                    if (parentIndex !== -1) {
                      findCategory?.term_sub_category.splice(subParentIndex, 1);
                    }
                  }
                } else {
                  if (findSubCategory?.term_measurement_criteria?.length == 0 && findCategory?.term_sub_category?.length == 0) {
                    listArray?.splice(categoryIndex, 1);
                  }
                }
              });
            }

          })
        })
      })
    }
    return listArray;
  }

  getData() {
    this.ps.getOpportunityFormData('terms_of_engagement').subscribe((res: any) => {
      this.patchToeData = this.lodash.Clonedata(res.records[0].field_values);
      this.list = res.records[0].field_values;
      this.filterDuplicates(this.list, this.masterTermArray);
      this.slaveTermListRecords = this.list;
      //---------------RECOMMENDED LIST-------------------------//
      (this.slaveTermListRecords || []).forEach((item: any) => {
        let payload = {
          categoryTitle: item.category_display_name,
        }
        this.matValues.push(payload);
        this.matValues = _.uniqWith([...this.matValues], _.isEqual);

        this.items = this.toeForm.get('items') as FormArray;
        if (item.term_sub_category) {
          item.term_sub_category.map((e: any) => {
            e.category_uuid = item.category_uuid;
            if (e.term_measurement_criteria) {
              e.term_measurement_criteria.map((s: any) => {
                this.items.push(this.createItem(s));
                return s;
              });
            }
            return e;
          });
          this.listItems = this.items
        }
      });
    }, error => {
      this.spinner.hide();
      this.toastrService.error('Could not get data');
      throw error;
    });
  }

  addColumn(item: any) {
    // let found = this.allTerms.find((o:any)=>o.measurement_criteria_uuid == item.measurement_criteria_uuid);
    // if(!found)
    //   this.allTerms.push(item);
    // below code we will find category for patchToeData it contains all category data
    let keyData1 = { ...this.patchToeData.find((i: any) => i.category_uuid == item.category_uuid) };
    let subKey = _.cloneDeep(keyData1);
    let keyData = _.cloneDeep(keyData1);
    let results = keyData.term_sub_category.filter((i: any) => i.sub_category_uuid == item.sub_category_uuid);
    const isExist = this.masterTermArray.some(e => e.category_uuid == keyData.category_uuid);
    keyData.term_sub_category = results;
    let measurement = keyData.term_sub_category[0].term_measurement_criteria.filter((el: any) => {
      if (el.measurement_criteria_uuid == item.measurement_criteria_uuid) {
        return el;
      }
    });
    if (!isExist) {
      keyData.term_sub_category[0].term_measurement_criteria = measurement;
      this.masterTermArray.push(keyData);   
      //this.allTerms.push()
    } else {
      let index = this.masterTermArray.findIndex((i: any) => i.category_uuid == item.category_uuid);
      // this.masterTermArray[index].term_sub_category[0].term_measurement_criteria.push(...measurement);
      const subValue = this.masterTermArray[index].term_sub_category.find((z: any) => z.sub_category_uuid == item.sub_category_uuid);
      if (subValue) {
        subValue.term_measurement_criteria.push(...measurement);
      } else {
        const subCopy = subKey.term_sub_category.find((x: any) => x.sub_category_uuid == item.sub_category_uuid);
        subCopy.term_measurement_criteria = measurement;
        this.masterTermArray[index].term_sub_category.push(subCopy);
      }
    }

  }

  removeKeyColumn(item: any) {
    //this.allTerms =this.allTerms.filter((o:any)=> o?.measurement_criteria_uuid != item?.measurement_criteria_uuid)
    this.masterTermArray?.filter((category: any, categoryIndex: number) => {
      if (category?.category_uuid == item.category_uuid) {
        if (category?.key_results?.length != 0) {
          category?.term_sub_category.forEach((subCategory: any, subCategoryIndex: number) => {
            let measurementCriteriaIndex = subCategory?.term_measurement_criteria?.findIndex((measuremen: any) => measuremen?.measurement_criteria_uuid == item?.measurement_criteria_uuid)
            if (measurementCriteriaIndex !== -1) {
              subCategory?.term_measurement_criteria.splice(measurementCriteriaIndex, 1);
            }
            if (subCategory?.term_measurement_criteria?.length == 0) {
              category?.term_sub_category.splice(subCategoryIndex, 1);
            }
            if (category?.term_sub_category?.length == 0) {
              this.masterTermArray.splice(categoryIndex, 1);
            }
          })
        } else {
          this.masterTermArray.splice(categoryIndex, 1);
        }
      }
    })
    let formIndex = this.formArray.findIndex((formIndx: any) => formIndx.uuid == item.measurement_criteria_uuid);
    this.formArray.splice(formIndex, 1);
    if (!item.goal_is_custom) {
      this.dataService.patchToeCategory(item);
    } else {
      this.dataService.patchCustomToeCategory(item);
    }
  }

  addCustomColumn(item: any) {
    // this.formInvalid = true;
    let keyData1 = { ...this.customTermsList.find(i => i.category_uuid == item.category_uuid) };
    let subKey = _.cloneDeep(keyData1);
    let keyData = _.cloneDeep(keyData1);
    let results = keyData.term_sub_category.filter((i: any) => i.sub_category_uuid == item.sub_category_uuid);
    const isExist = this.masterTermArray.some(e => e.category_uuid == keyData.category_uuid);
    keyData.term_sub_category = results;
    let measurement = keyData.term_sub_category[0].term_measurement_criteria.filter((el: any) => {
      if (el.measurement_criteria_uuid == item.measurement_criteria_uuid) {

        return el;
      }
    });
    if (!isExist) {
      keyData.term_sub_category[0].term_measurement_criteria = measurement;
      this.masterTermArray.push(keyData);
    } else {
      let index = this.masterTermArray.findIndex((i: any) => i.category_uuid == item.category_uuid);
      // this.masterTermArray[index].term_sub_category[0].term_measurement_criteria.push(...measurement);
      const subValue = this.masterTermArray[index].term_sub_category.find((z: any) => z.sub_category_uuid == item.sub_category_uuid);
      if (subValue) {
        subValue.term_measurement_criteria.push(...measurement);
      } else {
        const subCopy = subKey.term_sub_category.find((x: any) => x.sub_category_uuid == item.sub_category_uuid);
        subCopy.term_measurement_criteria = measurement;
        this.masterTermArray[index].term_sub_category.push(subCopy);
      }
    }
  }

  showCustomTerms() {
    this.showTab = 'custom_terms';
    this.termsForm.reset();
    this.submitted = false;
  }



  deleteCustomColumn(item: any) {
    if (item.measurement_criteria_uuid) {
      this.deleteCustomMeasurement(item);
    }
  }

  deleteCustomMeasurement(item: any) {
    this.spinner.show();
    this.termsService.deleteMeasurement(item.measurement_criteria_uuid, item.sub_category_uuid).subscribe((res: any) => {
      if (!res) {
        this.spinner.hide();
        if (item.sub_category_uuid) {
          this.deleteCustomSubcategory(item);
        }
      } else {
        this.toastrService.success('Deleted successfully');
        this.spinner.hide();
        this.getData();
      }

    }, error => {
      this.spinner.hide();
      this.toastrService.error('Could not delete custom Measurement');
      throw error;
    });
  }


  deleteCustomSubcategory(item: any) {
    this.spinner.show();
    this.termsService.deleteSubcategory(item.sub_category_uuid, item.category_uuid).subscribe((res: any) => {
      if (!res) {
        this.spinner.hide();
        if (item.category_uuid) {
          this.deleteCustomCategory(item);
        }
      } else {
        this.toastrService.success('Deleted successfully');
        this.spinner.hide();
        this.getData();
      }
    }, error => {
      this.spinner.hide();
      this.toastrService.error('Could not delete sub category');
      throw error;
    });
  }


  deleteCustomCategory(item: any) {
    this.spinner.show();
    this.termsService.deleteCategory(item.category_uuid).subscribe((res: any) => {
      this.toastrService.success('Deleted successfully');
      this.spinner.hide();
      this.getData();
    }, error => {
      this.spinner.hide();
      this.toastrService.error('Could not delete category');
      throw error;
    });
  }

  previousSection() {
    this.ps.setValue(2);
    this.ps.getOppData(this.opportunityId).subscribe((res)=>{this.ps.setStatusValue(res?.status);this.router.navigate([`rfx/`+btoa(this.opportunityId)+`/opportunity/evaluation`])});
  }

  createFormDataPayload(data?: any) {
    let formData: any[] = []
    if (data&& data.length>0 ) {
      for (var formdata of data) {
        if (formdata?.measurement_criteria_uuid && formdata?.milestone
          && formdata?.deadline && formdata?.notes) {
          var selecterMeasurement: any = {
            uuid: formdata?.measurement_criteria_uuid,
            milestone: formdata?.milestone,
            deadline: formatDate(formdata?.deadline, 'yyyy-MM-dd', 'en-US'),
            notes: formdata?.notes
          }
          formData.push(selecterMeasurement);
          this.lodash.sortUniqueBy(formData, data.measurement_criteria_uuid);
          this.formArray = this.formArray.filter((o:any)=>o.uuid !=formdata?.measurement_criteria_uuid )
          this.formArray.push(formData);
        }
      }
    }
  }

  nextSection() {    
    const noChange = JSON.stringify(this.masterTermArray) === JSON.stringify(this.masterTermArrayInitial);
    if(noChange){      
      this.ps.setValue(4);
      this.ps.getOppData(this.opportunityId).subscribe((res)=>{this.ps.setStatusValue(res?.status);this.router.navigate([`rfx/`+btoa(this.opportunityId)+`/opportunity/more-info`])});
      return;
    }

    this.toeSubmitted = true;
    let valid=true;
    const payload: any = {
      terms_data: []
    };
    (this.masterTermArray || []).forEach((t: any) => {
      const uuid1: any = {
        uuid: t.category_uuid,
        term_subcategory: []
      };
      (t.term_sub_category || []).forEach((s: any) => {
        const uuid2: any = {
          uuid: s.sub_category_uuid,
          term_measurement_criteria: []
        };
        // (s.term_measurement_criteria || []).forEach((m: any) => {
        //   for (var formData of this.formArray) {
        //     if (m.measurement_criteria_uuid === formData.uuid) {
        //       uuid2.term_measurement_criteria.push(formData);
        //     }
        //   }
        // });
        (s.term_measurement_criteria || []).forEach((m: any) => {
          let foundItem= this.formArray.find((o:any)=> o.uuid === m.measurement_criteria_uuid)
          if(foundItem){
            uuid2.term_measurement_criteria.push(foundItem);
          }else{
            const uuid3: any = {              
              uuid: m?.measurement_criteria_uuid,
              milestone: m?.milestone,
              deadline: m?.deadline ?formatDate(m?.deadline, 'yyyy-MM-dd', 'en-US'): m?.deadline,
              notes: m?.notes
            };
            if(isNullOrUndefined(m?.milestone) || isNullOrUndefined(m?.deadline)){
              valid=false;
              this.toastrService.error("Please fill mandatory fields")
            }else{
              uuid2.term_measurement_criteria.push(uuid3);
            }
          }
        });
        uuid1.term_subcategory.push(uuid2);
      });
      payload.terms_data.push(uuid1);
    });
    if(valid){
      this.ps.updateOpportunity(this.opportunityId, payload).subscribe((res: any) => {
        if (res) {
          this.ps.setValue(4);
          this.ps.getOppData(this.opportunityId).subscribe((res)=>{this.ps.setStatusValue(res?.status);this.router.navigate([`rfx/`+btoa(this.opportunityId)+`/opportunity/more-info`])});
        }
      }, error => {
        this.spinner.hide();
        this.toastrService.error(error?.error)
        throw error;
      })
    }
  }
  closeSidePane() {
    this.showTab = 'help';
  }
  clickPrevious(){
    this.router.navigate([`view/${this.projectID}/overview`])
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}