<div>
    <div class="row">
        <div class="col-md-12">
            <div class="row mt-1">
                <div class="p-0 d-flex">
                    <div *ngIf="isRedirectFrom" class="w-auto p-0">
                        <h3 class="screen-name" (click)="clickPrevious()">
                            <mat-icon aria-hidden="false" aria-label="material-icons-filled " class="cursor-pointer"
                                style="font-size: 35px;">chevron_left
                            </mat-icon>
                        </h3>
                    </div>
                    <div [ngClass]="isRedirectFrom?'col-9':'col-10'">
                        <p id="toe_title_terms" class="heading-color fw-bold mb-0">{{staticText?.toe.title}}
                        </p>
                    </div>
                    <div class="col-md-1 p-0" *ngIf="!disableFields">
                        <button id="openToe" matButton mat-flat-button
                            class="float-end danger mat-button fw-bold py-1 fs-16 " (click)="openToe()">+
                            {{staticText?.rfx.toe.add_terms}}
                        </button>
                    </div>
                    <div class="col-md-1  p-0" *ngIf="!disableFields">
                        <button id="more_vert" mat-icon-button [matMenuTriggerFor]="menu"
                            aria-label="Example icon-button with a menu">
                            <mat-icon class="m-0">more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                            <button id="file_upload" mat-menu-item (click)="uploadBar()">
                                <mat-icon class="material-icons-outlined secondary">file_upload</mat-icon>
                                <span class="m-0 fs-6">{{staticText?.rfx.toe.upld_items}}</span>
                            </button>
                        </mat-menu>
                    </div>
                </div>
            </div>
            <!-- <div class="row">
                            <div class="col-md-10">
                                <p class="heading-color fw-bold mb-0">{{staticText?.toe.title}}</p>
                            </div>                            
                        </div> -->
            <hr class="my-1">
            <div class="scope-height" [ngClass]="{'align-center':masterTermArray?.length == 0}">
                <div class="row" *ngIf="masterTermArray?.length == 0">
                    <!-- <div class="col-md-7 mx-auto">
                                    <img src="./assets/images/background.png" height="100%" width="100%">
                                </div> -->
                    <div class="col-md-10 mx-auto align-center">
                        <img src="./assets/images/no-record-found.png" class="no-record">
                    </div>
                    <div class="col-md-6 mx-auto">
                        <p id="toe_content_terms" class=" text-center text-muted">
                            {{staticText?.rfx.toe.content}} </p>
                    </div>
                </div>
                <div class="row" *ngIf="allTerms.length >= 0">
                    <div class="col-md-12 field-adjust">
                        <!-- center Pane -->
                        <app-tree *ngFor='let tree of masterTermArray;index as treeindex;even as eve'
                            [treeclass]="'pt-2 pb-2'" [disableEdit]="disableFields" [formInputDisable]="disableFields"
                            [treecardtype]="'formtree'" [treearray]="tree" [treeindex]="treeindex"
                            [parentTagName]="'termGoal'" [removeicon]="true" [formShow]="true"
                            [subParentTagname]="'termParent'+eve" [formTagName]="'termFormMeasurment'"
                            (formData)="createFormDataPayload($event)" (addkeyresult)="removeKeyColumn($event)">
                        </app-tree>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="col-md-11 mx-auto col-12 footer-position">
    <div class="row border-top">
        <div class="col-md-6 d-flex justify-content-start p-0">
            <button id="previousSectionterms" mat-button class="my-3 primary border button-hover text-white px-3 p-0"
                (click)="previousSection()">
                <mat-icon class="">chevron_left</mat-icon>
                <span class="m-0 pe-2">
                    {{staticText?.rfx.prev_section}}
                </span>
            </button>
        </div>
        <div class="col-md-6 d-flex justify-content-end p-0">
            <button id="nextSectiont_terms" mat-button class="my-3 next-color text-white px-3 p-0"
                (click)="nextSection()">
                <span class="m-0 ps-2">
                    {{staticText?.rfx.next_section}}
                </span>
                <mat-icon class="">chevron_right</mat-icon>
            </button>
        </div>
    </div>
</div>
<div class="col-md-4 col-12 mt-4 field-adjust">
    <div *ngIf="showTab=='add_terms'">
        <div>
            <div class="help-border helpleft htopborder p-2 d-flex justify-content-between border-bottom">
                <p id="add_contract_terms" class="fw-500 fs-18 mb-0">{{staticText?.toe.add_contract_terms}}</p>
                <span (click)="closeSidePane()">
                    <img src="./assets/images/Group 8814.svg">
                </span>
            </div>
            <div>
                <mat-card class="t-cardborder list-height p-2" style="border-radius: 0 0 0.8rem 0.8rem !important;">
                    <div class="col-md-12 pt-4">
                        <div class="col-md-12">
                            <mat-form-field appearance="outline" class="form-align" id="searchInput">
                                <input id="searchInput_3535" type="text" matInput class="w-100 m-0" id="searchInput"
                                    [(ngModel)]="searchText" placeholder="Search contract terms here...">
                                <mat-icon matPrefix class="muted iconPos me-1">search</mat-icon>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="recom-scope p-2">
                        <label
                            class="pt-1 mb-0 fw-bold fs-16 px-2 ">{{staticText?.rfx.toe.recom_contract_terms}}</label>
                        <app-list [list]="slaveTermListRecords" [disableEdit]="disableFields"
                            [patchToeRecords]="patchToeData" [TreecardTagname]="'recommendedFormGoal'"
                            [filterListKey]="'is_custom'" [filterListKeyValue]="false" [customkeydeleteicon]="true"
                            [TreecardParentTagname]="'recommendedFormGoalParent'"
                            [TreecardToeFormTagname]="'recommendedFormMeasurment'" type="termsList"
                            (levelSelected)="addColumn($event)" [content]="textContents" [searchBoxText]="searchText"
                            (levelClose)="deleteCustomColumn($event)">
                        </app-list>
                    </div>

                    <div class="recom-scope p-2 mt-3">
                        <div class="mx-2" *ngIf="!searchText && !disableFields">
                            <p id="toe_add_custom_terms" class="pt-1 mb-0 para-color d-flex cursor-pointer"
                                (click)="showCustomTerms()"
                                *ngxPermissionsOnly="['opportunity.add_termmeasurementcriteria']"><span>
                                    <mat-icon class="cursor-pointer" style="font-size: large;">add
                                    </mat-icon>
                                </span>{{staticText?.toe.add_custom_terms}}</p>
                        </div>
                        <label
                            class="pt-2 fw-bold fs-16 px-2 mb-0">{{staticText?.rfx.toe.custom_contract_terms}}</label>
                        <app-list [list]="slaveTermListRecords" [disableEdit]="disableFields"
                            [patchToeRecords]="patchToeData" [filterListKey]="'is_custom'" [filterListKeyValue]="true"
                            [TreecardTagname]="'CustomFormGoal'" [customkeydeleteicon]="true"
                            [TreecardParentTagname]="'CustomFormGoalParent'"
                            [TreecardToeFormTagname]="'customFormMeasurment'" type="termsList"
                            (levelSelected)="addColumn($event)" [content]="textContents" [searchBoxText]="searchText"
                            (levelClose)="deleteCustomColumn($event)">
                        </app-list>
                    </div>
                </mat-card>
            </div>
        </div>
    </div>
    <div *ngIf="showTab=='custom_terms'">
        <div class="help-border helpleft htopborder p-2 d-flex justify-content-between border-bottom">
            <p id="toe_add_custom_terms_terms" class="fw-bold fs-18 mb-0">{{staticText?.toe.add_custom_terms}}</p>
            <span class="cursor-pointer" (click)="showTab='help';">
                <img src="./assets/images/Group 8814.svg">
            </span>
        </div>
        <div class="row m-0" style="height:49rem">
            <mat-card class="matcorner1 p-2" style="border-radius: 0 0 0.8rem 0.8rem !important;">
                <div class="col-md-12 px-0">
                    <div class="col-md-12 p-1">
                        <form [formGroup]="termsForm">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>{{staticText?.toe.category}}</mat-label>
                                <input id="validateData" matInput [matAutocomplete]="auto" type="text"
                                    formControlName="category" (blur)="validateData('type')">
                                <mat-icon matSuffix class="muted">search</mat-icon>
                                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                                    <mat-option *ngFor="let option of filterOptionTitle | async" [value]="option">
                                        {{option.categoryTitle}}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                            <mat-error *ngIf="submitted && f.category.errors">
                                <small class="text-danger"
                                    *ngIf="f.category.errors.required">{{staticText?.toe.category_err}}</small>
                            </mat-error>
                            <mat-form-field class="py-1 w-100" appearance="outline">
                                <mat-label>{{staticText?.toe.sub_category}}</mat-label>
                                <input id="subCategory" type="text" matInput fullWidth status="basic"
                                    formControlName="subCategory">
                            </mat-form-field>
                            <mat-form-field class="py-1 w-100" appearance="outline">
                                <mat-label>{{staticText?.toe.measurement_criteria}}</mat-label>
                                <textarea type="text" matInput fullWidth status="basic"
                                    formControlName="measurement"></textarea>
                            </mat-form-field>
                            <mat-error *ngIf="submitted && f.measurement.errors">
                                <small class="text-danger"
                                    *ngIf="f.measurement.errors.required">{{staticText?.toe.measurement_criteria_err}}</small>
                            </mat-error>
                        </form>
                        <div>
                            <button id="addCustomTerms_terms" mat-button [disabled]="disableFields" mat-flat-button
                                class="next-color text-white w-100 border my-1"
                                (click)="addCustomTerms()">{{staticText?.toe.add_custom_terms}}
                            </button>
                        </div>
                    </div>
                </div>
            </mat-card>
        </div>
    </div>

    <div class="col-md-12 col-12" *ngIf="showTab=='upload'">
        <div class="help-border helpleft htopborder p-3 d-flex justify-content-between border-bottom">
            <p id="rfx_toe_upld_items_terms" class="fw-bold fs-18 mb-0">{{staticText?.rfx.toe.upld_items}}</p>
            <mat-icon class="float-end cursor-pointer text-muted" (click)="showTab = 'custom_terms';">close
            </mat-icon>
        </div>
        <div>
            <div class="d-flex">
                <mat-card class="matcorner1 list-height px-2" style="border-radius: 0 0 0.8rem 0.8rem !important;">
                    <div class="col-md-12 pt-2 bg-light">
                        <div class="col-md-12">
                            <p id="rfx_toe_upld_custom_terms_1" class="fw-bold">
                                {{staticText?.rfx.toe.upld_custom_terms}}</p>
                            <p id="text-muted" class="text-muted" innerHTML="{{staticText?.rfx.toe.terms_content}}">
                            </p>
                            <div class="p-2 mt-3 upload">
                                <div class="w-100 cursor-pointer d-flex justify-content-center"
                                    (click)="fileInput.click()">
                                    <mat-icon class="material-icons my-auto secondary">backup</mat-icon>
                                    <small id="rfx.toe.upld_file_3123" class="mt-1 ms-2 my-auto secondary">
                                        {{staticText?.rfx.toe.upld_file}}</small>

                                </div>
                                <input itemid="onFileSelected" hidden (change)="onFileSelected($event)"
                                    [disabled]="disableFields" #fileInput type="file" id="file">

                            </div>
                            <p id="common_doc_ext_maxsize_43245" class="fw-light font-size py-4"
                                style="color: rgb(199, 195, 195);">
                                {{staticText?.common.doc_ext_maxsize}}</p>
                        </div>
                    </div>
                    <div *ngIf="totalFiles?.length">
                        <mat-list>
                            <mat-list-item id="padding" *ngFor="let i of totalFiles"
                                class="row w-100 border rounded m-0 mb-3">
                                <div class="row px-3 w-100">
                                    <div class="col-md-1 pt-1 me-0 ps-0">
                                        <img *ngIf="i.doc == 'doc' || i.doc == 'docx'" src="./assets/images/word.svg"
                                            height="40">
                                        <img *ngIf="i.doc == 'pdf'" src="./assets/images/pdf.svg" height="40">
                                        <img *ngIf="i.doc == 'xls' || i.doc == 'xlsx'" src="./assets/images/excel.svg"
                                            height="40">
                                        <img *ngIf="i.doc == 'csv'" src="./assets/images/csv.png" height="40">
                                        <mat-icon
                                            *ngIf="i.doc !== 'pdf' && i.doc !== 'doc' && i.doc !== 'docx' && i.doc !== 'xls' && i.doc !== 'xlsx' && i.doc !== 'csv'"
                                            mat-list-icon class="material-icons my-auto fs-1 text-danger mr-2">
                                            description</mat-icon>
                                    </div>

                                    <div class="col-md-9 ms-2 pe-0">
                                        <div class="fw-bold fs-14">{{ i.file }}</div>
                                        <div class="fs-12"> {{ i.size | number : '1.2-2' }} MB </div>
                                    </div>
                                    <div class="col-md-1 pt-2 ps-0 d-flex">
                                        <img (click)="deleteFile(i)" *ngIf="!disableFields"
                                            src="./assets/images/delete_icon.svg" height="30" class="cursor-pointer">
                                        <img (click)="downloadFile(i)" class="px-2"
                                            src="./assets/images/download_icon.svg" class="cursor-pointer px-1"
                                            height="30">
                                    </div>
                                </div>
                            </mat-list-item>
                        </mat-list>
                    </div>
                </mat-card>
            </div>
        </div>
    </div>

    <div class="col-md-12 col-12" *ngIf="showTab=='help'">
        <div class="row">
            <app-list [list]="listItems" [type]="'termsHelp'"></app-list>
        </div>
    </div>
</div>