<div class="list-height">
    <!-- <div class="row px-1 py-2">
    <div class="col-md-6">
        <p class="pt-2 fw-bold blue mb-0">{{staticText?.rfx.score_range}} out of {{scoreRange || 10}}</p>
    </div>
</div> -->
    <mat-tab-group mat-align-tabs="start" [selectedIndex]="0" mat-stretch-tabs>
        <mat-tab label="Scope Framework">
            <div *ngFor="let i of selectedItems">
                <div class="my-1 px-0 evaluation-border col-12">
                    <div class="">
                        <p class="fw-bold pt-2 ps-3 mb-2  kleft-border next-color fs-12 key-border px-3"
                            style="color: #ffffff">{{i.category.display_name}}</p>
                    </div>
                    <div *ngFor="let s of i.category.scope; let i = index">
                        <div class="mb-2 fixBorder px-1 py-2">
                            <div class="row d-flex align-items-center pr-5" *ngFor="let c of s.criticality; let j = index">
                                <div class="col-md-8 d-flex justify-content-start">
                                    <p class="mb-0" style="padding-top: 3px;">{{i + 1}}.</p>
                                    <p class="pt-1 mb-0 ps-2 fs-16 text-black">{{s.description}}</p>
                                </div>
                                <div class="col-md-3 d-flex">
                                    <img src="assets/images/criticality.svg" matTooltip="{{c.title}}"
                                        matTooltipClass="example-tooltip" alt="image" class="ps-3">
                                    <div class="mx-2"
                                        [ngClass]="{'borderred': c.title == 'Mandatory' , 'bordergreen': c.title == 'Nice to have' , 'borderorange': c.title == 'Non-Negotiable','borderbrown':(c.title != 'Mandatory' && c.title != 'Non-Negotiable' && c.title != 'Nice to have')}">
                                        <p class="pt-1 mb-0 px-2 fs-16"
                                            [ngClass]="{'color-red': c.title == 'Mandatory' ,  'color-green': c.title == 'Nice to have', 'orange': c.title == 'Non-Negotiable','color-brown':(c.title != 'Mandatory' && c.title != 'Non-Negotiable' && c.title != 'Nice to have')}">
                                            {{c.title}}</p>
                                    </div>
                                </div>
                                <div class="col-md-1 d-flex">
                                    <img src="assets/images/weightage.svg" matTooltip="weightage"
                                        matTooltipClass="example-tooltip" alt="image" class="ps-3">
                                    <p class="pt-1 mb-0 ps-2 fs-16 fw-bold"
                                        [ngClass]="{'color-red': c.weightage < 30 ,  'blue': c.weightage > 75, 'orange': c.weightage < 75}">
                                        {{c.weightage}}%</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Terms of Engagement Framework">
            <div *ngFor="let i of toeMasterSelectedData; let ia = index ">
                <div class="my-1 px-0 evaluation-border col-12">
                    <div class="">
                        <p class="fw-bold pt-2 ps-3 mb-2  kleft-border next-color fs-12 key-border px-3"
                            style="color: #ffffff">{{i.name}}</p>
                    </div>
                    <div *ngFor="let s of i.opportunityterms; let ja = index">
                        <!-- {{s | json}} -->
                        <div class="mb-2 fixBorder px-1 py-2" *ngIf="s?.evals?.length > 0">
                            <div class="row d-flex align-items-center pr-5">
                                <div class="col-md-8 d-flex justify-content-start">
                                    <p class="mb-0" style="padding-top: 3px;">{{ja + 1}}.</p>
                                    <p class="pt-1 mb-0 ps-2 fs-16 text-black">{{s?.term?.title}}</p>
                                </div>

                                <div class="col-md-4" *ngFor="let eval of s.evals;let i = index;">
                                    <div class="row d-flex align-items-center">
                                        <div class="col-md-8 d-flex" *ngIf="i==0">
                                            <img src="assets/images/criticality.svg"
                                                matTooltip="{{eval.criticality.title}}"
                                                matTooltipClass="example-tooltip" alt="image" class="ps-3">
                                            <div class="mx-2"
                                                [ngClass]="{'borderred': eval.criticality.title == 'Mandatory' , 'bordergreen': eval.criticality.title == 'Nice to have' , 'borderorange': eval.criticality.title == 'Non-Negotiable','borderbrown':(eval.criticality.title != 'Mandatory' && eval.criticality.title != 'Non-Negotiable' && eval.criticality.title != 'Nice to have')}">
                                                <p class="pt-1 mb-0 px-2 fs-16"
                                                    [ngClass]="{'color-red': eval.criticality.title == 'Mandatory' ,  'color-green': eval.criticality.title == 'Nice to have', 'orange': eval.criticality.title == 'Non-Negotiable','color-brown':(eval.criticality.title != 'Mandatory' && eval.criticality.title != 'Non-Negotiable' && eval.criticality.title != 'Nice to have')}">
                                                    {{eval.criticality.title}}</p>
                                            </div>
                                        </div>
                                        <div class="col-md-4 d-flex" *ngIf="i==0">
                                            <img src="assets/images/weightage.svg" matTooltip="weightage"
                                                matTooltipClass="example-tooltip" alt="image" class="ps-3">
                                            <p class="pt-1 mb-0 ps-2 fs-16 fw-bold"
                                                [ngClass]="{'color-red': eval.weightage < 30 ,  'blue': eval.weightage > 75, 'orange': eval.weightage < 75}">
                                                {{eval.weightage}}%</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>