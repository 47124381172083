
@Pipe({
  name: 'formGroupName'
})
export class FormGroupNamePipe {
  transform(item:any,arr:any) {
    let formGroupNameIndex = 1000000;
    try {
      arr?.controls.forEach((res: any, index: any) => {
        if (res.controls.capabilityId.value == item.capabilityId && res.controls.uuid.value == item.uuid) {
          formGroupNameIndex = index;;
          throw BreakException;
        }
      });
    } catch (e) {
      if (e != BreakException) {
        throw e
      }
    }
    return formGroupNameIndex;
  }
}

import { OnDestroy, Pipe, ViewChild, ViewEncapsulation } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { NgxPermissionsService } from 'ngx-permissions';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BreadcrumbService } from 'src/app/shared/services/breadcrumb.service';
import { EvaluationService } from 'src/app/shared/services/evaluation.service';
import { ProgramService } from 'src/app/shared/services/program.service';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { MatStepper } from '@angular/material/stepper';
import { MessageService } from 'src/app/shared/services/message.service';
var _ = require('lodash');
var BreakException = {};

@Component({
  selector: 'app-evaluation-matrix',
  templateUrl: './evaluation-matrix.component.html',
  styleUrls: ['./evaluation-matrix.component.scss'],
  encapsulation:ViewEncapsulation.Emulated
})
export class EvaluationMatrixComponent implements OnInit, OnDestroy {
  staticText: any = (textConfiguration as any).default;
  @ViewChild('stepper') stepper: MatStepper|any;
  opportunityId: any;
  scopeCategory: any[] = [];
  scopeDetails: any[] = [];
  ColumnMode = ColumnMode;
  readonly headerHeight = 50;
  readonly rowHeight = 100;
  totalList: any;
  criticalityList: any;
  showSetting = false;
  showRG = false;
  groupScope: any[] = [];
  matrixForm: FormGroup | any;
  items: any;
  submitted = false;
  scopeList: any;
  invalid = false;
  showRightPane = false;
  showCriticality = false;
  showAddCriticality = false;
  critiTitle: any;
  critiDescription: any;
  tips = true;
  evaluationForm: FormGroup | any;
  scoreRangeList: any;
  weightageList: any;
  settingsSubmit = false;
  resSettings: any;
  evalSubmitted = false;
  showValError = false;
  evaluationList: any[] = [];
  scopeData: any[] = [];
  critiCheck = true;
  weightCheck = true;
  scoreId: any;
  data: any;
  disableFields: boolean=false;
  hasChange:boolean = false;
  isRedirectFrom = false;
  projectID:any='';
  sub:any;
  apiResponseReceived =false;
  opportunityInfo: any;
  breadcrumb: any;
  stepStates:any=[];
  opportunityTerms:any = [];
  evaluationTermMatrix: FormArray | any;;
  evaluationTermList:any = [];

  constructor(private ps: ProgramService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private spinner: NgxSpinnerService,
    private evaluationService: EvaluationService,
    private permissionsService: NgxPermissionsService,
    private breadCrumService: BreadcrumbService,
    private messageService: MessageService) {
      this.createForm()
     
  }

  ngOnInit(): void {    
    this.messageService.setGnews('Opportunity Evaluation Framework')
    this.sub = this.route
      .queryParams
      .subscribe((params:any) => {
        this.isRedirectFrom = params['redirectFrom'] || false;
        this.projectID = params['id']; 
      });
    const perm: any = JSON.parse(localStorage.getItem('permission') || '{}');
    this.permissionsService.loadPermissions(perm.permissions);
    this.route.parent?.parent?.params.subscribe((res: any) => {
      this.opportunityId = atob(res.id)
      setTimeout(() => {
        this.getOpportunityById();
        this.getTermMatrix();
      
      }, 300);
    })
    this.getCriticality();
    let oppInfo = localStorage.getItem('opportunity-info');
    this.opportunityInfo = typeof(oppInfo) === 'string' ? JSON.parse(oppInfo) : {};

    this.breadCrumService.breadcrumbs$.subscribe((bc: any) => {
      this.breadcrumb = bc;
    })
  }

  createForm() {
    this.matrixForm = new FormGroup({
      items: new FormArray([])
    });
    this.evaluationForm = this.formBuilder.group({
      score_range: [this.scoreId, Validators.required],
      criticality: [true, Validators.required],
      weightage: [true, Validators.required]
    });

    this.evaluationTermMatrix = this.formBuilder.array([])
  }

  createItem(data: any): FormGroup {
    return this.formBuilder.group({
      criticality: [data?.criticality?.id || '', Validators.required],
      weightage: [isNaN(data?.weightage) ?  0:parseFloat(data?.weightage), Validators.required],
      description: [data?.description || ''],
      capabilityId: [data?.capabilityId || ''],
      uuid:[data?.uuid || ''],
    });
  }
  getOpportunityById() {
    this.spinner.show();
    this.ps.getOpportunityEvoluationData(this.opportunityId).subscribe((resp: any) => {
     try{

      let breadCrumbFirst: any = [this.breadcrumb[0]];
      let breadCrumbLast: any = [this.breadcrumb[this.breadcrumb.length - 1]];
      let breadCrumb = [
        { label: this.opportunityInfo.portfolio, url: '/program', queryParams: {portfolio: this.opportunityInfo.portfolio_id} },
        { label: this.opportunityInfo.subportfolio, url: '/program', queryParams: {subportfolio: this.opportunityInfo.subportfolio_id} },
        { label: this.opportunityInfo.program, url: '/program', queryParams: {program: this.opportunityInfo.program_id} }
      ];
      breadCrumbFirst.push(...breadCrumb, ...breadCrumbLast)
  
      this.breadCrumService.setBreadcrumb(breadCrumbFirst);
      this.createEvoluationMatrixData(resp);
      this.apiResponseReceived=true;

     }catch(e){

     }
    
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      throw error;
    })
  }

  createEvoluationMatrixData(resp:any){
    let res = resp?.records[0];
    this.data = res;
    this.ps.setStatusValue(res?.status);
    if(res.status.slug == 'opportunity_evaluating' || res.status.slug == 'opportunity_sow_signed' || res.status.slug == 'opportunity_cancelled'){
      this.disableFields =true;
    }

   
    this.scopeList = res?.opportunity_scope;
    
    if(this.scopeList.length==0){
      this.scopeList = res?.opportunity_scope;
    }
    (this.scopeList || []).forEach((i: any) => {
      i.category.scope.forEach((e: any) => {
        const scopeObj = {
          name: i.category.display_name,
          description: e.description,
          capabilityId: i.category.id,
          uuid:e.uuid,
          criticality: '',
          weightage: ''
        }
        if (e.criticality) {
          scopeObj.criticality = e.criticality[0];
          scopeObj.weightage = e.criticality[0]?.weightage;
        }
        this.scopeCategory.push(scopeObj);
      });
    })
    var groupScope = _.groupBy(this.scopeCategory, 'name');
    this.groupScope = Object.keys(groupScope);
    this.groupScope = this.groupScope.map(item => {
      this.spinner.hide();
      return { name: item, values: groupScope[item], showValidation: false, id: groupScope[item][0].capabilityId }
    })
    this.groupScope.forEach(i => {
      i.values?.forEach((element: any) => {
        this.items = this.matrixForm?.get('items') as FormArray;
        this.items?.push(this.createItem(element));
      });
    })
    
    // if(this.disableFields){
    //   this.matrixForm.get("items").controls.forEach((control:any,index:number) => {
    //     this.matrixForm.get("items").controls[index].disable();
    //   });
    // }
    const matrixFormValue = this.matrixForm?.value;
  this.matrixForm?.valueChanges.subscribe((value:any) => {
    this.hasChange = Object.keys(matrixFormValue).some(key => this.matrixForm?.value[key] != matrixFormValue[key])
  });
  }

  getCriticality() {
    this.ps.getOpportunityFormData('evaluation_matrix').subscribe((res: any) => {
      this.totalList = res.records;
      this.totalList.map((i: any) => {
        if (i.field_name == 'evaluation_criticality') {
          this.criticalityList = i.field_values;
        }
        if (i.field_name == 'evaluation_score_range') {
          this.scoreRangeList = i.field_values;
          i.field_values.forEach((e: any) => {
              if(e.name == "Score out of 10") {
                this.scoreId = e.id;
              }
          });
        }
        if (i.field_name == 'evaluation_weightage') {
          this.weightageList = i.field_values;
          this.weightageList = this.weightageList.reverse();
        }
      });
      if(this.opportunityId)
        this.getSettings();
    }, error => {
      throw error;
    })
  }
  matrixValueFormArray() {
    return this.matrixForm.get('items') as FormArray;
  }
  onWeightageChange(event: any, option: any) {    
    const uniqueFormvalue = this.matrixForm.controls.items.value.filter((i: any) => i.capabilityId == option.capabilityId);
    const total = uniqueFormvalue.reduce((accumulator: any, currentValue: any) => accumulator + currentValue.weightage, 0);

    const addValue = this.groupScope.find(i => i.id == option.capabilityId);
    if (total != 100) {
      addValue.showValidation = true;
      this.showValError = true;
    } else {
      addValue.showValidation = false;
      this.submitted = true;
      this.showValError = false;
    }
  }

  onWeightageTermsChange(event: any, category: any, terms: any) {    
    const uniqueFormvalue = this.evaluationTermMatrix.value.filter((i: any) => i.cat_id == category.value.cat_id);
    const total = uniqueFormvalue[0].terms.reduce((accumulator: any, currentValue: any) => accumulator + currentValue.weightage, 0)

    const addValue = this.evaluationTermMatrix.value.find((i: any) => i.cat_id == category.value.cat_id);
    if (total != 100) {
      addValue.showValidation = true;
      this.showValError = true;
    } else {
      addValue.showValidation = false;
      this.submitted = true;
      this.showValError = false;
    }
  }

  crticalityChange(e: any) {
  }

  get isMandatory(): boolean {
    console.log();
    return true;
    // return 'Mandatory';
  }

  toggle(e: any, form: any, j: any, i: any){
    let criticality = this.criticalityList.find((e: any) => e.title == 'Nice to have').id
    if(e.checked){
      criticality = this.criticalityList.find((e: any) => e.title == 'Mandatory').id
    }

    if(form == 'matrixForm'){
      this.matrixForm.get('items').controls.forEach((control: FormControl, index: number) => {
        if(control.value.uuid == this.groupScope[j].values[i].uuid){
          control?.get('criticality')?.setValue(criticality)
        }
      });
    }
    if(form == 'evaluationTermMatrix'){
      this.evaluationTermMatrix.controls[j].value.terms[i].criticality = criticality;
    }
   
  }

  showSettings(type: any) {
    this.showRightPane = true;
    if (type == 'setting') {
      this.showRG = false;
      this.showSetting = true;
      this.showCriticality = false;
      this.showAddCriticality = false;
    } else if (type == 'rangeGuide') {
      this.showSetting = false;
      this.showRG = true;
      this.showCriticality = false;
      this.showAddCriticality = false;
    }
  }
  showRangeGuide() {
    this.showSetting = false;
    this.showRG = true;
  }

  updateEvaluation() {
    this.matrixForm.get('items').controls.forEach((element: any) => {
        if(!element.get('criticality').value){
          let id = this.criticalityList.filter((e: any) => e.title == 'Nice to have')[0].id
          element.get('criticality').setValue(id)
        }
      
    });
    if(this.disableFields || !this.hasChange){
      this.ps.setValue(2);
      this.stepper.next();
          
      this.ps.getOppData(this.opportunityId).subscribe((res)=>this.ps.setStatusValue(res?.status));
      // this.router.navigate([`rfx/`+btoa(this.opportunityId)+`/opportunity/terms`]).then(() => { });
      return;
    }
    if (this.matrixForm.status == "INVALID") {
      this.toastrService.error('Please fill all the fields');
      return;
    }
    this.evalSubmitted = true;
    this.spinner.show();
    this.evaluationService.getSettings(this.opportunityId).subscribe((res: any) => {
      this.spinner.hide();
      this.items = this.matrixForm.get('items') as FormArray;
      this.resSettings = res;
      if(this.resSettings.criticality == false) {
        this.critiCheck = false;
        this.clearValidatorCriticality();  
      } else {
        this.critiCheck = true;
        this.setValidatorCriticality();
      }

      if(this.resSettings.weightage == false) {
        this.weightCheck = false;
        this.clearValidatorWeightage();      
      } else {
        this.weightCheck = true;
        this.setValidatorWeightage();
      }
      this.patchForm(res);

      if (this.matrixForm.status == "INVALID") {
        this.toastrService.error('Please fill all the fields');
        return;
      }
      else {
        let matrixForm = this.matrixForm.controls.items.value;
        const evaluation_Data: any = {
          evaluation_matrix: []
        };
        (this.scopeList || []).forEach((s: any) => {
          let scope_category: any = {
            scope_category: s.category.id,
            scope: []
          };
          (s.category.scope || []).forEach((i: any) => {
            (matrixForm || []).forEach((si: any) => {
              if (i.uuid === si.uuid && s.category?.id === si.capabilityId ) {
                const scope: any = {
                  uuid: i.uuid,
                  criticality: si.criticality,
                  weightage: si.weightage
                };
                scope_category.scope.push(scope);
              }
            });  
          })
          evaluation_Data.evaluation_matrix.push(scope_category);
        })
        const clonedEvaluationData = JSON.parse(JSON.stringify(evaluation_Data.evaluation_matrix));
        let totals: any = [];
        (clonedEvaluationData || []).forEach((x: any) => {
          let weight: number = 0;
          (x.scope || []).forEach((scopeWeight: any, index: number) => {
            weight += scopeWeight.weightage;
            if (x.scope.length - 1 === index) {
              x.weightage = weight;
              const obj = totals.find((o: any) => o.scope_category === x.scope_category);
              if (obj) {
                obj.weightage = obj.weightage + x.weightage;
              } else {
                totals.push(x);
              }
            }
          });
        });
        let isWeightageLow = false;
        for(let t=0;t<totals.length;t++){
       // totals.forEach((t: any) => {
          if(totals[t]?.weightage !== 100){
            isWeightageLow = true;
            if(this.weightCheck == true) {
              this.toastrService.error('Please check weightage value is equal to 100');
              break;
            }
          }
        }
        //});
        if(isWeightageLow){
          if(!!this.weightCheck) {
          return;
          }
        }
        this.ps.updateOpportunity(this.opportunityId, evaluation_Data).subscribe((res: any) => {
          this.ps.setValue(2);
          this.ps.getOppData(this.opportunityId).subscribe((res)=>this.ps.setStatusValue(res?.status));
          this.stepper.next();
          // this.router.navigate([`rfx/`+btoa(this.opportunityId)+`/opportunity/terms`]).then(() => {
           // window.location.reload();
          // });
        }, error => {
          this.spinner.hide();
          throw error;
        })
      }
    }, err => {
      console.log("test");
      this.spinner.hide();
      throw err;
    })

  }

  previous() {    
    this.ps.setValue(1)
    this.ps.getOppData(this.opportunityId).subscribe((res)=>this.ps.setStatusValue(res?.status));
    this.router.navigate([`rfx/`+btoa(this.opportunityId)+`/opportunity/distribution`]).then(() => {
      //window.location.reload();
    });
  }

  matrixValueFormArrayControls(i: any): FormGroup {
    return this.matrixValueFormArray().controls[i] as FormGroup;
  }

  get f() {
    return this.evaluationForm.controls;
  }

  addCriticality() {
    this.showAddCriticality = true;
    this.showCriticality = false;
    this.showSetting = false;
  }
  createCrticality() {
    if (this.critiTitle && this.critiDescription) {
      var payload = {
        title: this.critiTitle,
        description: this.critiDescription
      }
      this.evaluationService.createCriticality(payload).subscribe((res: any) => {
        if (res == false) {
          this.spinner.hide();
          this.toastrService.error('UH OH! This crticality already exists, Please create a crticality that didn’t exist in the system');
          return;
        } else {
          this.getCriticality();
          this.toastrService.success('Critciality added successfully');
          this.spinner.hide();
          this.showAddCriticality = false;
          this.showCriticality = true;
        }
      }, error => {
        throw error;
      })
    } else {
      this.toastrService.error('Please fill all the fields');
    }
    this.critiTitle = '';
    this.critiDescription = '';
  }
  updateSetting() {
    this.settingsSubmit = true;
    if (this.evaluationForm.status == "INVALID") {
      return;
    }
    const payload = {
      score_range: this.evaluationForm.value.score_range,
      criticality: this.evaluationForm.value.criticality == '1' ? true : false,
      weightage: this.evaluationForm.value.weightage == '1' ? true : false,
      opportunity: this.opportunityId
    }
    if (!payload.criticality && !payload.weightage) {
      for (let index = 0; index < this.items.length; index++) {
        this.items.controls[index].controls.criticality.clearValidators();
        this.items.controls[index].controls.criticality.updateValueAndValidity();
        this.items.controls[index].controls.weightage.clearValidators();
        this.items.controls[index].controls.weightage.updateValueAndValidity();
      }
      this.evaluationService.updateSettings(payload).subscribe((data: any) => {
        this.toastrService.success('Settings updated successfully');
        if(this.opportunityId)
          this.getSettings(); 
      }, err => {
        throw err;
      })
    } else {
      this.evaluationService.updateSettings(payload).subscribe((data: any) => {
        this.toastrService.success('Settings updated successfully');
        if(this.opportunityId)
          this.getSettings();
      }, err => {
        throw err;
      })
    }
  }
  getSettings() {
    this.evaluationService.getSettings(this.opportunityId).subscribe((res: any) => {
      if(!res.id){
        const payload = {
          score_range: this.evaluationForm.value.score_range,
          criticality: this.evaluationForm.value.criticality == '1' ? true : false,
          weightage: this.evaluationForm.value.weightage == '1' ? true : false,
          opportunity: this.opportunityId
        }
          this.evaluationService.updateSettings(payload).subscribe((data: any) => {
          }, err => {
            throw err;
          })
      }else{
        this.items = this.matrixForm.get('items') as FormArray;

        this.resSettings = res;
        if(this.resSettings.criticality == false) {
          this.critiCheck = false;
          this.clearValidatorCriticality();  
        } else {
          this.critiCheck = true;
          this.setValidatorCriticality();
        }
  
        if(this.resSettings.weightage == false) {
          this.weightCheck = false;
          this.clearValidatorWeightage();      
        } else {
          this.weightCheck = true;
          this.setValidatorWeightage();
        }
        this.patchForm(res);
      }
    }, err => {

    })
  }

  setValidatorCriticality() {
    this.items.controls.forEach((c: any) => c.controls.criticality.setValidators([Validators.required]));
    this.items.controls.forEach((c: any) => c.controls.criticality.updateValueAndValidity());
  }

  setValidatorWeightage() {
    this.items.controls.forEach((c: any) => c.controls.weightage.setValidators([Validators.required]));
    this.items.controls.forEach((c: any) => c.controls.weightage.updateValueAndValidity());
  }

  clearValidatorCriticality() {
    this.items.controls.forEach((c: any) => c.controls.criticality.clearValidators());
    this.items.controls.forEach((c: any) => c.controls.criticality.updateValueAndValidity());
  }

  clearValidatorWeightage() {
    this.items.controls.forEach((c: any) => c.controls.weightage.clearValidators());
    this.items.controls.forEach((c: any) => c.controls.weightage.updateValueAndValidity());
  }


  patchForm(data: any) {
    this.evaluationForm.patchValue({
      score_range: data.score_range,
      criticality: data.criticality == true ? 1 : 0,
      weightage: data.weightage == true ? 1 : 0,
    })   
    if(this.disableFields)
      this.evaluationForm.disable();
  }

  deleteCriticality(e: any) {
    this.spinner.show();
    this.evaluationService.deleteCriticality(e.id).subscribe((data: any) => {
      this.toastrService.success('Deleted Successfully');
      this.spinner.hide();
      this.getCriticality();
    }, err => {
      this.spinner.hide();
      throw err;
      })
  }
  
  clickPrevious(){
    this.router.navigate([`view/${this.projectID}/overview`])
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }


  getTagObject(id: any){
    return {
      entity_id: id,
      entity_type: "opportunity"
    }
  }


  selectedStepper(e: StepperSelectionEvent) {
    this.stepper.steps.forEach((step:any,index:number) => {
      if( index == e.selectedIndex){
        this.stepStates[index] = 'edit';
      }else if(!step.completed && index != e.selectedIndex){
        this.stepStates[index] = 'number';
      }else if(step.completed && index != e.selectedIndex){
        this.stepStates[index] = 'done';
      }      
    });
  }

  back(){
    this.stepper.previous();
  }

  updateEvaluationTerms(){
    var terms:any = [];
    this.evaluationTermMatrix.controls.forEach((control:any)=>{
      control.value.terms.forEach((term:any)=>{
        var data;
        // if(term.criticality){
        data = {
          id:term.id?term.id:null,
          opportunity_term_id: term.term_id,
          criticality_id: term.criticality ? term.criticality : this.criticalityList.filter((e: any) => e.title == 'Nice to have')[0].id,
          weightage:term.weightage
        }
        // }
        if(data){
          terms.push(data);
        }
      });
    });

    let validation = false;
    this.evaluationTermMatrix.value.forEach((element: any) => {
      if(element?.showValidation == true){
        validation = true;
        return;
      }
    });

    if (this.evaluationTermMatrix.status == "INVALID") {
      this.toastrService.error('Please fill all the fields');
      return;
    }
    let bool = false
    terms.forEach((element: any) => {
      if(!element.weightage && element.weightage != 0){
        bool = true
      }
    });

    if(bool) {
      this.toastrService.error('Please fill all the fields');
      return;
    }

    if(validation){
      this.toastrService.error('Please check weightage value is equal to 100');
      return;
    }

    this.ps.updateTermMatrix(this.opportunityId,terms).subscribe((resp: any) => {
      this.router.navigate([`rfx/`+btoa(this.opportunityId)+`/opportunity/more-info`]).then(() => { });
    });
    
  }

  getTermMatrix(){
    this.ps.getTermMatrix(this.opportunityId).subscribe((resp: any) => {
      this.evaluationTermList = resp;
      this.getSelectedTerms();
    });
    
  }
  getSelectedTerms(){
    this.ps.getSelectedTerms(this.opportunityId).subscribe((resp: any) => {
      resp.terms_hierarchy.forEach((cat:any,i:any) => {
        if(resp.selected_term_ids.find((x:any)=>x.term_category_id == cat.id)){
          this.addCategories(cat);
          cat.subcategories.forEach((subCat:any) => {
            subCat.terms.forEach((term:any) => {
              let term_elm = resp.selected_term_ids.find((x:any)=>x.term_id == term.id)
              if(term_elm){
                term.opp_term_id = term_elm.id; 
                term.comment = term_elm.comments;
                this.addCatTerms(i,term,cat)
              }
            });
          });
        }
        });
      });
  }
  addCategories(cat:any){
    var data = {
      cat_id:cat.id,
      cat_name:cat.name,
      terms:[]
    }
    this.evaluationTermMatrix.push(
      this.formBuilder.control(data));
  }
  addCatTerms(catIndex:number,term:any,cat:any){
    var id:any = '',criticality:any='',weightage:any='';
    this.evaluationTermList.forEach((element:any) => {
      // id='',criticality='',weightage='';
      if(element.opportunity_term.id == term.opp_term_id){
        id = element?.id;
        criticality=element.criticality.id
        weightage=element?.weightage
      }
    });
    let index = this.evaluationTermMatrix.value.findIndex((x:any)=>x.cat_id == cat.id);
    this.evaluationTermMatrix.controls[index].value.terms.push({
      id: id ? id:null,
      term_id: term.opp_term_id,
      term_name: term.title,
      criticality:criticality ? criticality:'',
      weightage:weightage ? weightage:'',
      comment: term.comment,
    })
  }

  getCriticalityId(title: any){
    return this.criticalityList.find((e: any) => e.title === title).id
  }


}