import { ViewEncapsulation } from '@angular/core';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { EvaluationService } from 'src/app/shared/services/evaluation.service';
import { ProgramService } from 'src/app/shared/services/program.service';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
import { GetProposalService } from 'src/app/shared/services/get-proposal.service';

@Component({
  selector: 'app-preview-evaluation',
  templateUrl: './preview-evaluation.component.html',
  styleUrls: ['./preview-evaluation.component.scss'],
  encapsulation:ViewEncapsulation.Emulated
})
export class PreviewEvaluationComponent implements OnInit {
  staticText: any = (textConfiguration as any).default;
  opportunityId: any;
  selectedItems: any;
  ColumnMode = ColumnMode;
  readonly headerHeight = 50;
  readonly rowHeight = 50;
  toeMasterSelectedData:any = [];
  @Output() sendSelected = new EventEmitter<{}>();
  exportPayload: any;
  evaluationModel = true;
  scoreRange: any;

  constructor(
    private ps: ProgramService,
    private route: ActivatedRoute,
    private evaluationService: EvaluationService,
    private proposalService : GetProposalService,
  ) { }

  ngOnInit(): void {
    this.route.parent?.parent?.params.subscribe((res: any) => {
      this.opportunityId = atob(res.id);
      this.getOpportunityById();
      this.getOpportunityTermsMatrix();
      this.getSettings();
    });
  }

  showOptions(e: any) {
    if(e.source.id == 'evaluationCheck') {
      this.ps.getOpportunityFormData('evaluation_matrix').subscribe((res: any) => {
        const sectionId = res.records[0].form_details.id;
        this.exportPayload = {
          section: sectionId,
          export: e.checked
        };
        this.sendSelected.emit(this.exportPayload);
      }, error => {
        throw error;
      });
    }
  }

  getSettings() {
    this.evaluationService.getSettings(this.opportunityId).subscribe((res: any) => {
      this.scoreRange = res.score_range_details?.end_range;
    }, error => {
      throw error;
    })
  }

  getOpportunityById() {
    this.ps.getOpportunityById(this.opportunityId).subscribe((res: any) => {
      this.selectedItems = res.opportunity_scope;
    }, error => {
      throw error;
    });
  }

  async getOpportunityTermsMatrix(){
    await this.proposalService.getOpportunityTermsDetails(this.opportunityId).subscribe((resp: any) => {
     this.toeMasterSelectedData = resp;
    });
  }
}
