import { ViewEncapsulation } from '@angular/core';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { map, startWith } from 'rxjs/operators';
import { ProgramService } from 'src/app/shared/services/program.service';

import * as textConfiguration from 'src/assets/static-text-configuration.json';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  encapsulation:ViewEncapsulation.Emulated
})
export class ProfileComponent implements OnInit {
  staticText: any = (textConfiguration as any).default;
  profileForm: FormGroup | any;
  data: any;
  profileList: any;
  clasificationList: any;
  triggerList: any;
  workList: any;
  statusList: any;
  categoryList: any;
  @Output() profile = new EventEmitter<{}>();
  profSumbit: any;
  @Input()
  set submitValue(data: any) {
    this.profSumbit = data;
  }
  constructor(
    private fb: FormBuilder,
    private toastrService: ToastrService,
    private router: Router,
    private ps: ProgramService,
    private spinner: NgxSpinnerService,
  ) { 
  }

  ngOnInit(): void {
    this.createForm();
    this.getProfileDate();
    this.data = {
      category: false,
      classification: false,
      triggers: false,
      work: false,
      fundingStatus: false,
    }
  }

  createForm() {
    this.profileForm = this.fb.group({
      category: ['', Validators.required],
      classification: ['', Validators.required],
      triggers: ['', Validators.required],
      work: ['', Validators.required],
      fundingStatus: ['', Validators.required]
    });
    this.profileForm.valueChanges.subscribe((item: any) => {
      console.log('form value changed')
      console.log(item)
      this.profile.emit(item);
  })
  }

  get f() {
    return this.profileForm.controls;
  }

  getProfileDate() {
    this.ps.getOpportunityFormData('opportunity_profile').subscribe((res: any) => {
      this.profileList = res.records;
      // console.log('profileList', this.profileList);
      this.profileList.map((i: any) => {
        if (i.field_name == "opportunity_category") {
          this.data.category = i.field_status;
          this.categoryList = i.field_values;
          this.data.disCategory = i.display_name;
          if (!i.field_status) {
            this.profileForm.removeControl('name');
          }
        }
        if (i.field_name == "opportunity_classification") {
          this.data.classification = i.field_status;
          this.clasificationList = i.field_values;
          this.data.disClassification = i.display_name
          if (!i.field_status) {
            this.profileForm.removeControl('classification');
          }
        }
        if (i.field_name == "opportunity_trigger") {
          this.data.triggers = i.field_status;
          this.triggerList = i.field_values;
          this.data.disTriggers = i.display_name
          if (!i.field_status) {
            this.profileForm.removeControl('triggers');
          }
        }
        if (i.field_name == "opportunity_work_type") {
          this.data.work = i.field_status;
          this.workList = i.field_values;
          this.data.disWork = i.display_name
          if (!i.field_status) {
            this.profileForm.removeControl('work');
          }
        }
        if (i.field_name == "funding_status") {
          this.data.fundingStatus = i.field_status;
          this.statusList = i.field_type;
          this.data.disfundingStatus = i.display_name
          if (!i.field_status) {
            this.profileForm.removeControl('fundingStatus');
          }
        }
      })
    });
  }

}
