<div class="parameter-holder">
    <div *ngIf="buttonName == 'Parameter'">
        <div class="help-border helpleft htopborder p-3 d-flex justify-content-between border-bottom">
            <p class="fw-500 fs-18 mb-0"> {{staticText?.rfx.setup.add_parameter}} </p>
        </div>
        <div class="p-3">
            <mat-form-field appearance="outline" class="w-100" id="searchInput">
                <input id='search' type="text" matInput class="w-100" [(ngModel)]="searchText"
                    placeholder="Search parameter here...">
                <mat-icon matPrefix class="muted iconPos">search</mat-icon>
            </mat-form-field>
            <div class="recom-scope p-2">
                <label class="py-1 fw-500 fs-16">{{staticText?.rfx.setup.recom_params}}</label>
                <app-list [list]="items" [disableEdit]="disableFields" type="list"
                    (levelSelected)=addColumn($event) 
                    [searchBoxText]="searchText" [content]="textContents"
                    [search]="search">
                </app-list>
            </div>
            <div class="recom-scope p-2 mt-2">
                <div >
                    <p class="pt-1 para-color d-flex cursor-pointer" (click)="goToAdd()"
                        *ngxPermissionsOnly="['opportunity.add_customparameter']"><span>
                            <mat-icon id="add_custom" class="cursor-pointer" style="font-size: large;">add
                            </mat-icon>
                        </span>{{staticText?.delivery.okr_log.add_custom}}</p>
                </div>
                <div *ngIf="showAdd" class="mb-3">
                    <div class="row">
                        <div class="col-md-8 field-adjust">
                            <span>
                                <mat-form-field class=" w-100 " appearance="outline">
                                    <mat-label class="label-design">{{staticText?.rfx.setup.field_title}}</mat-label>
                                    <input id='parameterName' type="text" matInput fullWidth status="basic"
                                        [(ngModel)]="parameterName" [maxlength]="maxChars">
                                    <button id='clearSearchField' mat-button mat-icon-button matSuffix
                                        (click)="clearSearchField()" *ngIf="parameterName && !disableFields">
                                        <img src="./assets/images/Group 8814.svg" class="cursor-pointer"
                                            style="width:20px;">
                                    </button>
                                </mat-form-field>
                            </span>
                        </div>
                        <div class="col-md-4">
                            <button id='customPar' mat-button mat-flat-button
                                    class="next-color text-white w-100 border my-2" (click)="customPar()"
                                    [disabled]="disableFields">{{staticText?.rfx.setup.add_text_field}}
                            </button>
                        </div>
                    </div>
                </div>

                <label class="fw-500" *ngIf="showAddField">{{staticText?.rfx.setup.custom_params}}</label>
                <app-list [list]="customItems" [disableEdit]="disableFields" type="list" *ngIf="showAddField"
                    [searchBoxText]="searchText" (levelSelected)=addCustomColumn($event)
                    [content]="textContents" (levelClose)=removeCustom($event)>
                </app-list>
            </div>
        </div>
    </div>

    <div *ngIf="buttonName == 'Function' && !isMemberSelected">
        <div class="help-border helpleft htopborder p-3 d-flex justify-content-between border-bottom">
            <p class="fw-500 fs-18 mb-0"> {{staticText?.rfx.setup.functions}}</p>
        </div>
        <div class="p-3">
            <div class="col-md-12 px-0">
                <div class="col-md-12 p-0 field-adjust">
                    <mat-form-field appearance="outline" class="w-100" id="searchInput">
                        <input id='searchNew' type="text" matInput class="w-100 m-0" [(ngModel)]="searchText"
                            placeholder="Search function here...">
                        <mat-icon matPrefix class="muted iconPos">search</mat-icon>
                    </mat-form-field>
                </div>
            </div>
            <div class="recom-scope p-2">
                <label class="fs-16 mb-2 text-black fw-500">{{staticText?.rfx.setup.rec_funtion}} </label>
                <app-list [list]="functionList" [disableEdit]="disableFields" type="function"
                    [searchBoxText]="searchText" (levelSelected)=addFunctionColumn($event) [content]="textContents"
                    [search]="search">
                </app-list>
            </div>
            <div class="recom-scope p-2 mt-2">
                <div class="" *ngIf="showAddField && !disableFields">
                    <div class="mx-2">
                        <!-- *ngxPermissionsOnly="['opportunity.add_function']" -->
                        <p class="para-color d-flex cursor-pointer mb-1" (click)="goToFunction()"
                            ><span>
                                <mat-icon class="cursor-pointer" style="font-size: large;">add
                                </mat-icon>
                            </span>{{staticText?.rfx.setup.add_custom_fun}}</p>
                    </div>
                </div>
                <div *ngIf="showAdd" class="mb-3">
                    <div class="col-md-12 field-adjust p-0">
                        <mat-form-field class="py-2 w-100 pt-2" appearance="outline">
                            <mat-label class="label-design">{{staticText?.rfx.setup.fun_name}}</mat-label>
                            <input id='functionNameNew' type="text" matInput fullWidth status="basic"
                                placeholder="Enter {{staticText?.rfx.setup.fun_name | lowercase}} here..."
                                [(ngModel)]="functionName" [maxlength]="maxChars">
                            <button id='close' mat-button mat-icon-button matSuffix (click)="clearFunctionField()"
                                *ngIf="functionName">
                                <mat-icon class="fs-5">close</mat-icon>
                            </button>
                        </mat-form-field>
                        <div>
                            <button id='addFunction' mat-button mat-flat-button
                                class="next-color text-white w-100 border my-2" (click)="addFunction()"
                                [disabled]="disableFields">{{staticText?.rfx.setup.add_new_fun}}
                            </button>
                        </div>
                    </div>
                </div>
                <label class="fs-16 pt-1 text-black fw-500 mb-1"
                    *ngIf="showAddField">{{staticText?.rfx.setup.custom_fun}}</label>
                <app-list [list]="customFunctionList" [disableEdit]="disableFields" type="function"
                    [searchBoxText]="searchText" *ngIf="showAddField"
                    (levelSelected)=addCustomFunctionColumn($event) [content]="textContents"
                    (levelClose)=removeFunctionColumn($event) (functionClose)="removeFunction($event)">
                </app-list>
            </div>
        </div>
    </div>

    <div class="" *ngIf="isMemberSelected && buttonName == 'Function' && !disableFields">
        <div class="help-border helpleft htopborder  p-3 d-flex justify-content-between border-bottom">
            <p class="fs-18 fw-500 mb-0" style="color: black;">{{staticText?.rfx.setup.add_team}}</p>
        </div>
        <div class="p-3">
            <div class="row">
                <div class="col-md-12 py-2 px-0">
                    <div class="col-md-12 p-0 field-adjust">
                        <mat-form-field appearance="outline" class="w-100" id="searchInput">
                            <input id='searchTextNew1' type="text" matInput class="w-100 m-0"
                                [(ngModel)]="searchText" placeholder="Search team member here...">
                            <mat-icon matPrefix class="muted iconPos">search</mat-icon>
                        </mat-form-field>
                    </div>
                </div>
                <app-list [list]="teamList" [disableEdit]="disableFields" type="team"
                    [searchBoxText]="searchText" (levelSelected)=addChips($event) [content]="textContents"
                    [search]="search">
                </app-list>
            </div>
        </div>
    </div>

    <div *ngIf="buttonName == 'Delivery Partner' && !disableFields">
        <div class="help-border helpleft htopborder p-3 d-flex justify-content-between border-bottom">
            <p class="fw-500 fs-18 mb-0">{{staticText?.proposal.proposal_overview.add_dp}}</p>
        </div>
        <div class="p-3">
            <mat-form-field appearance="outline" class="" id="searchInput" class="w-100">
                <input id='searchText2S' type="text" matInput class=" m-0" [(ngModel)]="searchText"
                    placeholder="Search vendor here...">
                <mat-icon matPrefix class="muted iconPos">search</mat-icon>
            </mat-form-field>
            <div style="margin:0 -15px;">
                <app-list [list]="vendorList" type="vendor" [disableEdit]="disableFields"
                    [searchBoxText]="searchText" (levelSelected)=addVendorColumn($event) [content]="textContents"
                    [search]="search">
                </app-list>
            </div>
        </div>
    </div>

    <div *ngIf="buttonName == 'Timeline' && !disableFields">
        <div class="help-border helpleft htopborder p-3 d-flex justify-content-between border-bottom">
            <p class="fw-500 fs-18 mb-0"> {{staticText?.rfx.setup.add_timelines}}</p>
        </div>
        <div class="p-3">
            <div class="col-md-12 pt-1 px-0">
                <div class="col-md-12 p-0 field-adjust">
                    <mat-form-field appearance="outline" class="w-100" id="searchInput">
                        <input id='Searchtimelinehere' type="text" matInput class="w-100 m-0"
                            [(ngModel)]="searchText" placeholder="Search timeline here...">
                        <mat-icon matPrefix class="muted iconPos">search</mat-icon>
                    </mat-form-field>
                </div>
            </div>
            <div class="recom-scope p-2">
                <label class="py-1 fw-500 fs-16 pt-3">{{staticText?.rfx.setup.recom_timeline}}</label>
                <app-list [list]="timelineList" type="timeList" [disableEdit]="disableFields"
                    [searchBoxText]="searchText" (levelSelected)="addTimelineColumn($event)"
                    [content]="textContents" [search]="search">
                </app-list>
            </div>
            <div class="recom-scope p-2 mt-2">
                <div *ngIf="showAddField && !disableFields">
                    <div class="mx-2">
                        <!-- *ngxPermissionsOnly="['opportunity.add_timeline']" -->
                        <p class="pt-2 para-color d-flex cursor-pointer mb-1" (click)="goToTimeline()"
                            
                            ><span>
                                <mat-icon class="cursor-pointer" style="font-size: large;">add
                                </mat-icon>
                            </span>{{staticText?.rfx.setup.create_custom_timeline}}</p>
                    </div>
                </div>
                <!-- *ngIf="showAdd" -->
                <div >
                    <div class="row">
                        <div class="col-md-8 field-adjust">
                            <mat-form-field class="py-2 w-100 pt-4" appearance="outline">
                                <mat-label class="label-design">{{staticText?.rfx.setup.timeline_name}}</mat-label>
                                <input id='timelineName' type="text" matInput fullWidth status="basic"
                                    [(ngModel)]="timelineName" [maxlength]="maxChars">
                                <button id="clearSearchField" mat-button mat-icon-button matSuffix
                                    (click)="clearSearchField()" *ngIf="timelineName">
                                    <mat-icon class="fs-5">close</mat-icon>
                                </button>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <button id='addNewTimeline' mat-button mat-flat-button
                                class="next-color text-white w-100 border my-2" (click)="addNewTimeline()"
                                [disabled]="disableFields">{{staticText?.rfx.setup.add_custom_timeline}}
                            </button>
                        </div>
                    </div>
                </div>
                <label class="py-1 fw-500 fs-16 mb-1"
                    *ngIf="showAddField">{{staticText?.rfx.setup.custom_timeline}}</label>
                <app-list [list]="customTimeline" type="timeList" [disableEdit]="disableFields"
                    [searchBoxText]="searchText" *ngIf="showAddField"
                    (levelSelected)=addCustomTimelineColumn($event) [content]="textContents"
                    (levelClose)=deleteCustomTimeline($event)>
                </app-list>
            </div>
        </div>
    </div>
</div>